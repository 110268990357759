import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { managersQueries } from 'react-query/factory/managersQueries';

import { updateManagerActivated } from 'api/managers/updateManagerActivated';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useUpdateActivatedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (managerId) => updateManagerActivated(managerId),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: managersQueries.all() });
    },
  });
};
