import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';

import { TOAST_MESSAGE } from 'constants/toast-message';

import { createMatch } from 'api-legacy/teams/createMatch';

export const useMatchAvatarMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ avatarId, managerId }) => {
      createMatch(avatarId, managerId);
      return managerId;
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: (managerId) => {
      queryClient.invalidateQueries({
        queryKey: teamAvatarsQueries.matched(managerId),
      });
    },
  });
};
