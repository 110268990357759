import { memo, useState } from 'react';

import { Button, ListItem, ListItemButton, ListItemText } from '@mui/material';

import MarkdownPreview from '@uiw/react-markdown-preview';

const DocumentListItem = ({ document, index, watching, setWatching }) => {
  return (
    <>
      <ListItem onClick={() => setWatching(index)}>
        <ListItemButton dense disableripple="true">
          <ListItemText>{document.title}</ListItemText>
        </ListItemButton>
      </ListItem>
      {index === watching && (
        <Button onClick={() => setWatching(null)}>닫기</Button>
      )}
      {index === watching && (
        <MarkdownPreview
          style={{
            maxHeight: '50vh',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 100,
            left: 290,
            width: '50%',
            padding: '12px 16px',
            textAlign: 'left',
            boxSizing: 'border-box',
            color: 'inherit',
            background: '#f2f2f2',
          }}
          source={document.text.replace('\n', '<br/>')}
        />
      )}
    </>
  );
};

export default memo(DocumentListItem);
