import { useContext, useState } from 'react';

import { Typography } from '@mui/material';

import {
  ListBoxStyle,
  ListStyle,
} from 'pages/user/features/question/components/styled';

import { ChatContext } from '../context/ChatProvider';
import DocumentListItem from '../items/DocumentListItem';

export const DocumentListBox = () => {
  const { documents } = useContext(ChatContext);
  const [watching, setWatching] = useState(null);
  if (!documents?.length) return null;
  return (
    <ListBoxStyle width="100%">
      <Typography variant="subtitle1">여기서 찾았어요!</Typography>
      <ListStyle>
        {documents.map((document, index) => (
          <DocumentListItem
            key={`${document.fileId} t-${document.title} d-${document.distance} t-${document.text}`}
            document={document}
            index={index}
            watching={watching}
            setWatching={setWatching}
          />
        ))}
      </ListStyle>
    </ListBoxStyle>
  );
};
