import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TOAST_MESSAGE } from '../../constants/toast-message';
import { AvatarCacheBox } from './components/missingQuestion/AvatarCacheBox';
import { DirectAnswerInput } from './components/missingQuestion/DirectAnswerInput';
import { MissingQuestionController } from './components/missingQuestion/MissingQuestionController';
import { MissingQuestionHandler } from './components/missingQuestion/MissingQuestionHandler';

import { handleMissingQuestion } from 'api-legacy/avatars/questions';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { Loader } from 'components/styledMUI/Loader';
import { PageTitle } from 'components/styledMUI/PageTitle';

const CommonButton = styled(Button)(() => ({
  width: '100%',
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '12px 24px',
  marginTop: 8,
  fontSize: '1.125rem',
  fontWeight: 600,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));

export default function MissingQuestionManagement() {
  const queryClient = useQueryClient();

  const { candidateId } = useParams();

  const [page, setPage] = useState(1);

  const [answer, setAnswer] = useState('');
  const handleChangeAnswer = ({ target: { value } }) => {
    setAnswer(value);
  };

  const [checkedQuestion, setCheckedQuestion] = useState([]);
  const [checkedCache, setCheckedCache] = useState({ cacheId: null });

  const { mutate: missingQuestionMutation, isPending } = useMutation({
    mutationFn: async () => {
      const handledQuestion = {
        ...checkedQuestion[0],
        handled: true,
        handleType: answer.trim().length ? 'Direct' : 'Cache',
        handledCacheId: answer.trim().length ? null : checkedCache.cacheId,
        handledAnswer: answer.trim().length ? answer : checkedCache.answer,
      };
      await handleMissingQuestion(handledQuestion);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['Missing Questions', candidateId]);
      setCheckedQuestion([]);
      setCheckedCache({ cacheId: null });
      setAnswer('');
    },
  });

  return (
    <ContentBox component="main" maxWidth="xl">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <PageTitle>누락 질문 관리</PageTitle>
      </Box>
      <MissingQuestionHandler
        disabled={isPending}
        page={page}
        setPage={setPage}
        checkedQuestion={checkedQuestion}
        setCheckedQuestion={setCheckedQuestion}
        checkedCache={checkedCache}
        setCheckedCache={setCheckedCache}
        setAnswer={setAnswer}
      >
        <Grid container spacing={2} mt={2}>
          <MissingQuestionController candidateId={candidateId} />

          <Grid item xs={6}>
            <AvatarCacheBox candidateId={candidateId} />
            <DirectAnswerInput
              answer={answer}
              onChange={handleChangeAnswer}
              disabled={isPending}
            />
            <CommonButton
              onClick={missingQuestionMutation}
              disabled={
                !(
                  ((checkedCache.cacheId || answer) &&
                    checkedQuestion.length === 1) ||
                  isPending
                )
              }
            >
              {isPending ? (
                <Loader />
              ) : checkedQuestion.handled ? (
                '답변 수정하기'
              ) : (
                '답변 하기'
              )}
            </CommonButton>
          </Grid>
        </Grid>
      </MissingQuestionHandler>
    </ContentBox>
  );
}
