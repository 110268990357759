import { API } from 'constants/api-endpoints';

import { defaultInstance } from 'lib/axios/instance';

export const fetchSimpleAvatarProfile = async (avatarId, isPreview = false) => {
  const { data } = await defaultInstance.get(
    `${API.AVATARS}/${avatarId}/profiles-simple`,
    {
      params: {
        isPreview,
      },
    },
  );
  return data;
};
