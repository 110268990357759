import { API } from 'constants/api-endpoints';

import { defaultInstance } from 'lib/axios/instance';

export const fetchFrequentCache = async (avatarId) => {
  const { data } = await defaultInstance.get(
    `${API.AVATARS}/caches/most-frequent`,
    {
      params: {
        avatarId,
      },
    },
  );

  return data;
};
