import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const createManager = async (manager) => {
  const endPoint =
    manager.type === 'Team'
      ? API.MANAGERS
      : `${API.TEAM_MANAGERS}/${localStorage.getItem('id')}`;
  return await tokenInstance.put(`${endPoint}`, {
    type: manager.type,
    loginId: manager.loginId,
    password: manager.password,
    name: manager.name,
  });
};
