import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchAvatarCache = async (avatarId, orderBy, page = 1, search) => {
  const { data } = await tokenInstance.get(`${API.AVATARS}/caches`, {
    params: { page, search, id: avatarId, orderBy },
  });
  return data;
};
