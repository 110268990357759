import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import useFileInput from 'hooks/useFileInput';

import { IMAGE } from 'constants/fileFormats';

const ButtonStyled = styled(Button)(() => ({
  color: '#282c34',
  backgroundColor: '#e8e8e8',
  marginLeft: 32,
  padding: '6px 24px',
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));

export const ImageUploadButton = ({ fileHandler }) => {
  const [FileInput, openDirectory] = useFileInput(
    IMAGE,
    fileHandler,
    'image/*',
  );

  return (
    <ButtonStyled onClick={openDirectory}>
      파일 업로드{FileInput()}
    </ButtonStyled>
  );
};
