import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { Avatar, Box, Container, Fab, Paper, Typography } from '@mui/material';

import { avatarsQueries } from 'react-query/factory/avatarsQueries';

import { profileType } from 'constants/profileType';

import IdeaDialog from './IdeaDialog';

import { createUserConnectionHistory } from 'api-legacy/user';
import { ReactComponent as ChatIcon } from 'assets/images/IconChatMax.svg';
import { ReactComponent as HomeIcon } from 'assets/images/IconHomeFlat.svg';
import { ReactComponent as IdeaIcon } from 'assets/images/IconIdaaFlat.svg';
import { ReactComponent as ShareIcon } from 'assets/images/IconShareFlat.svg';
import { ReactComponent as IconUser } from 'assets/images/IconUserMax.svg';
import { ReactComponent as UsersIcon } from 'assets/images/IconUsersFlat.svg';
import ShareDialog from 'components/Dialog/ShareDialog';
import { useStyles } from 'components/styles/ProfileStyles';
import { shareMobile } from 'lib/utils/share';

export default function Profile() {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const { candidateId } = useParams();

  const [open, setOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const onClickButton = () => {
    navigate(`/question/${candidateId}`, {
      state: { userType: 0 },
    });

    createUserConnectionHistoryMutation({
      userTypeId: location.state ? location.state.userTypeId : 0,
      avatarId: candidateId,
    });
  };

  const { mutate: createUserConnectionHistoryMutation } = useMutation({
    mutationFn: async (params) => {
      return await createUserConnectionHistory(params);
    },
  });

  const { data: avatarDetail, ...profiles } = useQuery(
    avatarsQueries.simpleProfile(candidateId),
  );

  if (profiles.isLoading) return null;

  if (profiles.error) {
    toast.error('존재하지 않는 캐릭터 입니다.');
    navigate(`/`);
  }

  return (
    <Box className={classes.root}>
      {avatarDetail && (
        <Container className={classes.container}>
          <Box className={classes.barArea}>
            <Box className={classes.flexCenter}>
              <UsersIcon width={'32px'} height={'32px'} />
              <p className={classes.count}>
                방문자 수 <b>{avatarDetail.viewCount}</b>
              </p>
            </Box>
            <Box
              sx={{ '& :hover': { cursor: 'pointer' } }}
              className={classes.buttonListStyle}
            >
              <div onClick={() => setOpen(true)}>
                <IdeaIcon />
              </div>
              <div
                onClick={() => {
                  if (navigator.share) {
                    shareMobile(
                      '', // 추후 변경 필요
                      'profile',
                      `${avatarDetail.name}의 프로필 페이지를 공유했습니다.`,
                      avatarDetail.avatarId,
                    );
                  } else {
                    setShareDialogOpen(true);
                  }
                }}
              >
                <ShareIcon />
              </div>
              <Link to="/">
                <HomeIcon />
              </Link>
            </Box>
          </Box>
          <Box className={classes.avatarWrap}>
            {/*<Avatar*/}
            {/*	className={classes.avatar}*/}
            {/*	src={*/}
            {/*		avatarDetail.avatarImage && avatarDetail.avatarImage.downloadUrl*/}
            {/*	}*/}
            {/*/>*/}

            {avatarDetail.avatarImage ? (
              <img
                src={
                  avatarDetail.avatarImage &&
                  avatarDetail.avatarImage.downloadUrl
                }
                alt="프로필 사진"
              />
            ) : (
              <Avatar className={classes.avatar}>
                <IconUser />
              </Avatar>
            )}
          </Box>
          <Paper className={classes.card} elevation={3}>
            <Box className={classes.cardTitle}>
              <Typography variant="h5" component={'span'}>
                {avatarDetail.name}
              </Typography>
            </Box>
            <Box className={classes.cardContents}>
              {avatarDetail.profileList.filter(
                (item) => item.type === profileType.AcademicBackground,
              ).length > 0 && (
                <>
                  <Typography variant="h6">학력</Typography>
                  <ul>
                    {avatarDetail.profileList
                      .filter(
                        (item) => item.type === profileType.AcademicBackground,
                      )
                      .map((el) => (
                        <li key={el.profileNum}>{el.value}</li>
                      ))}
                  </ul>
                </>
              )}

              {avatarDetail.profileList.filter(
                (item) => item.type === profileType.BriefHistory,
              ).length > 0 && (
                <>
                  <Typography variant="h6">약력</Typography>
                  <ul>
                    {avatarDetail.profileList
                      .filter((item) => item.type === profileType.BriefHistory)
                      .map((el) => (
                        <li key={el.profileNum}>{el.value}</li>
                      ))}
                  </ul>
                </>
              )}
            </Box>
          </Paper>
        </Container>
      )}

      <Fab className={classes.fabStyle} onClick={onClickButton}>
        <ChatIcon />
      </Fab>

      {/*  Dialog   */}
      <IdeaDialog open={open} setOpen={setOpen} />

      <ShareDialog open={shareDialogOpen} setOpen={setShareDialogOpen} />
    </Box>
  );
}
