import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkLogin } from 'api/auth/check-login';

import { defaultUrlByType } from 'constants/defaultUrlByType';
import { loginType } from 'constants/loginType';

export default function CheckLogin() {
  const navigate = useNavigate();
  const path = window.location.pathname;

  // 서비스 페이지 여부 체크
  const isServicePage =
    path === '/' ||
    path.includes('login') ||
    path.includes('candidateList') ||
    path.includes('checkUser') ||
    (path.includes('profile') && !path.includes('candidate/profile')) ||
    path.includes('questionBoard') ||
    path.includes('question');

  useEffect(() => {
    if (!isServicePage) {
      checkLogin()
        .then(({ data }) => {
          const type = data.type;

          // 다른 type의 관리자 페이지에 접근 시 본래 관리자 페이지로 이동
          switch (type) {
            case loginType.Team:
              path.includes('system') &&
                navigate(defaultUrlByType.Team, { replace: true });
              break;
            case loginType.Avatar:
              !path.includes('candidate') &&
                navigate(defaultUrlByType.Avatar, { replace: true });
              break;
            case loginType.System:
              !path.includes('system') &&
                navigate(defaultUrlByType.System, { replace: true });
              break;
            default:
              navigate('/login');
          }
        })
        .catch((error) => {
          // 로그인 만료 시 재로그인
          const errorCode = error.response.status;

          if (errorCode === 401) {
            localStorage.clear();
            window.location.replace('/login');
          }
        });
    } else if (path.includes('login')) {
      const type = localStorage.getItem('type');

      switch (type) {
        case loginType.Team:
          navigate(defaultUrlByType.Team, { replace: true });
          break;
        case loginType.Avatar:
          navigate(defaultUrlByType.Avatar, { replace: true });
          break;
        case loginType.System:
          navigate(defaultUrlByType.System, { replace: true });
          break;
        default:
          navigate('/login');
      }
    }
  }, []);

  return null;
}
