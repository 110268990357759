import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { Tooltip } from '@mui/material';

import { HeaderButton } from 'ui/layout/HeaderButton';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <Tooltip title="로그인" placement="bottom" onClick={handleLogin}>
      <HeaderButton disableRipple>
        로그인
        <LoginRoundedIcon />
      </HeaderButton>
    </Tooltip>
  );
};
