import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { filesQueries } from 'react-query/factory/filesQueries';

import { uploadAvatarFile } from 'api/data-files/avatars/uploadAvatarFile';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useAvatarFileUploadMutation = () => {
  const queryClient = useQueryClient();
  const { candidateId } = useParams();

  return useMutation({
    mutationFn: (file) => uploadAvatarFile(file, candidateId),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: filesQueries.lists('Avatar', candidateId),
      });
    },
  });
};
