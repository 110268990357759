export default function NoRowsComponent() {
  return (
    <div
      style={{
        width: '100%',
        height: '52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px rgba(224, 224, 224, 1) solid',
      }}
    >
      내용이 없습니다.
    </div>
  );
}
