import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cacheQueries } from 'react-query/factory/cacheQueries';

import { updateHitCount } from 'api/avatars/updateHitCount';

export const useCacheHitCountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ avatarId, cacheId }) => updateHitCount(avatarId, cacheId),
    onSettled: (avatarId) => {
      queryClient.invalidateQueries(cacheQueries.frequentList(avatarId));
    },
  });
};
