import { useState } from 'react';

import { HANDLE_TYPE } from 'constants/profileType';

export const useClientIPAddressList = () => {
  const [clientData, setClientData] = useState([
    {
      addressNum: Math.random(),
      address: '',
      handleType: null,
    },
  ]);

  const onCreate = () => {
    const newData = {
      addressNum: Math.random(),
      address: '',
      handleType: null,
    };

    setClientData([...clientData, newData]);
  };

  const onEdit = (value, data) => {
    const { create } = HANDLE_TYPE;

    setClientData(
      clientData.map((addressData) => {
        const { handleType } = addressData;

        const lock = addressData.addressNum;
        const key = data.addressNum;
        if (lock === key) {
          if (!handleType)
            return {
              ...addressData,
              handleType: create,
              address: value,
            };
          if (handleType === create)
            return {
              ...addressData,
              address: value,
            };
        } else return addressData;
      }),
    );
  };

  const onRemove = (data) => {
    if (clientData.length === 1) {
      return;
    }
    setClientData(
      clientData.filter(
        (addressData) => addressData.addressNum !== data.addressNum,
      ),
    );
  };

  return { clientData, onCreate, onEdit, onRemove };
};
