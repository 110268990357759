import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, FormControl, MenuItem } from '@mui/material';

import { BoxComponent } from 'ui/dialog-items/BoxComponent';
import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogInput } from 'ui/dialog-items/DialogInput';
import { DialogItemLabel } from 'ui/dialog-items/DialogItemLabel';
import { DialogSelection } from 'ui/dialog-items/DialogSelection';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';

import { charactersQueries } from 'react-query/factory/charactersQueries';
import { useCreateAvatarMutation } from 'react-query/mutations/avatars/useCreateAvatarMutation';

import { validation } from 'lib/utils/validation.util';

export const AddAvatarDialog = ({ title, open, handleClose }) => {
  const characters = useQuery(charactersQueries.list());
  const [character, setCharacter] = useState('');
  const [gender, setGender] = useState('Male');
  const [name, setName] = useState('');
  const [age, setAge] = useState(20);
  const [message, setMessage] = useState();

  const validateSubmit = () => {
    if (
      !characters.data?.find((characterData) => characterData.id === character)
    )
      return { enable: false, reason: '캐릭터를 선택해주세요.' };
    if (!validation('NameRegEx', name))
      return {
        enable: false,
        reason: '이름에 포함될 수 없는 문자가 있습니다.',
      };
    if (!(gender === 'Male' || gender === 'Female'))
      return { enable: false, reason: '성별을 선택해주세요.' };
    if (!validation('AgeRegEx', age))
      return { enable: false, reason: '나이를 숫자로 입력해주세요.' };

    return { enable: true };
  };

  const handleChangeCharacter = (event) => {
    setCharacter(event.target.value);
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  const mutation = useCreateAvatarMutation();
  const handleSubmit = () => {
    const validation = validateSubmit();
    if (validation.enable) {
      mutation.mutate(
        {
          character,
          gender,
          name,
          age,
        },
        {
          onSuccess: handleClose,
        },
      );
    } else {
      setMessage(validation.reason);
    }
  };
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogHeader title={title} handleClose={handleClose} />
      <BoxComponent>
        <FormControl>
          <DialogItemLabel>캐릭터 목소리</DialogItemLabel>
          {characters.isLoading && (
            <DialogInput
              readOnly
              disabled
              value={'데이터를 불러오는 중입니다.'}
            />
          )}
          {characters.isSuccess && (
            <DialogSelection
              disabled={mutation.isPending}
              value={character}
              onChange={handleChangeCharacter}
              IconComponent={() => (
                <Box style={{ marginRight: 10, display: 'flex' }}>
                  <ExpandMoreRoundedIcon />
                </Box>
              )}
              displayEmpty
            >
              {characters.data.map((character) => (
                <MenuItem key={character.id} value={character.id}>
                  {character.name}
                </MenuItem>
              ))}
            </DialogSelection>
          )}
        </FormControl>
        <FormControl>
          <DialogItemLabel>이름</DialogItemLabel>
          <DialogInput
            readOnly={mutation.isPending}
            placeholder="캐릭터의 이름을 입력해주세요."
            value={name}
            onChange={handleChangeName}
          />
        </FormControl>
        <FormControl>
          <DialogItemLabel>성별</DialogItemLabel>
          <DialogSelection
            disabled={mutation.isPending}
            value={gender}
            onChange={handleChangeGender}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
            displayEmpty
          >
            <MenuItem value="Male">남</MenuItem>
            <MenuItem value="Female">여</MenuItem>
          </DialogSelection>
        </FormControl>
        <FormControl>
          <DialogItemLabel>나이</DialogItemLabel>
          <DialogInput
            readOnly={mutation.isPending}
            placeholder="나이를 입력해주세요."
            type="number"
            value={age}
            onChange={handleChangeAge}
          />
        </FormControl>
        <Box
          sx={{
            '& span': { color: '#CA4242' },
          }}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <span>{message}</span>
          <Box display="flex" justifyContent="flex-end">
            <CancelButton disabled={mutation.isPending} onClick={handleClose}>
              취소
            </CancelButton>
            <SubmitButton
              disabled={!characters.data || mutation.isPending}
              onClick={handleSubmit}
            >
              추가
            </SubmitButton>
          </Box>
        </Box>
      </BoxComponent>
    </DialogWrapper>
  );
};
