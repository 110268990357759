import { IconButton } from '@mui/joy';

import { useAudioQuestionMutation } from 'react-query/mutations/questions/useAudioQuestionMutation';

import { useMicVAD } from '@ricky0123/vad-react';
import { ReactComponent as IconMicrophone } from 'assets/images/IconMicrophoneFlat.svg';
import clsx from 'clsx';
import { useStyles } from 'components/styles/RecordingStyles';
import { encodeAudio } from 'lib/utils/encodeAudio';
import { defaultVadParams } from 'lib/vad/defaultVadParams';

export const Record = ({ handleSTT }) => {
  const classes = useStyles();
  const query = useAudioQuestionMutation();

  const vad = useMicVAD({
    ...defaultVadParams,
    onSpeechEnd: (audio) => {
      vad.pause();
      const audioBlob = encodeAudio(audio);
      query.mutate(
        { audioBlob },
        {
          onSuccess: (data) => {
            handleSTT(data.trim());
          },
        },
      );
    },
  });

  return (
    <IconButton
      disabled={vad.loading}
      variant="plain"
      size="lg"
      onClick={vad.start}
    >
      {vad.userSpeaking ? (
        <span className={clsx(classes.recording, classes.recordingChat)} />
      ) : (
        <IconMicrophone />
      )}
    </IconButton>
  );
};
