import { inject, observer } from 'mobx-react';

import { IconButton } from '@mui/material';

import { useAudioQuestionMutation } from 'react-query/mutations/questions/useAudioQuestionMutation';

import { useMicVAD } from '@ricky0123/vad-react';
import { ReactComponent as MicIcon } from 'assets/images/IconMicrophoneFlat.svg';
import clsx from 'clsx';
import { useStyles } from 'components/styles/RecordingStyles';
import { encodeAudio } from 'lib/utils/encodeAudio';
import { defaultVadParams } from 'lib/vad/defaultVadParams';

const Recording = ({ handleSTT }) => {
  const classes = useStyles();
  const query = useAudioQuestionMutation();

  const vad = useMicVAD({
    ...defaultVadParams,
    onSpeechEnd: async (audio) => {
      vad.pause();
      const audioBlob = encodeAudio(audio);
      query.mutate(
        { audioBlob },
        {
          onSuccess: (data) => {
            handleSTT(data.trim());
          },
        },
      );
    },
  });
  return (
    <IconButton
      disabled={vad.loading}
      className={
        vad.userSpeaking
          ? clsx(classes.iconButtonStyle, classes.recordingBtnStyle)
          : classes.iconButtonStyle
      }
      onClick={vad.start}
      disableRipple
    >
      {vad.userSpeaking ? (
        <span className={classes.recording}></span>
      ) : (
        <MicIcon width={'48px'} height={'48px'} />
      )}
    </IconButton>
  );
};

export default inject('userStore')(observer(Recording));
