import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';

import { AGE, USER_TYPE } from 'constants/userType';

import { useStyles } from '../../../../components/styles/CheckUserStyles';

import { ReactComponent as FemaleIcon } from 'assets/images/FemaleIcon.svg';
import { ReactComponent as FemaleIconBW } from 'assets/images/FemaleIconBW.svg';
import { ReactComponent as MaleIcon } from 'assets/images/MaleIcon.svg';
import { ReactComponent as MaleIconBW } from 'assets/images/MaleIconBW.svg';
import clsx from 'clsx';

const GENDER = [
  {
    text: '여성',
    value: 'Female',
    selectIcon: <FemaleIcon />,
    defaultIcon: <FemaleIconBW />,
  },
  {
    text: '남성',
    value: 'Male',
    selectIcon: <MaleIcon />,
    defaultIcon: <MaleIconBW />,
  },
];

export default function CheckUser() {
  const classes = useStyles();

  const params = useParams();
  const navigate = useNavigate();

  const [selectData, setSelectData] = useState({
    gender: '',
    age: '',
  });

  const handleGenderToggle = (gender) => {
    setSelectData((prev) => ({
      ...prev,
      gender: gender,
    }));
  };

  const handleAgeToggle = (age) => {
    setSelectData((prev) => ({
      ...prev,
      age: age,
    }));
  };

  const handleNextPage = () => {
    const userType = USER_TYPE.find(
      (item) =>
        item.age === selectData.age && item.gender === selectData.gender,
    );

    navigate(`/profile/${params.candidateId}`, {
      state: { userTypeId: userType.id },
    });
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.titleBox}>
          <Typography variant="h2">성별과 연령을 선택해 주세요.</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle" className={classes.subtitle}>
            성별
          </Typography>
          <Box className={classes.buttonBox}>
            {GENDER.map((item) => (
              <Button
                key={item.value}
                className={
                  selectData.gender === item.value
                    ? clsx(classes.buttonStyle, classes.buttonStyleActive)
                    : classes.buttonStyle
                }
                onClick={() => handleGenderToggle(item.value)}
              >
                {selectData.gender === item.value
                  ? item.selectIcon
                  : item.defaultIcon}
                {item.text}
              </Button>
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle" className={classes.subtitle} mt={8}>
            연령
          </Typography>
          <Box className={classes.gridBox}>
            {AGE.map((item) => (
              <Button
                key={item.text}
                onClick={() => handleAgeToggle(item.value)}
                className={clsx(
                  classes.buttonStyle,
                  selectData.age === item.value && classes.buttonStyleActive,
                )}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Box>
        {/*<Button className={clsx(classes.nextButton, classes.nextButtonActive)}>다음</Button>*/}
        <button
          disabled={selectData.gender === '' || selectData.age === ''}
          onClick={handleNextPage}
          className={
            selectData.gender && selectData.age
              ? clsx(classes.nextButton, classes.nextButtonActive)
              : classes.nextButton
          }
        >
          다음
        </button>
      </Container>
    </div>
  );
}
