import { API } from 'constants/api-endpoints';
import { ARRAY_BUFFER_RESPONSE } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const getCharacterVoice = async (params) => {
  const response = await tokenInstance.post(
    `${API.CHARACTERS}/voice/${params.voiceId}`,
    params.text,
    ARRAY_BUFFER_RESPONSE,
  );
  return response;
};

export const getCharacterVoices = async () => {
  const { data } = await tokenInstance.get(`${API.CHARACTERS}/voices`);
  return data;
};
