import { useContext } from 'react';

import {
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { PinnedQuestionContext } from '../pinnedQuestion/PinnedQuestionContext';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  width: '100%',
  margin: 0,
  paddingLeft: 10,
  borderBottom: '1px solid #d4d9e7',
  boxSizing: 'border-box',
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
  '& .MuiCheckbox-root': {
    margin: '0 12px',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const AvatarCacheItem = ({ data }) => {
  const context = useContext(PinnedQuestionContext);

  if (!context) {
    return (
      <FormControlLabelStyled
        control={<Checkbox />}
        label={
          <ListItemButton disableRipple>
            <ListItemText primary={data.question} secondary={data.answer} />
          </ListItemButton>
        }
      />
    );
  }
  const replacedAnswer = data.answer.replace('\n', '<br/>');
  const { selectedCache, checkedCache } = context;

  return (
    <FormControlLabelStyled
      control={
        <Checkbox
          // disabled={disabled}
          checked={selectedCache?.cacheId === data.cacheId || false}
          onClick={() => checkedCache(data)}
        />
      }
      label={
        <ListItemButton
          disableRipple
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            '& img': {
              backgroundColor: 'inherit !important',
              objectFit: 'scale-down',
              width: '100%',
              maxHeight: '240px',
            },
          }}
        >
          <ListItemText primary={data.question} />
          <MarkdownPreview
            style={{
              textAlign: 'left',
              boxSizing: 'border-box',
              color: 'inherit',
              background: 'inherit',
            }}
            source={replacedAnswer}
          />
        </ListItemButton>
      }
    />
  );
};
