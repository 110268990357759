import { queryOptions } from '@tanstack/react-query';

import { fetchTeamInfo } from 'api/infos/fetchTeamInfo';

export const teamInfoQueries = {
  all: () => ['team'],
  infos: () => [...teamInfoQueries.all(), 'info'],
  info: () =>
    queryOptions({
      queryKey: teamInfoQueries.infos(),
      queryFn: fetchTeamInfo,
      meta: {
        errorMessage:
          '팀 정보를 불러오지 못했습니다.\n잠시 후에 다시 시도해주세요.',
      },
      staleTime: Infinity,
    }),
};
