import { useState } from 'react';

import { HANDLE_TYPE, profileType } from 'constants/profileType';

export const useClientProfileList = (candidateId) => {
  const [clientData, setClientData] = useState([
    {
      avatarId: candidateId,
      profileNum: Math.random(),
      type: profileType.SelfIntroduction,
      value: '',
      handleType: null,
    },
    {
      avatarId: candidateId,
      profileNum: Math.random(),
      type: profileType.BriefHistory,
      value: '',
      handleType: null,
    },
    {
      avatarId: candidateId,
      profileNum: Math.random(),
      type: profileType.AcademicBackground,
      value: '',
      handleType: null,
    },
  ]);

  const onCreate = (data) => {
    const newData = {
      avatarId: candidateId,
      profileNum: Math.random(),
      type: data.type,
      value: '',
      handleType: null,
    };

    setClientData([...clientData, newData]);
  };

  const onEdit = (value, data) => {
    const { create } = HANDLE_TYPE;

    setClientData(
      clientData.map((profileData) => {
        const { handleType } = profileData;

        const lock = profileData.avatarId + profileData.profileNum;
        const key = data.avatarId + data.profileNum;
        if (lock === key) {
          if (!handleType)
            return {
              ...profileData,
              handleType: create,
              value: value,
            };
          if (handleType === create)
            return {
              ...profileData,
              value: value,
            };
        } else return profileData;
      }),
    );
  };

  const onRemove = (data) => {
    if (
      clientData.filter((profile) => profile.type === data.type).length === 1
    ) {
      return;
    }
    setClientData(
      clientData.filter(
        (profileData) =>
          profileData.avatarId + profileData.profileNum !==
          data.avatarId + data.profileNum,
      ),
    );
  };

  return { clientData, onCreate, onEdit, onRemove };
};
