import { API } from 'constants/api-endpoints';

import { defaultInstance } from 'lib/axios/instance';

export const fetchAvatarDescriptions = async (avatarId, isPinned) => {
  const { data } = await defaultInstance.get(
    `${API.AVATARS}/${avatarId}/descriptions`,
    {
      params: {
        isPinned,
      },
    },
  );
  return data;
};
