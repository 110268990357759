import { tokenInstance } from 'lib/axios/instance';

const avatarEndpointPrefix = '/api/v1/avatars';

export const createAvatarDescription = async (params) => {
  return await tokenInstance.put(
    `${avatarEndpointPrefix}/${params.avatarId}/descriptions`,
    params.avatarDescription,
  );
};

export const updateAvatarDescription = async (params) => {
  return await tokenInstance.post(
    `${avatarEndpointPrefix}/${params.avatarId}/descriptions/${params.descriptionNum}`,
    params.avatarDescription,
  );
};

export const deleteAvatarDescription = async (params) => {
  return await tokenInstance.delete(
    `${avatarEndpointPrefix}/${params.avatarId}/descriptions/${params.descriptionNum}`,
  );
};

export const updateAvatarDescriptionPinned = async (params) => {
  return await tokenInstance.post(
    `${avatarEndpointPrefix}/${params.avatarId}/descriptions/${params.descriptionNum}/pinned`,
  );
};
