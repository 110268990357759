import { memo, useState } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Box, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { profileType } from 'constants/profileType';

const InputBoxWrap = styled(Box)(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  flexWrap: 'nowrap',
  '& .MuiFormControl-root': {
    width: '90%',
  },
  '& .MuiInputBase-input': disabled && {
    pointerEvents: 'none',
    backgroundColor: '#ddd',
    borderRadius: 8,
    opacity: 0.5,
  },
}));

const StyledInput = styled(TextField)(() => ({
  '& input': {
    fontSize: '1rem',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      borderRadius: 10,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 10,
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '0 none',
  },
  '& .MuiFormControl-fullWidth': {
    width: '100%',
  },
}));

export const DynamicInput = memo(function DynamicInput({
  data,
  onCreate,
  onEdit,
  onRemove,
  resetTouchedData,
  multiline,
  isServerData,
  initialValue,
}) {
  const [value, setValue] = useState();
  const [willBeDeleted, setWillBeDeleted] = useState(false);

  const IconButtons = () => {
    if (data.type && data.type === profileType.SelfIntroduction) return null;
    if (isServerData) {
      return (
        <>
          <IconButton
            onClick={() => {
              if (willBeDeleted) return;
              setWillBeDeleted(true);
              onRemove(data);
            }}
          >
            <RemoveRoundedIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setValue();
              resetTouchedData(data);
              if (!willBeDeleted) return;
              setWillBeDeleted(false);
            }}
          >
            <ReplayRoundedIcon />
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton onClick={() => onCreate(data)}>
            <AddRoundedIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              onRemove(data);
            }}
          >
            <RemoveRoundedIcon />
          </IconButton>
        </>
      );
    }
  };

  return (
    <InputBoxWrap style={{ margin: '12px 0' }} disabled={willBeDeleted}>
      <>
        {multiline ? (
          <StyledInput
            multiline
            rows={4}
            fullWidth
            value={value ?? initialValue}
            onChange={(event) => {
              const { value } = event.target;
              setValue(value);
              onEdit(value, data);
            }}
          />
        ) : (
          <StyledInput
            fullWidth
            disabled={willBeDeleted}
            value={value ?? initialValue}
            onChange={(event) => {
              const { value } = event.target;
              setValue(value);
              onEdit(value, data);
            }}
          />
        )}
        {IconButtons()}
      </>
    </InputBoxWrap>
  );
});
