import { useContext } from 'react';

import { CacheGrid } from 'pages/user/features/question/components/blocks/CacheGrid';
import { CategoryGrid } from '../blocks/CategoryGrid';
import ChatInputBar from 'pages/user/features/question/components/blocks/ChatInputBar';
import ChatListBox from 'pages/user/features/question/components/blocks/ChatListBox';
import { GreetingBlock } from 'pages/user/features/question/components/blocks/GreetingBlock';
import { ContentsWrap } from 'pages/user/features/question/components/styled';

import { ChatContext } from '../context/ChatProvider';

export const ChatMain = ({
  usePinnedAnswer,
  bottomMenuOpen,
  setBottomMenuOpen,
}) => {
  const { questions } = useContext(ChatContext);
  return (
    <ContentsWrap>
      <ChatListBox />
      {!questions.length && <GreetingBlock usePinnedAnswer={usePinnedAnswer} />}
      {!usePinnedAnswer ? (
        <>
          <CacheGrid />
          <ChatInputBar />
        </>
      ) : (
        bottomMenuOpen && <CategoryGrid setBottomMenuOpen={setBottomMenuOpen} />
      )}
    </ContentsWrap>
  );
};
