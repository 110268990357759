import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { usePaginationProps } from 'hooks/usePaginationProps';

import { DataBox } from 'ui/datagrid';

import { MatchedAvatar } from 'pages/teams/features/management/components/cells/MatchedAvatar';

import { managersQueries } from 'react-query/factory/managersQueries';

import LoadingSpinner from '../../../../../../ui/LoadingSpinner';
import { ManagerManagementCell } from '../cells/ManagerManagementCell';

export const ManagersGrid = ({ filter }) => {
  const { teamId } = useOutletContext();
  const pagination = usePaginationProps();

  const managers = useQuery(
    managersQueries.list(
      'Avatar',
      teamId,
      pagination.rowsPerPage,
      filter,
      pagination.page + 1,
    ),
  );
  if (managers.isLoading)
    return <LoadingSpinner isLoading={managers.isLoading} />;

  const columns = [
    { field: 'name', headerName: '이름', width: 120 },
    { field: 'loginId', headerName: '아이디', width: 160 },
    {
      field: 'matching',
      headerAlign: 'center',
      align: 'center',
      headerName: '담당 캐릭터',
      flex: 1,
      cellClassName: 'cellCaption',
      sortable: false,
      renderCell: (params) => {
        const { id: managerId } = params.row;

        return <MatchedAvatar managerId={managerId} />;
      },
    },
    {
      field: 'management',
      headerName: '관리',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => <ManagerManagementCell row={row} />,
    },
  ];

  return (
    <DataBox
      data={managers.data.items}
      columns={columns}
      total={managers.data.totalItems}
      pagination={pagination}
    />
  );
};
