export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const setManagerDataLocalStorage = (managerData) => {
  localStorage.setItem('id', managerData.id);
  localStorage.setItem('loginId', managerData.loginId);
  localStorage.setItem('name', managerData.name);
  localStorage.setItem('type', managerData.type);
};
