import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { managersQueries } from 'react-query/factory/managersQueries';

import { createManager } from 'api/managers/createManager';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useCreateManagerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (manager) => createManager(manager),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
    },
    onError: (error) => {
      const errorCode = error.response.data.errorCode;
      if (errorCode === 'DuplicatedLoginId') {
        toast.error(TOAST_MESSAGE.ERROR.DUPLICATED_LOGIN_ID_ERROR);
      } else {
        toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: managersQueries.all() });
    },
  });
};
