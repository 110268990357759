import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchTeamQuestionRecords = async (body) => {
  const { data } = await tokenInstance.post(
    `${API.TEAM}/question-records`,
    body,
  );
  return data;
};
