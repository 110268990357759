import { Box } from '@mui/material';

import { AvatarMatchingTooltip } from '../cell-items/AvatarMatchingTooltip';
import { ChangeActivateTooltip } from '../cell-items/ChangeActivateTooltip';
import { ChangePasswordTooltip } from '../cell-items/ChangePasswordTooltip';
import { DeleteTooltip } from '../cell-items/DeleteTooltip';

export const ManagerManagementCell = ({ row }) => (
  <Box display="flex">
    <AvatarMatchingTooltip id={row.id} />
    <ChangeActivateTooltip id={row.id} activated={row.activated} />
    <ChangePasswordTooltip id={row.id} />
    <DeleteTooltip id={row.id} />
  </Box>
);
