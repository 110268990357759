import { useState } from 'react';

import { HANDLE_TYPE } from 'constants/profileType';

export const useServerProfileList = () => {
  const [touchedServerData, setTouchedServerData] = useState([]);

  const editServerData = (value, data) => {
    const { update } = HANDLE_TYPE;
    touchedServerData.find(
      (serverData) =>
        serverData.avatarId + serverData.profileNum ===
        data.avatarId + data.profileNum,
    )
      ? setTouchedServerData(
          touchedServerData.map((serverData) => {
            const lock = serverData.avatarId + serverData.profileNum;
            const key = data.avatarId + data.profileNum;
            if (lock === key) {
              return {
                ...serverData,
                value: value,
                handleType: update,
              };
            } else {
              return serverData;
            }
          }),
        )
      : setTouchedServerData([
          ...touchedServerData,
          {
            ...data,
            value: value,
            handleType: update,
          },
        ]);
  };

  const removeServerData = (data) => {
    const { willBeDeleted } = HANDLE_TYPE;
    touchedServerData.find(
      (serverData) =>
        serverData.avatarId + serverData.profileNum ===
        data.avatarId + data.profileNum,
    )
      ? setTouchedServerData(
          touchedServerData.map((serverData) => {
            const lock = serverData.avatarId + serverData.profileNum;
            const key = data.avatarId + data.profileNum;
            if (lock === key) {
              return {
                ...serverData,
                handleType: willBeDeleted,
              };
            } else {
              return serverData;
            }
          }),
        )
      : setTouchedServerData([
          ...touchedServerData,
          {
            ...data,
            handleType: willBeDeleted,
          },
        ]);
  };

  const resetTouchedData = (data) => {
    setTouchedServerData(
      touchedServerData.filter(
        (profileData) =>
          profileData.avatarId + profileData.profileNum !==
          data.avatarId + data.profileNum,
      ),
    );
  };
  return {
    touchedServerData,
    editServerData,
    removeServerData,
    resetTouchedData,
  };
};
