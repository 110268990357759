import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';

import { doLogout } from 'api/auth/do-logout';

import { ReactComponent as AccountManagementIcon } from 'assets/images/AccountManagementIcon.svg';
import { ReactComponent as UserManagementIcon } from 'assets/images/UserManagementIcon.svg';
import { Logo } from 'components/Logo';

const drawerWidth = 240;

const menuItem = [
  {
    icon: <UserManagementIcon />,
    title: '캐릭터 관리',
    link: '/system/manager',
    active: true,
  },
  {
    icon: <AccountManagementIcon />,
    title: '계정 관리',
    link: '/system/accountManagement',
    active: false,
  },
];
const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: '1px solid #e8edf7',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const IconButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  color: '#0F0F10',
  paddingLeft: 0,
  '&:hover': {
    color: '#0F0F10',
    backgroundColor: 'transparent',
  },
}));

const TopbarIconButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  margin: '0 8px 0 16px',
  // color:'#0F0F10',
  '& svg': {
    width: 26,
    height: 26,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const NameTag = styled('div')(({ theme }) => ({
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#27c3e2',
  color: '#fff',
  borderRadius: 10,
  padding: '4px 12px',
  fontWeight: 600,
}));

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  borderBottom: '1px solid #e8edf7',
  borderLeft: '1px solid #e8edf7',
  color: '#0F0F10',
  '& .MuiTypography-h6': {
    fontSize: '1.375rem',
    fontWeight: '700',
    marginLeft: 24,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  minHeight: '100vh',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  '& .MuiListItemText-root span': {
    fontWeight: 700,
  },
}));

export default function Setting(props) {
  const theme = useTheme();

  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  const logoutMutation = useMutation({
    mutationFn: doLogout,
    onSuccess: (data) => {
      localStorage.clear();
      window.location.replace('/login');
    },
    onError: (error) => {},
  });

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
          >
            <Box display="flex" alignItems="center">
              <Logo />
              <Typography variant="h6" noWrap component="div">
                시스템 관리
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <NameTag>Admin</NameTag>
              <Tooltip
                onClick={handleLogout}
                title="로그아웃"
                placement="bottom"
              >
                <TopbarIconButton disableRipple>
                  <LogoutRoundedIcon />
                </TopbarIconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItem.map((item) => (
            <ListItem disablePadding sx={{ display: 'block' }} key={item.title}>
              <ListItemButtonStyled
                sx={{
                  minHeight: 42,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: pathname === item.link ? '#ECF3FF' : '#fff',
                }}
                component={Link}
                to={item.link}
              >
                <ListItemIcon
                  sx={{
                    width: 32,
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary={item.title}
                  sx={{
                    fontWeight: 700,
                    opacity: open ? 1 : 0,
                    color: pathname === item.link ? '#276ae2' : '#000',
                  }}
                />
              </ListItemButtonStyled>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
