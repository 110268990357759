import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchAllCharacters = async () => {
  const { data } = await tokenInstance.get(`${API.CHARACTERS}/list`);
  return data;
};

export const fetchPaginatedCharacters = async (params) => {
  const { data } = await tokenInstance.get(`${API.CHARACTERS}`, { params });
  return data;
};
