import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SendIconBox = styled(IconButton)(() => ({
  '& svg': {
    width: 56,
    height: 56,
    '@media all and (max-width: 640px)': {
      width: 44,
      height: 44,
    },
    '@media all and (max-width: 480px)': {
      width: 36,
      height: 36,
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
