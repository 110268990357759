import { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';

import { usePaginationProps } from 'hooks/usePaginationProps';

import { DataBox } from 'ui/datagrid';
import LoadingSpinner from 'ui/LoadingSpinner';

import { fetchAvatarDescriptions } from 'api/avatars/fetchAvatarDescriptions';

import { AVATAR_DESCRIPTIONS } from 'constants/queryKeys';

import { DeleteTooltip } from './DeleteTooltip';

import { updateAvatarDescriptionPinned } from 'api-legacy/avatarDescription';
import CorePromisesDialog from 'components/Dialog/CorePromisesDialog';
import CorePromisesViewDialog from 'components/Dialog/CorePromisesViewDialog';
import { CommonIconButton } from 'components/styledMUI/CommonIconButton';
import { DataGridBox } from 'components/styledMUI/DataGridBox';

export const DescriptionGrid = ({ candidateId }) => {
  const queryClient = useQueryClient();
  const [openViewer, setOpenViewer] = useState(false);
  const [openAttack, setOpenAttack] = useState(false);
  const [openEditAttack, setOpenEditAttack] = useState(false);
  const [previewItem, setPreviewItem] = useState();
  const [editItem, setEditItem] = useState();
  const pagination = usePaginationProps();

  const handleClickViewer = (item) => {
    setOpenViewer(true);
    setPreviewItem(item);
  };
  const handleCloseViewer = () => {
    setOpenViewer(false);
    setPreviewItem();
  };

  const handleClickAttack = () => {
    setOpenAttack(true);
  };
  const handleCloseAttack = () => {
    setOpenAttack(false);
  };

  const handleClickEditAttack = (item) => {
    setOpenEditAttack(true);
    setEditItem(item);
  };
  const handleCloseEditAttack = () => {
    setOpenEditAttack(false);
    setEditItem();
  };

  const {
    data: avatarDescriptions,
    isLoading: isGetting,
    isSuccess,
  } = useQuery({
    queryKey: [AVATAR_DESCRIPTIONS, candidateId],
    queryFn: () => fetchAvatarDescriptions(candidateId),
  });

  const {
    mutate: updateAvatarDescriptionPinnedMutation,
    isPending: isUpdating,
  } = useMutation({
    mutationFn: async (descriptionNum) =>
      await updateAvatarDescriptionPinned({
        avatarId: candidateId,
        descriptionNum: descriptionNum,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [AVATAR_DESCRIPTIONS, candidateId],
      });
    },
  });

  const columnsAttack = [
    { field: 'title', headerName: '제목', width: 300, flex: 1 },
    {
      field: 'value',
      headerName: '내용',
      width: 560,
      flex: 1,
      renderCell: (params) =>
        params.value.split('\n').length > 1
          ? params.value.split('\n')[0] + ' ...'
          : params.value,
    },
    {
      field: 'createdDatetime', // 최근 업로드 순서로 정렬하기 위한 field(임시)
      headerName: '관리',
      // width: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <Tooltip title="유저화면 등록">
            <IconButton
              onClick={() =>
                updateAvatarDescriptionPinnedMutation(params.row.descriptionNum)
              }
            >
              {params.row.pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="수정">
            <IconButton onClick={() => handleClickEditAttack(params.row)}>
              <EditNoteRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="미리보기">
            <IconButton onClick={() => handleClickViewer(params.row)}>
              <FindInPageRoundedIcon />
            </IconButton>
          </Tooltip>
          <DeleteTooltip descriptionNum={params.row.descriptionNum} />
        </Box>
      ),
    },
  ];
  if (isGetting)
    return (
      <Box mb={10}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CommonIconButton disabled onClick={handleClickAttack}>
            <PostAddRoundedIcon />
            핵심공약 추가
          </CommonIconButton>
        </Box>
        <DataGridBox>
          <LoadingSpinner isLoading={isGetting} />
        </DataGridBox>
      </Box>
    );
  return (
    <Box mb={10}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <CommonIconButton disabled={isUpdating} onClick={handleClickAttack}>
          <PostAddRoundedIcon />
          FAQ 등록
        </CommonIconButton>
      </Box>

      {isSuccess && (
        <DataBox
          getRowId={(row) => row.descriptionNum}
          columns={columnsAttack}
          data={avatarDescriptions}
          total={avatarDescriptions.length}
          pagination={pagination}
        />
      )}

      {openAttack && (
        <CorePromisesDialog
          isLoading={isUpdating}
          open={openAttack}
          handleClose={handleCloseAttack}
          title={'FAQ 추가'}
          changeMode={false}
        />
      )}

      {openEditAttack && (
        <CorePromisesDialog
          isLoading={isUpdating}
          open={openEditAttack}
          handleClose={handleCloseEditAttack}
          title={'FAQ 수정'}
          editItem={editItem}
          changeMode={true}
        />
      )}

      {openViewer && previewItem && (
        <CorePromisesViewDialog
          open={openViewer}
          handleClose={handleCloseViewer}
          title={'FAQ 미리보기'}
          previewItem={previewItem}
        />
      )}
    </Box>
  );
};
