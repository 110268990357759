import { MULTIPART_HEADER } from 'constants/request-config';

import { defaultInstance, tokenInstance } from 'lib/axios/instance';

const avatarEndpointPrefix = '/api/v1/avatars';

export const getAvatarConfigDetails = async (avatarId) => {
  return await defaultInstance.get(
    `${avatarEndpointPrefix}/${avatarId}/config-details`,
  );
};

export const updateAvatarConfigExceptUseTeamFile = async (
  candidateId,
  formData,
) => {
  await tokenInstance.post(
    `${avatarEndpointPrefix}/${candidateId}/config-details`,
    formData,
    MULTIPART_HEADER,
  );
};

export const updateAvatarConfigUseTeamFile = async (candidateId) => {
  await tokenInstance.post(
    `${avatarEndpointPrefix}/config/update/${candidateId}/use-team-files`,
  );
};

export const updateAvatarConfigUseAutoPinnedAnswer = async (candidateId) => {
  await tokenInstance.post(
    `${avatarEndpointPrefix}/config/update/${candidateId}/use_auto_pinned_answer`,
  );
};
