import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';

import { deleteAvatar } from 'api/avatars/deleteAvatar';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useDeleteAvatarMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAvatar,
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.DELETE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.DELETE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: teamAvatarsQueries.all() });
    },
  });
};
