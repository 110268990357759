import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogInput = styled(TextField)(() => ({
  backgroundColor: '#fff',
  border: '0 none',
  // borderRadius:10,
  '& fieldset': {
    border: '1px solid #d4d9e7',
  },
  '& input': {
    padding: '12px 14px',
  },
}));
