import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  backgroundColor: '#F4F7FE',
}));

const ContentBox = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  padding: 40,
  margin: '94px 32px',
  backgroundColor: '#fff',
  borderRadius: 16,
  boxShadow: '0 0 10px rgb(182 182 182 / 40%)',
  '@media all and (min-width: 600px)': {
    padding: '40px 40px 200px 40px',
  },
}));

const PageTitle = styled('h3')(({ theme }) => ({
  margin: '1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));
const QuestionBoardStyled = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  // '& > div':{
  //     background:'#9ebedd',
  //     '& > .MuiContainer-root':{
  //         position:'relative',
  //         padding:0,
  //         top:-90,
  //         '& > div': {
  //             position: 'inherit',
  //             paddingRight:24,
  //             paddingLeft:24,
  //         },
  //     },
  //     '& > .MuiContainer-root+div':{
  //         position:'relative',
  //     },
  // },
}));

const FlexCenter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function Preview() {
  return (
    <ContentBox component="main" maxWidth="xl">
      <PageTitle>테스트 화면</PageTitle>
      <FlexCenter>
        <QuestionBoardStyled></QuestionBoardStyled>
      </FlexCenter>
    </ContentBox>
  );
}
