import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CommonIconButton = styled(Button)(() => ({
  color: '#276AE2',
  backgroundColor: '#EEF3FD',
  border: '1px solid #276AE2',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  textTransform: 'none',
  '& svg': {
    marginRight: 6,
  },
  '&:hover': {
    backgroundColor: '#EEF3FD',
  },
}));
