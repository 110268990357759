import { useState } from 'react';

import { Header } from 'ui/layout/Header';
import { Sidebar } from 'ui/layout/Sidebar';
import { RootBox } from 'ui/root';

import { NavMenu } from './NavMenu';

export const Layout = ({ navName, menuItems, children }) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <RootBox>
      <Header open={open} handleDrawerOpen={handleDrawerOpen}>
        <NavMenu name={navName}></NavMenu>
      </Header>
      <Sidebar
        menuItems={menuItems}
        open={open}
        handleDrawerClose={handleDrawerClose}
      />
      {children}
    </RootBox>
  );
};
