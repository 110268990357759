import { MaxLogo } from './MaxLogo';
import { OriginalLogo } from './OriginalLogo';

export const Logo = ({ width, height }) => {
  const locationOrigin = window.location.origin;

  if (locationOrigin.includes('post-ai'))
    return <MaxLogo width={width || '102px'} height={height || '42px'} />;

  return <OriginalLogo width={width || '102px'} height={height || '42px'} />;
};
