import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { filesQueries } from 'react-query/factory/filesQueries';

import { updateFileUsable } from 'api/data-files/updateFileUsable';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useFileUsableMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fileId) => updateFileUsable({ fileId }),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: filesQueries.all(),
      });
    },
  });
};
