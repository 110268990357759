import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(() => ({
  width: '100%',
  flexGrow: 1,
  padding: 40,
  margin: '94px 32px',
  backgroundColor: '#fff',
  borderRadius: 8,
  // boxShadow: '0 0 10px rgb(182 182 182 / 40%)',
  border: '1px solid #e8edf7',
  boxSizing: 'border-box',
  '@media all and (min-width: 600px)': {
    padding: 40,
  },

  '@media all and (min-width: 1536)': {
    maxWidth: 1536,
  },
}));

export const ContentContainer = ({ children }) => (
  <StyledContainer component="main" maxWidth="xl">
    {children}
  </StyledContainer>
);
