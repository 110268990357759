import toast from 'react-hot-toast';

export function shareMobile(title, pathname, text, id) {
  return navigator
    .share({
      title: title,
      text: text,
      url: `/profile/${id}`,
    })
    .then(() => {})
    .catch(() => {});
}

export function sharePC(pathname, id) {
  const originalPath = window.location.origin;
  const url = `${originalPath}/${pathname}/${id}`;
  return navigator.clipboard
    .writeText(url)
    .then(() => {
      toast.success('링크가 복사되었습니다.');
    })
    .catch(() => {
      toast.error('링크 복사에 실패하였습니다.');
    });
}
