import { queryOptions } from '@tanstack/react-query';

import { fetchFilesWithPagination } from 'api/data-files/fetchFilesWithPagination';

export const filesQueries = {
  all: () => ['files'],
  lists: (fileType, id) => [...filesQueries.all(), fileType, id],
  list: (fileType, id, pageSize = 5, search = '', page = 1) =>
    queryOptions({
      queryKey: [...filesQueries.lists(fileType, id), pageSize, search, page],
      queryFn: () =>
        fetchFilesWithPagination({ fileType, id, pageSize, search, page }),
      placeholderData: (previousData) => previousData,
    }),
};
