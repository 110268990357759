import { SubTitle } from 'ui/titles/SubTitle';

import { Wrapper } from 'pages/teams/features/team-info/components/atoms/Wrapper';

export const InfoItem = ({ title, children }) => {
  return (
    <Wrapper>
      <SubTitle>{title}</SubTitle>
      {children}
    </Wrapper>
  );
};
