import { queryOptions } from '@tanstack/react-query';

import {
  fetchAllCharacters,
  fetchPaginatedCharacters,
} from 'api/characters/fetchCharacters';

export const charactersQueries = {
  all: () => ['characters'],
  list: () =>
    queryOptions({
      queryKey: charactersQueries.all(),
      queryFn: () => fetchAllCharacters(),
      meta: {
        errorMessage:
          '캐릭터 데이터를 불러오지 못했습니다.\n잠시후에 다시 시도해주세요.',
      },
      staleTime: Infinity,
    }),
  paginations: (page, pageSize, search) => [
    ...charactersQueries.all(),
    page,
    pageSize,
    search,
  ],
  pagination: (page, pageSize, search) =>
    queryOptions({
      queryKey: charactersQueries.paginations(page, pageSize, search),
      queryFn: () => fetchPaginatedCharacters({ page, pageSize, search }),
    }),
};
