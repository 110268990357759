import { ReactComponent as AccountManagementIcon } from 'assets/images/AccountManagementIcon.svg';
import { ReactComponent as CommonDataIcon } from 'assets/images/CommonDataIcon.svg';
import { ReactComponent as MonitoringIcon } from 'assets/images/MonitoringIcon.svg';
import { ReactComponent as SettingIcon } from 'assets/images/SettingIcon.svg';
import { ReactComponent as UserManagementIcon } from 'assets/images/UserManagementIcon.svg';

export const teamPageMenu = [
  {
    icon: <UserManagementIcon />,
    title: '캐릭터 관리',
    link: '/team/management',
    active: true,
  },
  {
    icon: <CommonDataIcon />,
    title: '공통 데이터',
    link: '/team/data',
    active: false,
  },
  {
    icon: <MonitoringIcon />,
    title: '모니터링',
    link: '/team/monitoring',
    active: false,
  },
  {
    icon: <AccountManagementIcon />,
    title: '계정관리',
    link: '/team/manager',
    active: false,
  },
  {
    icon: <SettingIcon />,
    title: '팀 정보',
    link: '/team/info',
    active: false,
  },
];
