import { IconButton } from '@mui/joy';

import { ReactComponent as IconSend } from 'assets/images/IconSendFlat.svg';

export const Submit = ({ query }) => {
  return (
    <IconButton variant="solid" color="plain" size="lg" onClick={query}>
      <IconSend />
    </IconButton>
  );
};
