import { Link, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { profileType } from 'constants/profileType';
import { AVATAR_PROFILE } from 'constants/queryKeys';

import { AvatarImageBox } from './components/AvatarImageBox';
import { ProfileWrapper } from './components/Profile/ProfileWrapper';

import { getAvatarProfile } from 'api-legacy/avatars/getAvatarProfile';
// import { ReactComponent as GroupIcon } from 'Common/images/GroupIcon.svg';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { formatPhoneNumber } from 'lib/utils/phoneNumber.util';

const PageTitle = styled('h3')(() => ({
  // margin:'1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

const EditButton = styled(Link)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#d32f2f',
  marginLeft: 'auto',
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
}));

const TitleBoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 40,
}));

const GroupTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg, img': {
    width: 32,
  },
  '& .MuiTypography-root': {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#4f9be2',
    marginLeft: 10,
  },
}));

export default function CandidateProfile() {
  const { candidateId } = useParams();

  const {
    data: avatarProfile,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [AVATAR_PROFILE, candidateId],
    queryFn: async () => {
      const { data } = await getAvatarProfile(candidateId);
      return data;
    },
  });

  let profile = [
    { title: '이름', contents: null },
    { title: '나이', contents: null },
    {
      title: '연락처',
      contents: null,
    },
    {
      title: '성별',
      contents: null,
    },
    {
      title: '목소리',
      contents: null,
    },
  ];
  if (avatarProfile) {
    profile = [
      { title: '이름', contents: avatarProfile && avatarProfile.name },
      { title: '나이', contents: avatarProfile && `${avatarProfile.age}세` },
      {
        title: '연락처',
        contents: avatarProfile.phoneNum
          ? formatPhoneNumber(avatarProfile.phoneNum)
          : null,
      },
      {
        title: '성별',
        contents:
          avatarProfile && avatarProfile.gender === 'Male' ? '남성' : '여성',
      },
      {
        title: '목소리',
        contents: avatarProfile && avatarProfile.characterName,
      },
    ];
  }

  if (isLoading) return <></>;
  if (isError) return <></>;
  return (
    <ContentBox component="main" maxWidth="xl">
      <TitleBoxStyled>
        <PageTitle>프로필</PageTitle>
        <GroupTitle>
          {/*<GroupIcon />*/}
          {avatarProfile.teamLogoImage && (
            <img
              src={avatarProfile.teamLogoImage.downloadUrl}
              alt="팀 로고 이미지"
            />
          )}
          <Typography>{avatarProfile.teamName}</Typography>
        </GroupTitle>
      </TitleBoxStyled>
      <Box>
        <ProfileWrapper name={'사진'}>
          <AvatarImageBox src={avatarProfile.avatarImage?.downloadUrl} />
        </ProfileWrapper>
        {profile.map((item) => (
          <ProfileWrapper key={item.title} name={item.title}>
            <p>{item.contents}</p>
          </ProfileWrapper>
        ))}
        <ProfileWrapper name={'인사말씀'}>
          <p>
            {avatarProfile.profileList.length > 0 &&
              avatarProfile.profileList.find(
                (item) => item.type === profileType.SelfIntroduction,
              )?.value}
          </p>
        </ProfileWrapper>
        <ProfileWrapper name={'약력'}>
          <div>
            {avatarProfile.profileList.length > 0 &&
              avatarProfile.profileList
                .filter((item) => item.type === profileType.BriefHistory)
                .map((el) => <p key={el.profileNum}>{el.value}</p>)}
          </div>
        </ProfileWrapper>
        <ProfileWrapper name={'학력'}>
          <div>
            {avatarProfile.profileList.length > 0 &&
              avatarProfile.profileList
                .filter((item) => item.type === profileType.AcademicBackground)
                .map((el) => <p key={el.profileNum}>{el.value}</p>)}
          </div>
        </ProfileWrapper>
      </Box>
      <Box width="100%" mt={10}>
        <EditButton to={`/candidate/profile/edit/${candidateId}`}>
          수정
        </EditButton>
      </Box>
    </ContentBox>
  );
}
