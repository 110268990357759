import { useNavigate } from 'react-router-dom';

import { Box, Container, Paper, Typography } from '@mui/material';

import { useStyles } from 'components/styles/CandidateListStyle';
import { formatPhoneNumber } from 'lib/utils/phoneNumber.util';

export const CandidateCard = ({ candidate }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container
      className={classes.container}
      onClick={() => navigate(`/candidate/profile/${candidate.id}`)}
    >
      <Paper className={classes.card} elevation={3}>
        <Box className={classes.simpleCardContents}>
          <Box className={classes.cardTitle}>
            <Typography variant="h6" component={'span'}>
              {candidate.name}
            </Typography>
          </Box>
          <Box className={classes.infoStyle}>
            <Box>
              <span>소속</span>
              {candidate.teamName}
            </Box>
            <Box>
              <span>연락처</span>
              {candidate.phoneNum && formatPhoneNumber(candidate.phoneNum)}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
