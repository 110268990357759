import { useState } from 'react';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { IconButton, Tooltip } from '@mui/material';

import { useDeleteCharacterMutation } from 'react-query/mutations/characters/useDeleteCharacterMutation';

import { DeleteConfirmDialog } from 'components/Dialog/DeleteConfirmDialog';

export const DeleteCharacterTooltip = ({ id }) => {
  const mutation = useDeleteCharacterMutation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    mutation.mutate(id, { onSuccess: handleClose });
  };

  return (
    <>
      <Tooltip title="삭제" onClick={handleOpen}>
        <IconButton>
          <DeleteOutlineRoundedIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <DeleteConfirmDialog
          open={open}
          pending={mutation.isPending}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
