import { memo, useContext } from 'react';

import ChatBlock from 'pages/user/features/question/components/blocks/ChatBlock';

import { ChatContext } from '../context/ChatProvider';

const ChatListBox = () => {
  const { questions } = useContext(ChatContext);
  return (
    <div>
      {questions.map((question, index) => (
        <ChatBlock
          key={question.timestamp}
          index={index}
          content={question.content}
          answer={question.answer}
          userQuestionHistoryId={question.userQuestionHistoryId}
        />
      ))}
    </div>
  );
};
export default memo(ChatListBox);
