import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const createNewTeamAvatar = async (data) => {
  const { character, gender, name, age } = data;
  const managerId = localStorage.getItem('id');
  await tokenInstance.put(`${API.AVATARS}/${managerId}`, {
    characterId: character,
    gender: gender,
    name: name ? name : '이름 없음',
    age: age,
    typeMeta: '',
  });
};
