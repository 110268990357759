import { Navigate, Outlet } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { ContentContainer } from 'ui/container';
import { Layout } from 'ui/layout';
import { PageTitle } from 'ui/titles/PageTitle';

import { TeamNav } from 'pages/teams/outlet/TeamNav';
import { teamPageMenu } from 'pages/teams/outlet/teamPageMenu';

import { teamInfoQueries } from 'react-query/factory/teamInfoQueries';

import { NavMenu } from '../../../ui/layout/NavMenu';

export const TeamOutlet = () => {
  const teamInfo = useQuery(teamInfoQueries.info());

  if (localStorage.getItem('type') === 'Avatar')
    return <Navigate to={'/candidate/management'} replace={true} />;

  return (
    <Layout navName={'Team'} menuItems={teamPageMenu}>
      {teamInfo.isLoading && (
        <ContentContainer>
          <PageTitle>데이터를 불러오는 중입니다.</PageTitle>
        </ContentContainer>
      )}
      {teamInfo.data && (
        <ContentContainer>
          <Outlet context={{ teamId: teamInfo.data.team.id, teamInfo }} />
        </ContentContainer>
      )}
    </Layout>
  );
};
