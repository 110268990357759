import { DocumentListBox } from 'pages/user/features/question/components/blocks/DocumentListBox';
import { PromptBox } from 'pages/user/features/question/components/blocks/PromptBox';
import { SideBar } from 'pages/user/features/question/components/styled';

export const AsideMenu = () => {
  return (
    <SideBar>
      <DocumentListBox />
      <PromptBox />
    </SideBar>
  );
};
