import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, TablePagination, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { usePaginationProps } from 'hooks/usePaginationProps';

import LoadingSpinner from 'ui/LoadingSpinner';

import { charactersQueries } from 'react-query/factory/charactersQueries';

import { DataBox } from '../../ui/datagrid';
import CharacterAddBox from './CharacterAddBox';
import { DeleteCharacterTooltip } from './DeleteCharacterTooltip';
import Setting from './Setting';

import { ReactComponent as UserProfileAddIcon } from 'assets/images/UserProfileAddIcon.svg';
import { CommonIconButton } from 'components/styledMUI/CommonIconButton';
import { CommonInputBase } from 'components/styledMUI/CommonInputBase';
import { CommonSearch as Search } from 'components/styledMUI/CommonSearch';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { RootBox } from 'components/styledMUI/RootBox';
import { SearchIconWrapper } from 'components/styledMUI/SearchIconWrapper';
import { useDebouncedValue } from 'rooks';

const PageTitle = styled('h3')(() => ({
  margin: '1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

export default function CharacterManagement() {
  const pagination = usePaginationProps();

  const [searchName, setSearchName] = useState('');

  const [addUser, setAddUser] = useState(false);

  const [changeMode, setChangeMode] = useState(false);
  const [changeCharacterData, setChangeCharacterData] = useState({});

  const handleChangeSearchName = (event) => {
    setSearchName(event.target.value);
  };
  const [filter, hotUpdate] = useDebouncedValue(searchName, 500);

  const handleClickAddUser = () => {
    if (addUser && changeMode) {
      setChangeMode(false);
    } else {
      setAddUser(!addUser);
      setChangeMode(false);
    }
  };

  const handleChangeMode = (id) => {
    const changeCharacter = characterList.items.find((item) => item.id === id);
    setChangeCharacterData(changeCharacter);

    if (addUser && !changeMode) {
      setChangeMode(true);
    } else if (!addUser && !changeMode) {
      setAddUser(!addUser);
      setChangeMode(true);
    }
  };

  const {
    data: characterList,
    isLoading,
    isError,
  } = useQuery(
    charactersQueries.pagination(
      pagination.page + 1,
      pagination.rowsPerPage,
      filter,
    ),
  );

  const columnsData = [
    { field: 'name', headerName: '캐릭터 명', flex: 2 },
    {
      field: 'management',
      headerName: '관리',
      // width: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <Tooltip onClick={() => handleChangeMode(params.row.id)} title="수정">
            <IconButton>
              <EditNoteRoundedIcon />
            </IconButton>
          </Tooltip>
          <DeleteCharacterTooltip id={params.row.id} />
        </Box>
      ),
    },
  ];

  if (isError) return null;
  return (
    <RootBox>
      <Setting />
      <ContentBox component="main" maxWidth="xl">
        <PageTitle>캐릭터 관리</PageTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CommonIconButton onClick={handleClickAddUser}>
            <UserProfileAddIcon /> 캐릭터 추가
          </CommonIconButton>
          <Search>
            <SearchIconWrapper>
              <SearchRoundedIcon />
            </SearchIconWrapper>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                hotUpdate();
              }}
            >
              <CommonInputBase
                value={searchName}
                onChange={handleChangeSearchName}
                placeholder="캐릭터 검색"
              />
            </form>
          </Search>
        </Box>
        <Box>
          {addUser === true ? (
            <CharacterAddBox
              changeMode={changeMode}
              changeData={changeMode ? changeCharacterData : {}}
              setChangeMode={setChangeMode}
              setAddUser={setAddUser}
            />
          ) : null}
          {/* //// */}
          {isLoading ? (
            <LoadingSpinner isLoading={isLoading} />
          ) : (
            <DataBox
              data={characterList.items}
              columns={columnsData}
              total={characterList.totalItems}
              pagination={pagination}
            />
          )}
        </Box>
      </ContentBox>
    </RootBox>
  );
}
