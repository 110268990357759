import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useClientProfileList } from 'hooks/CandidateManager/useClientProfileList';
import { useServerProfileList } from 'hooks/CandidateManager/useServerProfileList';
import useFileInput from 'hooks/useFileInput';
import useImageUpload from 'hooks/useImageUpload';

import { IMAGE } from 'constants/fileFormats';
import { profileType } from 'constants/profileType';
import { AVATAR_PROFILE } from 'constants/queryKeys';

import { charactersQueries } from '../../react-query/factory/charactersQueries';
import { AvatarImageBox } from './components/AvatarImageBox';
import { DynamicInput } from './components/DynamicInput';
import { ProfileWrapper } from './components/Profile/ProfileWrapper';

import { getAvatarProfile } from 'api-legacy/avatars/getAvatarProfile';
import { updateAvatarProfile } from 'api-legacy/avatars/updateAvatarProfile';
import { ContentBox } from 'components/styledMUI/ContentBox';

const PageTitle = styled('h3')(() => ({
  margin: '1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

const StyledInput = styled(TextField)(() => ({
  '& input': {
    fontSize: '1rem',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      borderRadius: 10,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 10,
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '0 none',
  },
  '&.MuiFormControl-fullWidth': {
    width: '80%',
  },
}));

const CommonAgeInput = styled(TextField)(() => ({
  width: '4rem',
  marginRight: 8,
  '& input': {
    fontSize: '1rem',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      borderRadius: 10,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 10,
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '0 none',
  },
}));

const CommonSelect = styled(Select)(() => ({
  backgroundColor: '#fff',
  '& fieldset': {
    borderRadius: 10,
  },
  '& svg': {
    color: '#869592',
  },
}));
const CommonButton = styled(Button)(() => ({
  color: '#282c34',
  backgroundColor: '#e8e8e8',
  marginLeft: 32,
  padding: '6px 24px',
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));

const CancelButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#333',
  backgroundColor: 'transparent',
  marginLeft: 20,
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));

const EditButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#276AE2',
  marginLeft: 20,
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#1d5ac8',
  },
}));

export default function CandidateProfileEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { candidateId } = useParams();
  const [image, changeImage] = useImageUpload();
  const [FileInput, openDirectory] = useFileInput(
    IMAGE,
    changeImage,
    'image/*',
  );
  const {
    touchedServerData,
    editServerData,
    removeServerData,
    resetTouchedData,
  } = useServerProfileList();
  const { clientData, onCreate, onEdit, onRemove } =
    useClientProfileList(candidateId);
  const [editForm, setEditForm] = useState({
    avatarId: candidateId,
    name: null,
    age: null,
    gender: null,
    phoneNum: null,
    voice: null,
    profileList: [],
  });

  const {
    data: avatarProfile,
    isLoading,
    isError,
    isSuccess: hasProfileData,
  } = useQuery({
    queryKey: [AVATAR_PROFILE, candidateId],
    queryFn: async () => {
      const { data } = await getAvatarProfile(candidateId);

      return data;
    },
  });

  const {
    data: characters,
    isLoading: isCharactersLoading,
    isSuccess: hasCharacterData,
  } = useQuery(charactersQueries.list());

  const handleChangeEditForm = (event) => {
    const { name, value } = event.target;
    setEditForm({
      ...editForm,
      [name]: value,
    });
  };

  const { mutate: updateAvatarProfileMutation, isPending } = useMutation({
    mutationFn: async () => {
      await updateAvatarProfile(
        editForm,
        [...touchedServerData, ...clientData],
        image,
        candidateId,
      );
    },

    onSuccess: async () => {
      queryClient.invalidateQueries([AVATAR_PROFILE, candidateId]);
      navigate(`/candidate/profile/${candidateId}`);
    },
  });

  if (isLoading || isCharactersLoading) return <></>;
  if (isError) return <></>;
  return (
    <ContentBox component="main" maxWidth="xl">
      <PageTitle>프로필</PageTitle>
      <Box>
        <ProfileWrapper name={'사진'}>
          <AvatarImageBox
            src={image.url || avatarProfile.avatarImage?.downloadUrl}
          />
          <CommonButton onClick={openDirectory}>
            파일 업로드{FileInput()}
          </CommonButton>
          <span style={{ fontSize: '0.875rem', marginLeft: 20, color: '#888' }}>
            * 3:4 비율의 이미지를 권장합니다.
          </span>
        </ProfileWrapper>
        <ProfileWrapper name={'이름'}>
          <StyledInput
            placeholder="홍길동(본인)"
            name={'name'}
            value={editForm.name ?? avatarProfile.name}
            onChange={handleChangeEditForm}
          />
        </ProfileWrapper>
        <ProfileWrapper name={'나이'}>
          <CommonAgeInput
            name={'age'}
            type="number"
            min={0}
            value={editForm.age ?? avatarProfile.age}
            onChange={handleChangeEditForm}
          />
          <span>세</span>
        </ProfileWrapper>
        <ProfileWrapper name={'연락처'}>
          <StyledInput
            placeholder='연락처 "-빼고 입력해주세요"'
            type="tel"
            name={'phoneNum'}
            value={editForm.phoneNum ?? avatarProfile.phoneNum ?? ''}
            onChange={handleChangeEditForm}
          />
        </ProfileWrapper>
        <ProfileWrapper name={'성별'}>
          <CommonSelect
            name={'gender'}
            value={editForm.gender ?? avatarProfile.gender}
            onChange={handleChangeEditForm}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
          >
            <MenuItem value="Male">남</MenuItem>
            <MenuItem value="Female">여</MenuItem>
          </CommonSelect>
        </ProfileWrapper>
        <ProfileWrapper name={'캐릭터 목소리'}>
          <CommonSelect
            name={'voice'}
            value={editForm.voice ?? avatarProfile.characterId}
            onChange={handleChangeEditForm}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
            displayEmpty
          >
            {characters.map((character) => (
              <MenuItem key={character.id} value={character.id}>
                {character.name}
              </MenuItem>
            ))}
          </CommonSelect>
        </ProfileWrapper>
        <ProfileWrapper name={'인사말씀'}>
          {avatarProfile.profileList.find(
            (profile) => profile.type === profileType.SelfIntroduction,
          ) ? (
            <DynamicInput
              fullWidth
              multiline={true}
              rows={4}
              onEdit={editServerData}
              resetTouchedData={resetTouchedData}
              initialValue={
                avatarProfile.profileList.find(
                  (profile) => profile.type === profileType.SelfIntroduction,
                ).value
              }
              isServerData={true}
              data={
                avatarProfile.profileList.find(
                  (profile) => profile.type === profileType.SelfIntroduction,
                ) || {
                  avatarId: candidateId,
                  profileNum: Math.random(),
                  type: profileType.SelfIntroduction,
                  value: '',
                  handleType: null,
                }
              }
            />
          ) : (
            <DynamicInput
              fullWidth
              multiline={true}
              rows={4}
              onEdit={onEdit}
              initialValue={
                clientData.find(
                  (profile) => profile.type === profileType.SelfIntroduction,
                ).value
              }
              isServerData={false}
              data={clientData.find(
                (profile) => profile.type === profileType.SelfIntroduction,
              )}
            />
          )}
        </ProfileWrapper>
        <ProfileWrapper name={'약력'}>
          <Box width="80%">
            {avatarProfile.profileList
              .filter((profile) => profile.type === profileType.BriefHistory)
              .map((item) => (
                <DynamicInput
                  key={item.avatarId + item.profileNum}
                  onEdit={editServerData}
                  onRemove={removeServerData}
                  resetTouchedData={resetTouchedData}
                  data={item}
                  initialValue={item.value}
                  isServerData={true}
                />
              ))}
            {clientData
              .filter((profile) => profile.type === profileType.BriefHistory)
              .map((item) => (
                <DynamicInput
                  key={item.avatarId + item.profileNum}
                  onEdit={onEdit}
                  onCreate={onCreate}
                  onRemove={onRemove}
                  data={item}
                  initialValue={item.value}
                  isServerData={false}
                />
              ))}
          </Box>
        </ProfileWrapper>
        <ProfileWrapper name={'학력'}>
          <Box width="80%">
            {avatarProfile.profileList
              .filter(
                (profile) => profile.type === profileType.AcademicBackground,
              )
              .map((item) => (
                <DynamicInput
                  key={item.avatarId + item.profileNum}
                  onEdit={editServerData}
                  resetTouchedData={resetTouchedData}
                  onRemove={removeServerData}
                  data={item}
                  initialValue={item.value}
                  isServerData={true}
                />
              ))}
            {clientData
              .filter(
                (profile) => profile.type === profileType.AcademicBackground,
              )
              .map((item) => (
                <DynamicInput
                  key={item.avatarId + item.profileNum}
                  onEdit={onEdit}
                  onCreate={onCreate}
                  onRemove={onRemove}
                  data={item}
                  initialValue={item.value}
                  isServerData={false}
                />
              ))}
          </Box>
        </ProfileWrapper>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" mt={10}>
        <CancelButton
          disabled={isPending}
          onClick={() => {
            navigate(`/candidate/profile/${candidateId}`);
          }}
        >
          취소
        </CancelButton>
        <EditButton disabled={isPending} onClick={updateAvatarProfileMutation}>
          확인
        </EditButton>
      </Box>
    </ContentBox>
  );
}
