import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const LogoBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiAvatar-root': {
    width: 80,
    height: 80,
  },
  '& img': {
    maxWidth: '80px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: '3/4',
  },
}));

export const AvatarImageBox = ({ src }) => {
  const avatar = () => (src ? <img src={src} alt="프로필" /> : <Avatar />);
  return <LogoBox>{avatar()}</LogoBox>;
};
