import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, List, Pagination, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import LoadingSpinner from 'ui/LoadingSpinner';

import { AvatarCacheItem } from 'pages/avatars/components/missingQuestion/AvatarCacheItem';

import { cacheQueries } from 'react-query/factory/cacheQueries';

import { CommonStack } from 'components/styledMUI/CommonStack';

const ListStyled = styled(List)(({ theme }) => ({
  margin: '8px 0',
  backgroundColor: '#fff',
  border: '1px solid #d4d9e7',
  borderRadius: 10,
  minHeight: '10vh',
  '& .MuiListItem-root': {
    padding: 0,
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.938rem',
    fontWeight: 800,
    color: '#266be2',
    marginBottom: 4,
    '&:before': {
      content: '"Q."',
      display: 'inline-flex',
      marginRight: 4,
    },
  },
  '& .MuiListItemText-secondary': {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#000',
    '&:before': {
      content: '"A."',
      display: 'inline-flex',
      marginRight: 4,
    },
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
  },
}));

export const CacheList = ({ search }) => {
  const { candidateId } = useParams();
  const [page, setPage] = useState(1);

  const {
    isLoading,
    isError,
    error,
    data: avatarCache,
    isSuccess,
  } = useQuery({
    ...cacheQueries.cache(
      candidateId,
      'not allow empty string',
      'createdDatetime',
      page,
      search,
    ),
    enabled: !!search,
  });
  if (isLoading) return <LoadingSpinner isLoading={true} />;
  return (
    <ListStyled
      sx={{
        '& > :last-child': {
          borderBottom: 'none',
        },
      }}
    >
      {isSuccess && avatarCache.items.length > 0 ? (
        avatarCache.items.map((cache) => (
          <AvatarCacheItem key={cache.cacheId} data={cache} />
        ))
      ) : (
        <Box sx={{ m: 6, display: 'flex', justifyContent: 'center' }}>
          <Typography>
            {search.length > 0
              ? '검색 결과가 없습니다.'
              : '질문을 입력해주세요.'}
          </Typography>
        </Box>
      )}

      {isSuccess && avatarCache.maxPage > 1 && (
        <CommonStack spacing={2} mt={3}>
          <Pagination
            count={avatarCache.maxPage}
            page={page}
            onChange={(_event, page) => setPage(page)}
          />
        </CommonStack>
      )}
    </ListStyled>
  );
};
