import { makeStyles } from '@mui/styles';

import BgMaxRag from 'assets/images/BgMaxRag.jpg';

export const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    minHeight: '100dvh',
    display: 'flex',
    // background:
    // 	'linear-gradient(337deg, #27C3E2 0%, white 50%, rgba(39, 106, 226, 0.42) 100%)',
    backgroundImage: `url(${BgMaxRag})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: 'cover',
    '& *': {
      fontFamily: 'Pretendard!important',
    },
  },
  container: {
    '&.MuiContainer-root': {
      display: 'flex',
      margin: '0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  loginBox: {
    position: 'absolute',
    top: 30,
    right: 0,
    width: 150,
    '@media all and (max-width: 580px)': {
      width: 120,
    },
  },
  logoStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 240,
    height: 'min-content',
    marginBottom: 10,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '@media all and (max-width: 480px)': {
      '& svg': {
        width: '65%',
      },
      '& img': {
        width: '80%',
        height: '60%',
      },
    },
  },
  searchWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: 640,
  },
  textFieldStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: 40,
  },
  inputStyle: {
    '&.MuiFormControl-root': {
      width: 'calc(100% - 120px)',
    },
    // '& ::placeholder': {
    // 	fontSize: '0.8rem',
    // },
    '& .MuiInputBase-root': {
      fontSize: '1.5rem',
      fontWeight: 400,
      backgroundColor: '#fff',
      border: '1px solid #CCD1D6', //#27c3e2
      // boxShadow: '0px 2px 8px #27c3e22b',
      borderRadius: 10,
      paddingRight: 0,
      '& input:-internal-autofill-selected': {
        backgroundColor: '#fff!important',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
      {
        border: '0',
      },
    '@media all and (max-width: 640px)': {
      '& .MuiInputBase-root': {
        width: '100%',
        fontSize: '1.25rem',
      },
    },
    '@media all and (max-width: 480px)': {
      '& .MuiInputBase-root': {
        width: '100%',
        fontSize: '1.25rem',
      },
    },
  },
  recordWrapper: {
    // position: 'absolute',
    // left: 'calc(100% - 50px)',
  },
});
