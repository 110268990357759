import axios from 'axios';

const token = localStorage.getItem('token');

export const tokenInstance = axios.create({
  // baseURL: 'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json',
    'X-Auth-Token': token,
  },
});

export const defaultInstance = axios.create({
  // baseURL: 'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json',
  },
});

// const  getContentType = (url) => {
// 	if (url.includes('/upload')) {
// 		return 'multipart/form-data';
// 	} else if (url.includes('/form')) {
// 		return 'application/x-www-form-urlencoded';
// 	} else {
// 		return 'application/json';
// 	}
// };
//
// defaultInstance.interceptors.request.use(
// 	function (config) {
// 		const  token = localStorage.getItem('token');
// 		if (token) {
// 			config.headers['x-auth-token'] = token;
// 		}
//
// 		config.headers['Content-Type'] = getContentType(config.url);
//
// 		return config;
// 	},
// 	function (error) {
// 		return Promise.reject(error);
// 	},
// );
