import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DataGridBox = styled(Box)(() => ({
  marginTop: '16px',
  width: '100%',
  '& .MuiDataGrid-overlayWrapper': {
    width: '100%',
    height: '52px',
  },
  '& .cellCaption': {
    '&>div': {
      padding: '3px 6px',
      color: '#0b2759',
      backgroundColor: '#d3e1fa',
      borderRadius: 20,
      marginRight: '5px',
    },
  },
}));
