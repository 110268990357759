import { useOutletContext } from 'react-router-dom';

import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import useFileInput from 'hooks/useFileInput';

import { StyledIconButton } from 'ui/buttons/StyledIconButton';

import { useTeamFileUploadMutation } from 'react-query/mutations/data-files/team/useTeamFileUploadMutation';

import { PDF } from 'constants/fileFormats';

import { Loader } from 'components/styledMUI/Loader';

const ListStyled = styled('ul')(() => ({
  padding: 0,
  paddingLeft: 20,
  '& li': {
    marginBottom: 4,
  },
}));

export const UploadBlock = () => {
  const { teamId } = useOutletContext();
  const mutation = useTeamFileUploadMutation();
  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    mutation.mutate({ formData, teamId });
  };
  const [FileInput, openDirectory] = useFileInput(PDF, handleUpload);

  return (
    <Tooltip
      placement="right"
      // disableInteractive={isFileHandling}
      title={
        <ListStyled>
          <li>등록 가능한 파일 종류 : PDF</li>
          <li>파일 크기에 따라 업로드와 처리시간이 소요됩니다.</li>
        </ListStyled>
      }
    >
      <StyledIconButton onClick={openDirectory}>
        {mutation.isPending ? (
          <Loader sx={{ marginRight: '6px' }} />
        ) : (
          <PostAddRoundedIcon />
        )}
        공통 데이터 추가{FileInput()}
      </StyledIconButton>
    </Tooltip>
  );
};
