import { useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { DescriptionGrid } from './components/MyData/DescriptionGrid';
import { FileDataGrid } from './components/MyData/FileDataGrid';

import { ContentBox } from 'components/styledMUI/ContentBox';

const PageTitle = styled('h3')(({ theme }) => ({
  margin: '0 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

export default function MyData() {
  const { candidateId } = useParams();

  return (
    <ContentBox component="main" maxWidth="xl">
      <PageTitle>My 데이터</PageTitle>
      {candidateId && (
        <>
          <DescriptionGrid candidateId={candidateId} />
          <FileDataGrid candidateId={candidateId} />
        </>
      )}
    </ContentBox>
  );
}
