import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';

import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';

import CheckLogin from 'pages/auth/CheckLogin';
import Login from 'pages/auth/Login';
import AiQuestionManagement from 'pages/avatars/AiQuestionManagement';
import CandidateLayout from 'pages/avatars/CandidateLayout';
import CandidateProfile from 'pages/avatars/CandidateProfile';
import CandidateProfileEdit from 'pages/avatars/CandidateProfileEdit';
import CandidateSelection from 'pages/avatars/CandidateSelection';
import IdeaList from 'pages/avatars/IdeaList';
import PinnedQuestionManagement from 'pages/avatars/PinnedQuestionManagement';
import MissingQuestionManagement from 'pages/avatars/MissingQuestionManagement';
import CandidateManagerMonitoring from 'pages/avatars/Monitoring';
import MyData from 'pages/avatars/MyData';
import Preferences from 'pages/avatars/Preferences';
import PreferencesEdit from 'pages/avatars/PreferencesEdit';
import Preview from 'pages/avatars/Preview';
import QuestionLank from 'pages/avatars/QuestionLank';
import SystemAccountManagement from 'pages/system/AccountManagement';
import CharacterManagement from 'pages/system/CharacterManagement';
import { AvatarManagementRoute } from 'pages/teams/routes/AvatarManagementRoute';
import { ManagerManagementRoute } from 'pages/teams/routes/ManagerManagementRoute';
import { MonitoringRoute } from 'pages/teams/routes/MonitoringRoute';
import { TeamDataRoute } from 'pages/teams/routes/TeamDataRoute';
import { TeamInfoEditRoute } from 'pages/teams/routes/TeamInfoEditRoute';
import { TeamInfoRoute } from 'pages/teams/routes/TeamInfoRoute';
import { TeamLayout } from 'pages/teams/routes/TeamLayout';
import CandidateList from 'pages/user/features/search/CandidateList';
import CheckUser from 'pages/user/features/search/CheckUser';
import Main from 'pages/user/features/search/Main';
import Profile from 'pages/user/features/search/Profile';
import { QuestionRoute } from 'pages/user/routes/QuestionRoute';

import { queryClient } from 'react-query/query-client';

import { stores } from 'AppStores';

import { toastOptionProps } from 'lib/react-hot-toast/toast-option-props';
import * as ort from 'onnxruntime-web';

configure({ enforceActions: 'always' });

function App() {
  ort.env.wasm.wasmPaths = {
    'ort-wasm-simd-threaded.wasm': '/ort-wasm-simd-threaded.wasm',
    'ort-wasm-simd.wasm': '/ort-wasm-simd.wasm',
    'ort-wasm.wasm': '/ort-wasm.wasm',
    'ort-wasm-threaded.wasm': '/ort-wasm-threaded.wasm',
  };
  return (
    <QueryClientProvider client={queryClient}>
      <MobxProvider {...stores}>
        <Toaster {...toastOptionProps} />
        <Router>
          <CheckLogin />

          <Routes className="App">
            {/* 유저 화면 */}
            <Route index element={<Main />} />
            <Route path="/candidateList/:search" element={<CandidateList />} />
            <Route path="/candidateList" element={<CandidateList />} />
            <Route path="/checkUser/:candidateId" element={<CheckUser />} />
            <Route path="/profile/:candidateId" element={<Profile />} />
            <Route path="/question/:avatarId" element={<QuestionRoute />} />

            {/* 팀 관리자 화면 */}
            <Route path="team" element={<TeamLayout />}>
              <Route path="info" element={<TeamInfoRoute />} />
              <Route path="info/edit" element={<TeamInfoEditRoute />} />
              <Route path="management" element={<AvatarManagementRoute />} />
              <Route path="data" element={<TeamDataRoute />} />
              <Route path="manager" element={<ManagerManagementRoute />} />
              {/*  */}
              <Route path="monitoring" element={<MonitoringRoute />} />
            </Route>

            {/* 후보자 관리자 화면 */}
            <Route
              path="candidate/management"
              element={<CandidateSelection />}
            />
            <Route path="candidate" element={<CandidateLayout />}>
              <Route path="preferences">
                <Route path=":candidateId" element={<Preferences />} />
                <Route path="edit/:candidateId" element={<PreferencesEdit />} />
              </Route>
              <Route path="profile">
                <Route path=":candidateId" element={<CandidateProfile />} />
                <Route
                  path="edit/:candidateId"
                  element={<CandidateProfileEdit />}
                />
              </Route>
              <Route path="data/:candidateId" element={<MyData />} />
              <Route
                path="monitoring/:candidateId"
                element={<CandidateManagerMonitoring />}
              />
              <Route
                path="monitoring/question/:candidateId"
                element={<QuestionLank />}
              />
              <Route
                path="monitoring/idea/:candidateId"
                element={<IdeaList />}
              />
              <Route path="preview/:candidateId" element={<Preview />} />
              <Route
                path="pinned-question/:candidateId"
                element={<PinnedQuestionManagement />}
              />
              <Route
                path="missing-question/:candidateId"
                element={<MissingQuestionManagement />}
              />
              <Route
                path="ai-question/:candidateId"
                element={<AiQuestionManagement />}
              />
            </Route>

            {/* 시스템 관리자 화면 */}
            <Route path="/system/manager" element={<CharacterManagement />} />
            <Route
              path="/system/accountManagement"
              element={<SystemAccountManagement />}
            />

            {/*  로그인 */}
            <Route path="/login" element={<Login />} />

            {/* 404 Not Found */}
            <Route path="/*" element={<div>없는 페이지</div>} />
          </Routes>
        </Router>
      </MobxProvider>
    </QueryClientProvider>
  );
}

export default App;
