import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchAvatarQuestionRecords = async (body) => {
  const { data } = await tokenInstance.post(
    `${API.AVATARS}/question-records`,
    body,
  );
  return data;
};
