import { styled } from '@mui/material/styles';

export const CommonSearch = styled('div')(() => ({
  position: 'relative',
  width: '14rem',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  border: '1px solid #e8edf7',
  borderRadius: 10,
}));
