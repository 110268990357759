import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Box, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import useFileInput from 'hooks/useFileInput';
import useImageUpload from 'hooks/useImageUpload';

import { IMAGE } from 'constants/fileFormats';
import { AVATAR_CONFIG_DETAILS } from 'constants/queryKeys';

import { HANDLE_TYPE, profileType } from '../../constants/profileType';
import { useClientIPAddressList } from '../../hooks/CandidateManager/useClientIPAdressList';
import { useClientProfileList } from '../../hooks/CandidateManager/useClientProfileList';
import { useServerIPAdressList } from '../../hooks/CandidateManager/useServerIPAdressList';
import { useServerProfileList } from '../../hooks/CandidateManager/useServerProfileList';
import { validateIPAddress } from '../../lib/utils/validateIpAddress';
import { DynamicInput } from './components/DynamicInput';
import { ImageBox } from './components/ImageBox';

import {
  getAvatarConfigDetails,
  updateAvatarConfigExceptUseTeamFile,
} from 'api-legacy/avatars/getAvatarDescription';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { ExposeSwitch } from 'components/styledMUI/ExposeSwitch';
import { bodyToFormData } from 'lib/utils/bodyToFormData';
import { convertObjectToBlob } from 'lib/utils/convertObjectToBlob';

const PageTitle = styled('h3')(() => ({
  margin: '1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));
const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40,
  '& p': {
    fontSize: '1.125rem',
  },
}));
const SubTitle = styled('h4')(() => ({
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.063rem',
  marginRight: 20,
  width: 210,
  // borderBottom:'1px dashed #ddd',
  // paddingBottom:12,
}));

const StyledInput = styled(TextField)(() => ({
  minWidth: 360,
  border: '0 none',
  borderRadius: 10,
  '& input': {
    fontSize: '1.25rem',
    '&:before, &:after': {
      borderBottom: '1px solid #d4d9e7',
    },
  },
}));
const CommonButton = styled(Button)(() => ({
  color: '#282c34',
  backgroundColor: '#e8e8e8',
  marginLeft: 32,
  padding: '6px 24px',
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));

const CancelButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#333',
  backgroundColor: 'transparent',
  marginLeft: 20,
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  padding: '12px 24px',
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));
const EditButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#276AE2',
  marginLeft: 20,
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#1d5ac8',
  },
}));

export default function Home() {
  const { candidateId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [title, setTitle] = useState();
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const [expose, setExpose] = useState();
  const [accessPolicy, setAccessPolicy] = useState();

  const [image, changeImage] = useImageUpload();
  const [FileInput, openDirectory] = useFileInput(
    IMAGE,
    changeImage,
    'image/*',
  );

  const { data: avatarConfigDetails, isLoading } = useQuery({
    queryKey: [AVATAR_CONFIG_DETAILS, candidateId],
    queryFn: async () => {
      const { data } = await getAvatarConfigDetails(candidateId);
      return data;
    },
  });

  const { mutate: preferenceEditMutation, isPending } = useMutation({
    mutationFn: async () => {
      const isAccessPolicyTouched = accessPolicy !== undefined;
      const avatarConfig = {
        avatarId: candidateId,
        title: title,
        public: expose ?? avatarConfigDetails.public,
      };

      if (isAccessPolicyTouched) {
        avatarConfig.accessPolicy = accessPolicy ? 'WhiteList' : 'BlackList';
      } else {
        avatarConfig.accessPolicy = avatarConfigDetails.accessPolicy;
      }
      const avatarConfigBlob = convertObjectToBlob(avatarConfig);

      const accessAddresses =
        accessPolicy ?? avatarConfigDetails.accessPolicy === 'WhiteList'
          ? [...touchedServerData, ...clientData].filter(
              (data) => !!data.handleType,
            )
          : [];

      const body = {
        avatarConfig: avatarConfigBlob,
        image: image.file ? image.file : null,
        accessAddresses: convertObjectToBlob(accessAddresses),
      };

      const formData = bodyToFormData(body);
      await updateAvatarConfigExceptUseTeamFile(candidateId, formData);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([candidateId]);
      navigate(`/candidate/preferences/${candidateId}`);
    },
  });

  const handleChangeExpose = () => {
    setExpose(expose === undefined ? !avatarConfigDetails.public : !expose);
  };
  const handleChangeIsWhiteList = (e) => {
    const { checked } = e.target;
    checked ? setAccessPolicy(true) : setAccessPolicy(false);
  };
  const {
    touchedServerData,
    editServerData,
    removeServerData,
    resetTouchedData,
  } = useServerIPAdressList();
  const { clientData, onCreate, onEdit, onRemove } = useClientIPAddressList();

  const notAllowedIPSubmit = () => {
    const allowed = [...touchedServerData, ...clientData].map((item) => {
      if (
        item.handleType === HANDLE_TYPE.create ||
        item.handleType === HANDLE_TYPE.update
      ) {
        return validateIPAddress(item.address);
      } else {
        return true;
      }
    });
    return allowed.find((element) => element === false) !== undefined;
  };
  if (isLoading) return <></>;
  return (
    <ContentBox component="main" maxWidth="xl">
      <PageTitle>환경설정</PageTitle>
      <Box>
        <BoxStyled>
          <SubTitle>사진</SubTitle>
          <ImageBox
            src={image.url || avatarConfigDetails.posterImage?.downloadUrl}
          />
          <CommonButton onClick={openDirectory} disabled={isPending}>
            파일 업로드{FileInput()}
          </CommonButton>
        </BoxStyled>
        <BoxStyled>
          <SubTitle>타이틀</SubTitle>
          <StyledInput
            disabled={isPending}
            variant="outlined"
            value={title ?? avatarConfigDetails.title}
            onChange={handleChangeTitle}
          />
        </BoxStyled>
        <BoxStyled>
          <SubTitle>외부노출 허용</SubTitle>
          <ExposeSwitch
            disabled={isPending}
            checked={expose ?? avatarConfigDetails.public}
            onClick={handleChangeExpose}
          />
        </BoxStyled>
        <BoxStyled>
          <SubTitle>특정 IP만 허용</SubTitle>
          <ExposeSwitch
            checked={
              accessPolicy ?? avatarConfigDetails.accessPolicy === 'WhiteList'
            }
            onClick={handleChangeIsWhiteList}
          />
        </BoxStyled>
        {(accessPolicy ?? avatarConfigDetails.accessPolicy === 'WhiteList') && (
          <SubTitle sx={{ width: '100%' }}>
            0 ~ 255의 숫자를 &apos;&nbsp;.&nbsp;&apos; 으로 구분한 네 자릿 수의
            형식으로 작성해주세요.
          </SubTitle>
        )}
      </Box>
      {(accessPolicy ?? avatarConfigDetails.accessPolicy === 'WhiteList') && (
        <Box width="80%">
          {avatarConfigDetails.accessAddresses.map((address) => (
            <DynamicInput
              key={address.addressNum}
              onEdit={editServerData}
              onRemove={removeServerData}
              resetTouchedData={resetTouchedData}
              data={address}
              initialValue={address.address}
              isServerData={true}
            />
          ))}
          {clientData.map((address) => (
            <DynamicInput
              key={address.addressNum}
              onEdit={onEdit}
              onCreate={onCreate}
              onRemove={onRemove}
              data={address}
              initialValue={address.address}
              isServerData={false}
            />
          ))}
        </Box>
      )}
      <Box width="100%" display="flex" justifyContent="flex-end">
        <CancelButton
          disabled={isPending}
          onClick={() => {
            navigate(`/candidate/preferences/${candidateId}`);
          }}
        >
          취소
        </CancelButton>
        <EditButton
          onClick={() => {
            if (notAllowedIPSubmit()) {
              toast.error('올바른 IP 주소 형식을 입력해주세요.');
            } else {
              preferenceEditMutation();
            }
          }}
          disabled={isPending}
        >
          확인
        </EditButton>
      </Box>
    </ContentBox>
  );
}
