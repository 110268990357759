import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useQuery } from '@tanstack/react-query';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import useFileInput from 'hooks/useFileInput';
import useImageUpload from 'hooks/useImageUpload';

import LoadingSpinner from 'ui/LoadingSpinner';

import { useUpdateCharacterMutation } from 'react-query/mutations/characters/useUpdateCharacterMutation';

import { getCharacterVoices } from 'api/voice/voice';

import { IMAGE } from 'constants/fileFormats';

import { useCreateCharacterMutation } from '../../react-query/mutations/characters/useCreateCharacterMutation';

const LogoBox = styled('div')(({ theme }) => ({
  width: 120, // 임시 사이즈
  height: 120,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fafafa',
  border: '1px dashed #ddd',
  '& img': {
    width: 120,
    height: 120,
    objectFit: 'cover',
  },
}));

const CommonButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#525252',
  marginLeft: 32,
  padding: '6px 24px',
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#525252',
  },
}));

const BoxComponent = styled('div')(({ theme }) => ({
  marginTop: 20,
  padding: '24px 32px',
  backgroundColor: '#F1F1F1',
  borderRadius: 10,
}));

const GridBoxComponent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridColumnGap: 20,
  // marginTop:20,
  // padding:'24px 32px',
  // backgroundColor:'#F1F1F1',
  // borderRadius:10,
  '& > div': {
    marginBottom: 20,
  },
}));

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: '0.938rem',
  marginBottom: 4,
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff',
  border: '0 none',
  '& fieldset': {
    border: '0 none',
  },
  '& input': {
    padding: '12px 14px',
  },
}));

const CommonSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#fff',
  '& fieldset': {
    border: '0 none',
  },
  '& svg': {
    color: '#869592',
  },
  '& .MuiSelect-select': {
    padding: '12px 14px',
  },
}));

const PrimaryIconButton = styled(Button)(({ theme }) => ({
  minWidth: 72,
  width: '100%',
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '16px 14px',
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  margin: '0 4px',
  '& svg': {
    color: '#fff',
    marginRight: 4,
  },
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));

export default function CharacterAddBox(props) {
  const [image, changeImage] = useImageUpload();
  const [FileInput, openDirectory] = useFileInput(
    IMAGE,
    changeImage,
    'image/*',
  );

  const [inputData, setInputData] = useState({
    name: '',
    description: '',
    gender: 'Male',
    age: '',
    voiceId: '',
    prompt: '',
    welcomeMessage: '',
  });

  useEffect(() => {
    if (props.changeMode) {
      setInputData({
        id: props.changeData.id,
        name: props.changeData.name,
        description: props.changeData.description,
        gender: props.changeData.gender,
        age: props.changeData.age,
        voiceId: props.changeData.voiceId,
        prompt: props.changeData.prompt,
        welcomeMessage: props.changeData.welcomeMessage,
        imageObjectId: props.changeData.imageObjectId,
      });
    } else {
      setInputData({
        name: '',
        description: '',
        gender: 'Male',
        age: '',
        voiceId: '',
        prompt: '',
        welcomeMessage: '',
      });
    }
  }, [props.changeMode, props.changeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleChangeDropdown = (value, name) => {
    setInputData({
      ...inputData,
      [`${name}`]: value,
    });
  };

  const { data: characterVoices, isLoading } = useQuery({
    queryKey: ['voice'],
    queryFn: () => getCharacterVoices(),
  });

  const { mutate: createNewCharacterMutation, isPending: createIsPending } =
    useCreateCharacterMutation();

  const { mutate: updateCharacterMutation, isPending: updateIsPending } =
    useUpdateCharacterMutation();

  const isEmptyObject = () => {
    for (let key in inputData) {
      if (inputData[key].toString().trim() === '') {
        return true;
      }
    }
    return false;
  };

  const handleAddCharacter = () => {
    if (isEmptyObject()) {
      return toast('누락된 내용이 있습니다.');
    }

    if (props.changeMode) {
      updateCharacterMutation(
        {
          inputData: inputData,
          image: image.file.size > 0 ? image.file : null,
        },
        {
          onSuccess: () => {
            props.setChangeMode(false);
            props.setAddUser(false);
          },
        },
      );
    } else if (image.file.size > 0) {
      createNewCharacterMutation(
        { inputData: inputData, image: image.file },
        {
          onSuccess: () => {
            props.setChangeMode(false);
            props.setAddUser(false);
          },
        },
      );
    } else return toast('캐릭터 이미지를 업로드해주세요.');
  };

  if (isLoading || createIsPending || updateIsPending)
    return <LoadingSpinner isLoading={true} />;

  return (
    <BoxComponent>
      <FormControl style={{ marginBottom: 20 }}>
        <LabelText>캐릭터 이미지</LabelText>
        <Box display="flex">
          <LogoBox>
            {image.url ? (
              <img src={image.url} alt="캐릭터" />
            ) : props.changeData.downloadUrl ? (
              <img src={props.changeData.downloadUrl} alt="캐릭터" />
            ) : (
              '미리보기'
            )}
          </LogoBox>
          <CommonButton onClick={openDirectory}>
            파일 업로드{FileInput()}
          </CommonButton>
        </Box>
      </FormControl>
      <GridBoxComponent>
        <FormControl>
          <LabelText>캐릭터 명</LabelText>
          <StyledInput
            autoComplete={'off'}
            value={inputData.name}
            name="name"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <LabelText>캐릭터 설명</LabelText>
          <StyledInput
            autoComplete={'off'}
            value={inputData.description}
            name="description"
            onChange={handleChange}
            placeholder="홍길동"
          />
        </FormControl>
        {/*<FormControl>*/}
        {/*    <LabelText>이름</LabelText>*/}
        {/*    <StyledInput placeholder="홍길동"/>*/}
        {/*</FormControl>*/}
        <FormControl>
          <LabelText>성별</LabelText>
          <CommonSelect
            value={inputData.gender}
            onChange={(e) => handleChangeDropdown(e.target.value, 'gender')}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
            displayEmpty
          >
            <MenuItem value="Male">남</MenuItem>
            <MenuItem value="Female">여</MenuItem>
          </CommonSelect>
        </FormControl>
        <FormControl>
          <LabelText>나이</LabelText>
          <StyledInput
            value={inputData.age}
            name="age"
            onChange={handleChange}
            placeholder="24"
            autoComplete={'off'}
            type="number"
          />
        </FormControl>
        <FormControl>
          <LabelText>목소리</LabelText>
          <CommonSelect
            value={inputData.voiceId}
            onChange={(e) => handleChangeDropdown(e.target.value, 'voiceId')}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
            renderValue={(selected) => {
              const result = characterVoices.find(
                (voice) => voice.id === selected,
              )?.description;

              return result ?? selected;
            }}
            displayEmpty
          >
            {characterVoices &&
              characterVoices.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.description}
                </MenuItem>
              ))}
          </CommonSelect>
        </FormControl>
      </GridBoxComponent>
      <Box display="flex" flexDirection="column">
        <FormControl style={{ marginBottom: 20 }}>
          <LabelText>프롬프트</LabelText>
          <StyledInput
            value={inputData.prompt}
            name="prompt"
            onChange={handleChange}
            multiline
            placeholder="프롬프트 작성"
            rows={3}
          ></StyledInput>
        </FormControl>
        <FormControl>
          <LabelText>인사말</LabelText>
          <StyledInput
            value={inputData.welcomeMessage}
            name="welcomeMessage"
            onChange={handleChange}
            multiline
            placeholder="인사말 작성"
            rows={3}
          ></StyledInput>
        </FormControl>
        <Box
          onClick={handleAddCharacter}
          display="flex"
          justifyContent="space-around"
          mt={4}
        >
          <PrimaryIconButton>
            <CheckRoundedIcon /> {props.changeMode ? '수정' : '추가'}
          </PrimaryIconButton>
        </Box>
      </Box>
    </BoxComponent>
  );
}
