import toast from 'react-hot-toast';

import { useMutation } from '@tanstack/react-query';

import { changePassword } from 'api/managers/changePassword';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useChangePasswordMutation = () => {
  return useMutation({
    mutationFn: ({ id, password }) => changePassword(id, password),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
  });
};
