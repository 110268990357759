import { API } from 'constants/api-endpoints';

import { defaultInstance } from 'lib/axios/instance';

export const queryTextQuestion = async (userQueryData) => {
  const { data } = await defaultInstance.post(
    `${API.USERS}/questions`,
    userQueryData,
  );
  return data;
};
