import { Box, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import NoRowsComponent from 'ui/datagrid/NoRowsComponent';

const DataGridBox = styled(Box)(() => ({
  marginTop: '16px',
  width: '100%',
  '& .MuiDataGrid-overlayWrapper': {
    width: '100%',
    height: '52px',
  },
  '& .cellCaption': {
    '&>div': {
      padding: '3px 6px',
      color: '#0b2759',
      backgroundColor: '#d3e1fa',
      borderRadius: 20,
      marginRight: '5px',
    },
  },
}));

export const DataBox = ({
  getRowId = (row) => row.id,
  data = [],
  columns = [],
  total,
  pagination,
}) => {
  return (
    <DataGridBox>
      <MuiDataGrid
        getRowId={getRowId}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#333',
            color: '#fff',
            '& svg': {
              color: '#fff',
            },
          },
        }}
        slots={{
          noResultsOverlay: NoRowsComponent,
          noRowsOverlay: NoRowsComponent,
          footer: () => (
            <Box
              sx={{
                height: 52,
                display: 'flex',
                justifyContent: 'end',
                alignContent: 'center',
              }}
            >
              <TablePagination
                component={Box}
                sx={{
                  height: '100%',
                }}
                count={total}
                page={pagination.page ?? 0}
                onPageChange={(event, page) => {
                  pagination.onPageChange(page);
                }}
                onRowsPerPageChange={(event) => {
                  const { value } = event.target;
                  pagination.onPageChange(0);
                  pagination.onPerPageChange(value);
                }}
                rowsPerPage={pagination.rowsPerPage ?? 5}
                rowsPerPageOptions={[5, 10, 20]}
                labelRowsPerPage="페이지당 데이터: "
              />
            </Box>
          ),
        }}
        rows={data}
        columns={columns}
      />
    </DataGridBox>
  );
};
