import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { AVATAR_DESCRIPTIONS } from 'constants/queryKeys';

import { BootstrapDialog } from './components/BootstrapDialog';
import { BoxComponent } from './components/BoxComponent';
import { DialogInput } from './components/DialogInput';

import {
  createAvatarDescription,
  updateAvatarDescription,
} from 'api-legacy/avatarDescription';

const AppBarStyled = styled(AppBar)(() => ({
  color: '#333',
  boxShadow: 'none',
}));

const LabelText = styled(Typography)(() => ({
  fontSize: '0.938rem',
  marginBottom: 4,
}));

const PrimaryButton = styled(Button)(() => ({
  minWidth: 72,
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  margin: '0 4px',
  '&:hover': {
    backgroundColor: '#276AE2',
  },
  '&.Mui-disabled': {
    color: 'rgb(255 255 255 / 26%)',
  },
}));

const CancelButton = styled(Button)(() => ({
  minWidth: 72,
  color: '#fff',
  backgroundColor: '#b8b8bb',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  margin: '0 4px',
  '&:hover': {
    backgroundColor: '#b8b8bb',
  },
}));

export default function CorePromisesDialog({
  open,
  handleClose,
  title,
  editItem = {
    title: '',
    value: '',
  },
  changeMode,
}) {
  const queryClient = useQueryClient();

  const params = useParams();

  const [descriptionTitle, setDescriptionTitle] = useState();

  const [value, setValue] = useState();

  const handleChangeDescriptionTitle = (e) => {
    const { value } = e.target;
    setDescriptionTitle(value);
  };
  const handleChangeValue = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  const { mutate: createAvatarDescriptionMutation } = useMutation({
    mutationFn: async () =>
      await createAvatarDescription({
        avatarId: params.candidateId,
        avatarDescription: { title: descriptionTitle, value },
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [AVATAR_DESCRIPTIONS, params.candidateId],
      });
    },
  });

  const { mutate: updateAvatarDescriptionMutation, isPending: isUpdating } =
    useMutation({
      mutationFn: async (descriptionNum) =>
        await updateAvatarDescription({
          avatarId: params.candidateId,
          descriptionNum: descriptionNum,
          avatarDescription: {
            title: descriptionTitle ?? editItem.title,
            value: value ?? editItem.value,
          },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [AVATAR_DESCRIPTIONS, params.candidateId],
        });
      },
    });

  const handleClickSave = () => {
    if (changeMode) {
      updateAvatarDescriptionMutation(editItem.descriptionNum);
    } else {
      if (
        !(descriptionTitle && value) ||
        descriptionTitle.trim().length === 0 ||
        descriptionTitle.trim().length === 0
      )
        return;
      createAvatarDescriptionMutation();
    }
    handleClose();
  };

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <AppBarStyled sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBarStyled>
      <BoxComponent>
        <Box width={'100%'}>
          <LabelText>질문</LabelText>
          <DialogInput
            onChange={handleChangeDescriptionTitle}
            name="title"
            value={descriptionTitle ?? editItem.title}
            placeholder="질문"
          />
        </Box>
        <Box width={'100%'}>
          <LabelText>답변 내용</LabelText>
          <DialogInput
            onChange={handleChangeValue}
            name="value"
            value={value ?? editItem.value}
            placeholder="답변 내용"
            fullWidth
            multiline
            rows={4}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <CancelButton disabled={isUpdating} onClick={handleClose}>
            취소
          </CancelButton>
          <PrimaryButton disabled={isUpdating} onClick={handleClickSave}>
            {changeMode ? '수정' : '추가'}
          </PrimaryButton>
        </Box>
      </BoxComponent>
    </BootstrapDialog>
  );
}
