import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { filesQueries } from 'react-query/factory/filesQueries';

import { deleteAvatarFile } from 'api/data-files/avatars/deleteAvatarFile';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useAvatarFileDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { candidateId } = useParams();
  return useMutation({
    mutationFn: (id) => deleteAvatarFile(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: filesQueries.lists('Avatar', candidateId),
      });
      toast.success(TOAST_MESSAGE.SUCCESS.DELETE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.DELETE_ERROR);
    },
  });
};
