import { useState } from 'react';

export default function useImageUpload() {
  const [image, setImage] = useState({
    file: {},
    url: '',
  });

  const changeImage = (imageFile) => {
    setImage({
      file: imageFile.name ? imageFile : {},
      url: imageFile.name ? URL.createObjectURL(imageFile) : '',
    });
  };

  return [image, changeImage];
}
