// export const   styles = _theme => ({
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: '#F0F2F4',
    padding: '0px 50px 20px!important',
    boxSizing: 'border-box',
    '@media all and (max-width: 640px)': {
      padding: '0px 24px 20px!important',
    },
  },
  container: {
    '@media all and (max-width: 420px)': {
      padding: '0',
    },
  },
  titleBox: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-h2': {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    '@media all and (max-width: 640px)': {
      height: 'auto',
      padding: '40px 0 0',
      '& .MuiTypography-h2': {
        fontSize: '1.75rem',
      },
    },
    '@media all and (max-width: 420px)': {
      '& .MuiTypography-h2': {
        fontSize: '1.5rem',
      },
    },
  },
  subtitle: {
    display: 'flex',
    fontSize: '1.625rem',
    fontWeight: 600,
    marginBottom: '30px!important',
    '@media all and (max-width: 640px)': {
      fontSize: '1.5rem',
      marginTop: '32px!important',
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1.25rem',
    },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      width: 'calc(50% - 20px)',
      '@media all and (max-width: 640px)': {
        width: 'calc(50% - 10px)',
      },
    },
  },
  gridBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: 40,
    gridRowGap: 30,
    '@media all and (max-width: 640px)': {
      gridColumnGap: 16,
      gridRowGap: 16,
    },
  },
  buttonStyle: {
    '&.MuiButton-root': {
      height: 120,
      color: '#240B0D',
      fontSize: '1.875rem',
      fontWeight: 600,
      backgroundColor: '#fff',
      border: '1px solid #e8edf7',
      borderRadius: 20,
      boxShadow: '0 2px 10px #C6CACF',
      boxSizing: 'border-box',
      '& > svg': {
        marginRight: 20,
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
      '@media all and (max-width: 640px)': {
        height: 'auto',
        padding: '16px',
        fontSize: '1.125rem',
        letterSpacing: '-0.8px',
        '& > svg': {
          width: 32,
          height: 32,
          marginRight: 10,
        },
      },
      '@media all and (max-width: 420px)': {
        fontSize: '1rem',
        letterSpacing: 0,
      },
    },
  },
  buttonStyleActive: {
    '&.MuiButton-root': {
      fontWeight: 700,
      color: '#27C3E2',
      borderColor: '#27C3E2',
      borderWidth: 3,
      boxShadow: 'none',
    },
  },
  nextButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: 24,
    border: 'none',
    color: '#bbb2b2',
    backgroundColor: '#DBDBDB',
    fontSize: '1.625rem',
    fontWeight: 700,
    textDecoration: 'none',
    borderRadius: 20,
    marginTop: 80,
    '&:hover': {
      backgroundColor: '#DBDBDB',
    },
    '@media all and (max-width: 640px)': {
      marginTop: 40,
      padding: '16px 24px',
      fontSize: '1.25rem',
    },
  },
  nextButtonActive: {
    backgroundColor: '#27C3E2',
    color: '#fff',
    boxShadow: '0 2px 12px #27C3E273',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#27C3E2',
    },
  },
});
