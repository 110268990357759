import { useState } from 'react';

import { HANDLE_TYPE } from 'constants/profileType';

export const useServerIPAdressList = () => {
  const [touchedServerData, setTouchedServerData] = useState([]);

  const editServerData = (value, data) => {
    const { update } = HANDLE_TYPE;
    touchedServerData.find(
      (serverData) => serverData.addressNum === data.addressNum,
    )
      ? setTouchedServerData(
          touchedServerData.map((serverData) => {
            const lock = serverData.addressNum;
            const key = data.addressNum;
            if (lock === key) {
              return {
                ...serverData,
                address: value,
                handleType: update,
              };
            } else {
              return serverData;
            }
          }),
        )
      : setTouchedServerData([
          ...touchedServerData,
          {
            ...data,
            address: value,
            handleType: update,
          },
        ]);
  };

  const removeServerData = (data) => {
    const { willBeDeleted } = HANDLE_TYPE;
    touchedServerData.find(
      (serverData) => serverData.addressNum === data.addressNum,
    )
      ? setTouchedServerData(
          touchedServerData.map((serverData) => {
            const lock = serverData.addressNum;
            const key = data.addressNum;
            if (lock === key) {
              return {
                ...serverData,
                handleType: willBeDeleted,
              };
            } else {
              return serverData;
            }
          }),
        )
      : setTouchedServerData([
          ...touchedServerData,
          {
            ...data,
            handleType: willBeDeleted,
          },
        ]);
  };

  const resetTouchedData = (data) => {
    setTouchedServerData(
      touchedServerData.filter(
        (addressData) => addressData.addressNum !== data.addressNum,
      ),
    );
  };
  return {
    touchedServerData,
    editServerData,
    removeServerData,
    resetTouchedData,
  };
};
