import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Button, Grid } from '@mui/material';

import { PinnedQuestionContext } from './PinnedQuestionContext';
import { PinnedQuestionGrid } from './PinnedQuestionGrid';
import { PinnedQuestionCacheItem } from './PinnedQuestionCacheItem';
import { styled } from '@mui/material/styles';
import { categoryQueries } from '../../../../react-query/factory/categoryQueries';
import { AddCacheDialog } from '../../../../components/Dialog/AddCacheDialog';
import { AddCacheCategoryDialog } from '../../../../components/Dialog/AddCacheCategoryDialog';
import { DeleteConfirmDialog } from '../../../../components/Dialog/DeleteConfirmDialog';

const CommonButtonBox = styled(Box)(() => ({
  display: 'flex',
  '& :last-of-type': {
    marginRight: 0,
  },
}));

const CommonButton = styled(Button)(({ operation }) => ({
  width: '100%',
  color: '#fff',
  backgroundColor: operation === 'DELETE' ? '#d32f2f' : '#276AE2',
  padding: '12px 24px',
  marginTop: 8,
  fontSize: '1.125rem',
  fontWeight: 600,
  borderRadius: 8,
  marginRight: 5,
  '&:hover': {
    backgroundColor: operation === 'DELETE' ? '#d32f2f' : '#276AE2',
  },
}));
export const PinnedQuestionController = ({ candidateId }) => {
  const {
    page,
    mainCategory,
    selectedCategory,
    openCacheDialog,
    openAddCacheCategoryDialog,
    openAddCacheDialog,
    openCacheCategoryDialog,
    closeAddCacheDialog,
    closeAddCacheCategoryDialog,
    handleChangePage,
    categoryDeleteConfirmDialog,
    openCategoryDeleteConfirmDialog,
    closeCategoryDeleteConfirmDialog,
    handleDeleteCategory,
    isEdit,
    setSelectedCategory,
  } = useContext(PinnedQuestionContext);

  const {
    isLoading,
    isError,
    data: avatarCacheCategories,
    isSuccess,
  } = useQuery(
    categoryQueries.category(candidateId, 'allow empty string', page),
  );

  if (isLoading) return <></>;

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          {mainCategory ? (
            <CommonButtonBox>
              {!selectedCategory.parentId && (
                <CommonButton onClick={openAddCacheCategoryDialog}>
                  카테고리 등록
                </CommonButton>
              )}
              <CommonButton onClick={(_e) => openAddCacheCategoryDialog(true)}>
                카테고리 수정
              </CommonButton>
              <CommonButton
                operation={'DELETE'}
                onClick={openCategoryDeleteConfirmDialog}
              >
                카테고리 삭제
              </CommonButton>
            </CommonButtonBox>
          ) : (
            <CommonButton onClick={openAddCacheCategoryDialog}>
              카테고리 등록
            </CommonButton>
          )}
          {isSuccess && (
            <PinnedQuestionGrid
              page={page}
              handleChangePage={handleChangePage}
              avatarCacheCategories={avatarCacheCategories}
              mainCategories={avatarCacheCategories}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {mainCategory &&
          mainCategory.categoryId !== selectedCategory.categoryId &&
          !selectedCategory.cacheId ? (
            <CommonButton onClick={openAddCacheDialog}>캐시 등록</CommonButton>
          ) : (
            <Box style={{ height: 64 }} />
          )}
          <PinnedQuestionCacheItem openAddCacheDialog={openAddCacheDialog} />
        </Grid>
      </Grid>
      {openCacheDialog && (
        <AddCacheDialog
          open={openCacheDialog}
          title={'캐시 등록'}
          handleClose={closeAddCacheDialog}
          candidateId={candidateId}
          selectedCategory={selectedCategory}
        />
      )}
      {openCacheCategoryDialog && (
        <AddCacheCategoryDialog
          open={openCacheCategoryDialog}
          title={isEdit ? '카테고리 수정' : '카테고리 등록'}
          handleClose={closeAddCacheCategoryDialog}
          candidateId={candidateId}
          avatarCacheCategories={avatarCacheCategories}
          selectedCategory={selectedCategory}
          mainCategory={mainCategory}
          isEdit={isEdit}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {categoryDeleteConfirmDialog && (
        <DeleteConfirmDialog
          title={'삭제하시겠습니까?'}
          open={categoryDeleteConfirmDialog}
          handleClose={closeCategoryDeleteConfirmDialog}
          handleDelete={handleDeleteCategory}
          // pending={deleteCache.isPending}
          maxWidth={'xs'}
        />
      )}
    </>
  );
};
