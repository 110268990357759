import { useState } from 'react';

import { Box } from '@mui/material';

import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SearchBar } from 'ui/inputs/SearchBar';

import { ManagersDialogGrid } from 'pages/teams/features/management/components/blocks/ManagersDialogGrid';
import { MatchButton } from 'pages/teams/features/management/components/cells/MatchButton';
import { MatchedAvatar } from 'pages/teams/features/management/components/cells/MatchedAvatar';

import { useDebouncedValue } from 'rooks';

export const ManagerTableDialog = ({ open, currentAvatar, handleClose }) => {
  const [searchValue, setSearchValue] = useState('');
  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };
  const [filter, hotUpdate] = useDebouncedValue(searchValue, 500);

  const columns = [
    { field: 'name', headerName: '이름', width: 100 },
    { field: 'loginId', headerName: '아이디', flex: 1 },
    {
      field: 'manager',
      headerAlign: 'center',
      align: 'center',
      headerName: '담당 후보',
      width: 120,
      flex: true,
      flexWrap: true,
      cellClassName: 'cellCaption',
      renderCell: (params) => {
        const { id: managerId } = params.row;

        return <MatchedAvatar managerId={managerId} />;
      },
    },
    {
      field: 'management',
      headerName: '관리',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { id: managerId } = params.row;
        return (
          <MatchButton avatarId={currentAvatar.id} managerId={managerId} />
        );
      },
    },
  ];
  return (
    <DialogWrapper
      sx={{
        '& .MuiDialog-container > .MuiPaper-root': {
          minWidth: 840,
          backgroundColor: '#dae0e6',
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogHeader
        title={`${currentAvatar.name} 캐릭터 매니저 지정하기`}
        handleClose={handleClose}
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ margin: '16px 24px 0 0 ' }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            hotUpdate();
          }}
        >
          <SearchBar
            placeholder={'매니저 검색'}
            searchValue={searchValue}
            onChange={handleChangeSearchValue}
          />
        </form>
      </Box>
      <ManagersDialogGrid filter={filter} columns={columns} />
    </DialogWrapper>
  );
};
