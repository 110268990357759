import { memo, useContext } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';

import { GridBoxItem } from 'pages/user/features/question/components/styled';

import { useCacheHitCountMutation } from 'react-query/mutations/questions/useCacheHitCountMutation';

import { ChatContext } from '../context/ChatProvider';

const Cache = ({ cache }) => {
  const { avatarId } = useParams();
  const mutation = useCacheHitCountMutation();
  const { handleRequestQuery } = useContext(ChatContext);

  const handleSubmit = async () => {
    await handleRequestQuery({
      content: cache.question,
      timestamp: Date.now(),
    });
    mutation.mutate({ avatarId, cacheId: cache.cacheId });
  };
  return (
    <GridBoxItem variant="outlined" color="neutral" onClick={handleSubmit}>
      <Typography variant="subTitle2">{cache.question}</Typography>
      <Typography variant="body2">
        {cache.hitCount} 명의 사용자가 질문했어요.
      </Typography>
    </GridBoxItem>
  );
};
export default memo(Cache);
