import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cacheQueries } from 'react-query/factory/cacheQueries';

import { updateAiCacheAnswer } from 'api/caches/updateAiCacheAnswer';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useUpdateCacheAnswerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => updateAiCacheAnswer(params),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheQueries.all() });
    },
  });
};
