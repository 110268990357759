import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import useFileInput from 'hooks/useFileInput';
import { usePaginationProps } from 'hooks/usePaginationProps';

import { DataBox } from 'ui/datagrid';
import LoadingSpinner from 'ui/LoadingSpinner';

import { DataCheck } from 'pages/teams/features/team-data/components/cells/DataCheck';

import { filesQueries } from 'react-query/factory/filesQueries';
import { useAvatarFileUploadMutation } from 'react-query/mutations/data-files/avatar/useAvatarFileUploadMutation';
import { useFileUsableMutation } from 'react-query/mutations/data-files/useFileUsableMutation';

import { AVATAR } from 'constants/fileFormats';
import { AVATAR_CONFIG_DETAILS } from 'constants/queryKeys';

import { FileManagementCell } from './FileManagementCell';

import {
  getAvatarConfigDetails,
  updateAvatarConfigUseTeamFile,
} from 'api-legacy/avatars/getAvatarDescription';
import { CommonIconButton } from 'components/styledMUI/CommonIconButton';
import { CommonSwitch } from 'components/styledMUI/CommonSwitch';
import { DataGridBox } from 'components/styledMUI/DataGridBox';
import { Loader } from 'components/styledMUI/Loader';
import { DATE_UTIL } from 'lib/utils/date.util';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  width: '100%',
  margin: 0,
  paddingLeft: 10,
  boxSizing: 'border-box',
  '& .MuiCheckbox-root': {
    margin: '0 12px',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ListStyled = styled('ul')(() => ({
  padding: 0,
  paddingLeft: 20,
  '& li': {
    marginBottom: 4,
  },
}));

export const FileDataGrid = ({ candidateId }) => {
  const usableMutation = useFileUsableMutation();
  const mutation = useAvatarFileUploadMutation();
  const [FileInput, openDirectory] = useFileInput(AVATAR, mutation.mutate);
  const queryClient = useQueryClient();
  const pagination = usePaginationProps();
  const files = useQuery(
    filesQueries.list(
      'Avatar',
      candidateId,
      pagination.rowsPerPage,
      '',
      pagination.page + 1,
    ),
  );
  const { data: useTeamFiles, isLoading: isLoadingConfig } = useQuery({
    queryKey: [AVATAR_CONFIG_DETAILS, candidateId, 'team file'],
    queryFn: async () => {
      const { data } = await getAvatarConfigDetails(candidateId);
      return data.useTeamFiles;
    },
    placeholderData: keepPreviousData,
  });

  const {
    mutate: changeUseTeamFileMutation,
    isPending: isChangingUseTeamFileConfig,
  } = useMutation({
    mutationFn: async () => {
      await updateAvatarConfigUseTeamFile(candidateId);
    },
    onSettled: async () => {
      queryClient.invalidateQueries([candidateId]);
    },
    onMutate: async () => {
      await queryClient.cancelQueries([
        AVATAR_CONFIG_DETAILS,
        candidateId,
        'team file',
      ]);

      const useTeamFiles = queryClient.getQueryData([
        AVATAR_CONFIG_DETAILS,
        candidateId,
        'team file',
      ]);

      queryClient.setQueryData(
        [AVATAR_CONFIG_DETAILS, candidateId, 'team file'],
        !useTeamFiles,
      );
      return { useTeamFiles };
    },
    onError: (_error, _, context) => {
      queryClient.setQueryData(
        [AVATAR_CONFIG_DETAILS, candidateId, 'team file'],
        context.useTeamFiles,
      );
    },
  });

  const columnsData = [
    {
      field: 'usable',
      headerName: '사용 여부',
      renderCell: ({ row }) => (
        <FormControlLabelStyled
          control={
            <Checkbox
              checked={row.usable}
              disabled={
                row.fileType === 'Team' ||
                usableMutation.isPending ||
                row.fileStatus !== 'Embedded'
              }
              onClick={() => usableMutation.mutate(row.fileObjectId)}
            />
          }
        />
      ),
    },
    {
      field: 'name',
      headerName: '파일명',
      width: 800,
      flex: 1,
      renderCell: (data) =>
        `(${data.row.fileType === 'Team' ? '팀' : '개인'}) - ${data.row.name}`,
    },
    {
      field: 'createdDatetime',
      headerName: '등록 시간',
      type: 'datetime',
      headerAlign: 'center',
      align: 'center',
      width: 160,
      flex: 1,
      renderCell: (data) => (
        <>
          {DATE_UTIL.converterUTCToTimeZone(
            data.row.createdDatetime,
            DATE_UTIL.FORMAT.DEFAULT_FORMAT,
          )}
        </>
      ),
    },
    {
      field: 'dataCheck',
      headerName: '데이터 확인',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 160,
      renderCell: ({ row }) => <DataCheck data={row} />,
    },
    {
      field: 'management',
      headerName: '관리',
      // width: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.row.fileType === 'Avatar' ? (
            <FileManagementCell data={params.row} />
          ) : (
            '공용 파일입니다.'
          )}
        </>
      ),
    },
  ];

  if (files.isLoading)
    return (
      <Box mb={10}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CommonIconButton disabled>
            <PostAddRoundedIcon /> My데이터 추가{' '}
          </CommonIconButton>
        </Box>
        <DataGridBox>
          <LoadingSpinner isLoading={files.isLoading} />
        </DataGridBox>
      </Box>
    );

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          <Tooltip
            placement="right"
            disableInteractive={usableMutation.isPending}
            title={
              <ListStyled>
                <li>
                  등록 가능한 파일 종류 : PDF{' '}
                  {/*, TXT, HWP, DOCX, PPTX, XLSX, MP3, MP4*/}
                </li>
                <li>파일 크기에 따라 업로드와 처리시간이 소요됩니다.</li>
              </ListStyled>
            }
          >
            <CommonIconButton onClick={openDirectory}>
              {mutation.isPending ? (
                <>
                  <Loader sx={{ marginRight: '6px' }} />
                  My데이터 추가
                </>
              ) : (
                <>
                  <PostAddRoundedIcon />
                  My데이터 추가{FileInput()}
                </>
              )}
            </CommonIconButton>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">
            공통 데이터 사용{' '}
            <strong>(답변에 팀이 업로드한 문서를 사용합니다.)</strong>
          </Typography>
          <CommonSwitch
            disabled={
              files.isLoading || isLoadingConfig || usableMutation.isPending
            }
            checked={useTeamFiles}
            onClick={changeUseTeamFileMutation}
          />
        </Box>
      </Box>

      {files.isSuccess && (
        <DataBox
          getRowId={(row) => row.fileObjectId}
          columns={columnsData}
          data={files.data.items}
          total={files.data.totalItems}
          pagination={pagination}
        />
      )}
    </Box>
  );
};
