import { memo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useCacheHitCountMutation } from 'react-query/mutations/questions/useCacheHitCountMutation';
import { ChatContext } from '../context/ChatProvider';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useQuery } from '@tanstack/react-query';
import { categoryQueries } from '../../../../../../react-query/factory/categoryQueries';
import { ReactComponent as PlantIcon1 } from '../../../../../../assets/images/PlantIcon1.svg';
import { ReactComponent as PlantIcon2 } from '../../../../../../assets/images/PlantIcon2.svg';
import LoadingSpinner from '../../../../../../ui/LoadingSpinner';
// import { ReactComponent as PlantIcon3 } from '../../../../../../assets/images/PlantIcon3.svg';
// import { ReactComponent as PlantIcon4 } from '../../../../../../assets/images/PlantIcon4.svg';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& *': {
    fontFamily: 'Pretendard!important',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  padding: '12px 10px',
  border: '1px solid #E1E1E1',
  borderRadius: 4,
  '&:hover, &:focus': {
    border: '1px solid #8DAC31',
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: '#FCFCFC',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'none',
  },
  '&.Mui-expanded': {
    borderColor: '#8DAC31',
    backgroundColor: '#F4FED3',
    '& .MuiTypography-h6': {
      fontWeight: 800,
      overflow: 'visible',
    },
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    margin: '5px 0px',
    flexDirection: 'column',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.75rem',
      marginRight: 6,
      marginBottom: 0,
      padding: '0 4px',
      fontWeight: 600,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '& .MuiTypography-h6': {
      width: '100%',
      paddingRight: 0,
      fontSize: '1rem',
      fontWeight: 500,
      color: '#1F2F20',
      whiteSpace: 'normal',
      display: '-webkit-box',
      webkitLineClamp: 1,
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    '&.Mui-expanded': {
      '& .MuiTypography-h6': {
        '-webkit-box-orient': 'unset',
        overflow: 'visible',
      },
    },
    '& .MuiTypography-caption': {
      color: '#9b9b9b',
      fontSize: '0.875rem',
      fontWeight: 500,
      padding: 0,
      marginRight: 0,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 16,
  '&.MuiAccordionDetails-root': {
    border: '0 solid #e4f0c0',
    borderWidth: '0 1px 1px 1px',
    borderRadius: '0 4px 4px 0',
  },
  '& .MuiTypography-root': {
    fontSize: '1rem',
  },
}));

const AccordionSubSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  marginTop: 16,
  backgroundColor: '#fff',
  border: '1px solid #E1E1E1',
  borderRadius: 4,
  flexDirection: 'row',
  padding: '6px 10px',
  '&:first-of-type': {
    marginTop: 0,
  },
  '&:hover, &:focus': {
    border: '1px solid #8DAC31',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content': {
    margin: '5px 0px',
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      marginRight: 6,
      marginBottom: 0,
      fontWeight: 500,
    },
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 600,
    },
    '& .MuiTypography-h6': {
      width: '100%',
      paddingRight: 0,
      fontSize: '1rem',
      fontWeight: 500,
      color: '#1F2F20',
      whiteSpace: 'normal',
      display: '-webkit-box',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    '&.Mui-expanded': {
      '& .MuiTypography-h6': {
        '-webkit-box-orient': 'unset',
        overflow: 'visible',
      },
    },
    '& .MuiTypography-caption': {
      color: '#9b9b9b',
      fontSize: '0.875rem',
      fontWeight: 500,
      padding: 0,
      marginRight: 0,
    },
  },
}));

const Category = ({
  category,
  selectedCategory,
  toggleQuestion,
  setBottomMenuOpen,
}) => {
  const { avatarId } = useParams();
  const mutation = useCacheHitCountMutation();
  const { handleRequestQuery } = useContext(ChatContext);

  const handleSubmit = async (cache) => {
    setBottomMenuOpen(false);
    await handleRequestQuery({
      content: cache.question,
      timestamp: Date.now(),
    });
    mutation.mutate({ avatarId, cacheId: cache.cacheId });
  };

  const { isLoading, data: avatarCacheSubCategories } = useQuery(
    categoryQueries.subcategory(
      avatarId,
      selectedCategory?.categoryId,
      'allow empty string',
    ),
  );

  return (
    <Accordion
      key={category.categoryId}
      expanded={selectedCategory?.categoryId === category.categoryId}
    >
      <AccordionSummary onClick={(e) => toggleQuestion(e, category)}>
        <Box display="flex" alignItems="center">
          {category.iconObject?.downloadUrl ? (
            <img
              src={category.iconObject?.downloadUrl}
              alt={'icon'}
              style={{ width: 24, height: 24 }}
            />
          ) : (
            <PlantIcon1 />
          )}
          <Typography variant="h6" ml={1}>
            {category.question}
          </Typography>
        </Box>
        {/*<CaptionText variant='caption'>*/}
        {/*  <span>하위 카테고리: {category.categoryId}</span>&nbsp;*/}
        {/*</CaptionText>*/}
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6">{category.answer}</Typography>
        {isLoading && <LoadingSpinner isLoading={isLoading} />}
        {avatarCacheSubCategories?.items
          ?.filter((subCategory) => subCategory.cacheId !== null)
          .map((subCategory) => {
            return (
              <AccordionSubSummary
                key={subCategory?.categoryId}
                onClick={(_e) => handleSubmit(subCategory.cache)}
              >
                <Box display="flex" alignItems="center">
                  {subCategory.iconObject?.downloadUrl ? (
                    <img
                      src={subCategory.iconObject?.downloadUrl}
                      alt={'icon'}
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <PlantIcon2 />
                  )}
                  <Typography variant="h6" ml={1}>
                    {subCategory.question}
                  </Typography>
                </Box>
              </AccordionSubSummary>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};
export default memo(Category);
