import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchManagers = async (params) => {
  const { data } = await tokenInstance.get(`${API.MANAGERS}`, {
    params,
  });
  return data;
};
