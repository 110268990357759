export const regExType = {
  PasswordRegEx: 'PasswordRegEx',
  SubPasswordRegEx: 'SubPasswordRegEx',
  IdRegEx: 'IdRegEx',
  NameRegEx: 'NameRegEx',
  EmailRegEx: 'EmailRegEx',
  AgeRegEx: 'AgeRegEx',
};

export function validation(regExCase, text) {
  let regExp;

  switch (regExCase) {
    case regExType.PasswordRegEx:
      regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@!%*#?&])[A-Za-z\d@!%*#?&.]{8,}$/; // 최소 8 자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자
      break;
    case regExType.SubPasswordRegEx:
      regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,15}/; // 적어도 소문자 하나, 대문자 하나, 숫자 하나
      break;
    case regExType.IdRegEx:
      regExp = /^[가-힣a-zA-Z0-9]+$/;
      break;
    case regExType.NameRegEx:
      regExp = /^[가-힣a-zA-Z0-9-~/<>_()=+!@#$%^&*.,;:'"?| ]+$/; // 공백 허용
      break;
    case regExType.EmailRegEx:
      regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      break;
    case regExType.AgeRegEx:
      regExp = /^(100|[1-9][0-9]?)$/;
      break;
    default:
      regExp = /^[가-힣a-zA-Z0-9]+$/;
      break;
  }

  return regExp.test(String(text));
}
