import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const uploadTeamFile = async (formData, teamId) => {
  await tokenInstance.put(
    `${API.TEAM_FILES}/${teamId}`,
    formData,
    MULTIPART_HEADER,
  );
};
