import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { Box, Container, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { doLogin } from 'api/auth/do-login';

import { TOAST_MESSAGE } from 'constants/toast-message';

import BgMaxRag from 'assets/images/BgMaxRag.jpg';
import { Logo } from 'components/Logo';
import {
  setLocalStorage,
  setManagerDataLocalStorage,
} from 'lib/utils/localStorage.util';
import { notifyRouteByType } from 'lib/utils/notifyRouteByType';

const BoxStyled = styled(Box)(() => ({
  // background:'linear-gradient(337deg, #27C3E2 0%, white 50%, rgba(39, 106, 226, 0.42) 100%)',
  background: '#F4F7FE',
}));

const ContainerStyled = styled(Container)(() => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& > svg': {
    width: 230,
    marginBottom: 40,
  },
  '& .MuiInputBase-root': {
    margin: '8px 0',
  },
}));

const CommonInput = styled(TextField)(() => ({
  minWidth: 320,
  border: '0 none',
  '& input': {
    backgroundColor: '#fff',
    fontSize: '1.125rem',
    borderRadius: 8,
    '&:before, &:after': {
      borderBottom: '1px solid #27c3e2',
    },
  },
}));
const CommonButton = styled('button')(() => ({
  display: 'block',
  cursor: 'pointer',
  width: 320,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#27c3e2',
  padding: '18px 0',
  fontSize: '1.125rem',
  fontWeight: 700,
  textDecoration: 'none',
  borderRadius: 6,
  border: 'none',
  marginTop: 20,
  '&:hover': {
    backgroundColor: '#27c3e2',
  },
  '&:disabled': {
    cursor: 'not-allowed',
  },
}));
const CaptionTxt = styled('div')(() => ({
  fontSize: '0.813rem',
  color: '#555',
  marginTop: 30,
  '& a': {
    color: '#555',
    fontWeight: 700,
  },
}));

export default function Login() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    id: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.id) {
      toast.error('아이디를 입력해주세요.');
      return;
    }
    if (!loginData.password) {
      toast.error('비밀번호를 입력해주세요.');
      return;
    }
    loginMutation(loginData);
  };

  const { mutate: loginMutation } = useMutation({
    mutationFn: async (loginData) => {
      return await doLogin(loginData);
    },
    onSuccess: (response) => {
      const token = response.data.token;
      const managerData = response.data.manager;
      setLocalStorage('token', token);
      setManagerDataLocalStorage(managerData);
      navigate(notifyRouteByType(managerData.type), { replace: true });
      toast.success(TOAST_MESSAGE.SUCCESS.LOGIN_SUCCESS(managerData.name));
    },
    onError: (error) => {
      const errorCode = error.response.status;

      if (errorCode === 401) {
        toast.error(TOAST_MESSAGE.ERROR.LOGIN_ERROR);
      } else {
        toast.error(TOAST_MESSAGE.ERROR.SERVER_ERROR);
      }
    },
  });

  const moveToHome = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        margin: '0 auto',
        minHeight: '100dvh',
        // background:
        // 	'linear-gradient(337deg, #27C3E2 0%, white 50%, rgba(39, 106, 226, 0.42) 100%)',
        backgroundImage: `url(${BgMaxRag})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: 'cover',
        '& *': {
          fontFamily: 'Pretendard!important',
        },
      }}
    >
      <BoxStyled>
        {/*<TeamLayout/>*/}
        <form onSubmit={handleLogin}>
          <ContainerStyled>
            <Box mb={4} onClick={moveToHome} style={{ cursor: 'pointer' }}>
              <Logo width={'204px'} height={'84px'} />
            </Box>
            <CommonInput
              name="id"
              value={loginData.id}
              onChange={handleChange}
              placeholder="아이디를 입력해주세요."
              variant="outlined"
              type="text"
            />
            <CommonInput
              name="password"
              value={loginData.password}
              onChange={handleChange}
              placeholder="비밀번호를 입력해주세요."
              variant="outlined"
              type="password"
              autoComplete="on"
            />
            <CommonButton
              disabled={!(loginData.id || loginData.password)}
              type="submit"
            >
              로그인
            </CommonButton>
            <CaptionTxt>
              * 응답이 없으면 <Link>시스템 관리자에게 문의</Link>하여 주세요.
            </CaptionTxt>
          </ContainerStyled>
        </form>
      </BoxStyled>
    </Box>
  );
}
