import { useContext } from 'react';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Box, Divider, Typography } from '@mui/material';

import { MissingQuestionContext } from './MissingQuestionContext';
import { MissingQuestionItem } from './MissingQuestionItem';
import { MissingQuestionsGrid } from './MissingQuestionsGrid';

import { getMissingQuestions } from 'api-legacy/avatars/questions';

export const MissingQuestionController = ({ candidateId }) => {
  const { page, handleChangePage, showAll } = useContext(
    MissingQuestionContext,
  );

  const {
    isLoading,
    isError,
    data: missingQuestions,
    isSuccess,
  } = useQuery({
    queryKey: ['Missing Questions', candidateId, showAll, page],
    queryFn: () => getMissingQuestions(page, candidateId, showAll),
    placeholderData: keepPreviousData,
  });
  if (isLoading || isError) return <MissingQuestionsGrid />;

  return (
    <>
      {isSuccess && (
        <MissingQuestionsGrid
          maxPage={missingQuestions.maxPage}
          page={page}
          handleChangePage={handleChangePage}
          isSuccess={isSuccess}
        >
          {isSuccess && !!missingQuestions.totalItems ? (
            missingQuestions.items.map((question) => (
              <MissingQuestionItem
                key={question.missingQuestionNum}
                question={question}
              />
            ))
          ) : (
            <>
              <Box sx={{ m: 6, display: 'flex', justifyContent: 'center' }}>
                <Typography>누락된 질문이 없습니다.</Typography>
              </Box>
              <Divider />
            </>
          )}
        </MissingQuestionsGrid>
      )}
    </>
  );
};
