import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { charactersQueries } from 'react-query/factory/charactersQueries';

import { deleteCharacter } from 'api/characters/deleteCharacter';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useDeleteCharacterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => deleteCharacter(id),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.DELETE_SUCCESS);
    },
    onError: (error) => {
      if (error.response.data.errorCode === 'CanNotDeleteCharacter') {
        toast.error('사용되고 있는 캐릭터입니다.');
      } else {
        toast.error(TOAST_MESSAGE.ERROR.DELETE_ERROR);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: charactersQueries.all(),
      });
    },
  });
};
