import { useState } from 'react';
import { PinnedQuestionContext } from './PinnedQuestionContext';
import { useDebouncedValue } from 'rooks';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AVATAR_CONFIG_DETAILS } from '../../../../constants/queryKeys';
import { updateAvatarConfigUseAutoPinnedAnswer } from '../../../../api-legacy/avatars/getAvatarDescription';
import { queryClient } from '../../../../react-query/query-client';
import { categoryQueries } from '../../../../react-query/factory/categoryQueries';
import { useDeleteAvatarCategoryMutation } from '../../../../react-query/mutations/questions/useDeleteAvatarCategoryMutation';

export const PinnedQuestionHandler = ({
  page,
  subPage,
  setPage,
  setSubPage,
  avatarConfigDetails,
  candidateId,
  children,
}) => {
  const [mainCategory, setMainCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCache, setSelectedCache] = useState(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const [openCacheCategoryDialog, setOpenCacheCategoryDialog] = useState(false);
  const [openCacheDialog, setOpenCacheDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryDeleteConfirmDialog, setCategoryDeleteConfirmDialog] =
    useState(false);
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const isCheckedQuestion = (category) =>
    selectedCategory?.categoryId === category.categoryId;

  const toggleQuestion = (e, category) => {
    e.stopPropagation();
    if (!category.parentId) {
      if (mainCategory?.categoryId === category.categoryId) {
        setMainCategory(null);
        setSelectedCategory(null);
      } else {
        setMainCategory(category);
        setSelectedCategory(category);
      }
    } else {
      if (selectedCategory?.categoryId === category.categoryId) {
        setSelectedCategory(null);
        setSelectedCategory(mainCategory);
      } else {
        setSelectedCategory(category);
      }
    }
  };

  const {
    isSubCategoryLoading,
    isSubCategoryError,
    data: avatarCacheSubCategories,
    isSubCategorySuccess,
  } = useQuery(
    categoryQueries.subcategory(
      candidateId,
      mainCategory?.categoryId,
      'allow empty string',
      subPage,
    ),
  );

  const handleChangePage = (_, page) => {
    setMainCategory(null);
    setSelectedCategory(null);
    setPage(page);
  };

  const handleChangeSubPage = (_, page) => {
    setSubPage(page);
  };

  const openAddCacheCategoryDialog = (isEdit) => {
    setOpenCacheCategoryDialog(true);
    if (isEdit === true) setIsEdit(isEdit);
  };

  const closeAddCacheCategoryDialog = () => {
    setOpenCacheCategoryDialog(false);
    setIsEdit(false);
  };

  const openAddCacheDialog = () => {
    setOpenCacheDialog(true);
  };

  const closeAddCacheDialog = () => {
    setOpenCacheDialog(false);
  };

  const openCategoryDeleteConfirmDialog = () => {
    setCategoryDeleteConfirmDialog(true);
  };

  const closeCategoryDeleteConfirmDialog = () => {
    setCategoryDeleteConfirmDialog(false);
  };

  const deleteCategory = useDeleteAvatarCategoryMutation();
  const handleDeleteCategory = () => {
    deleteCategory.mutate(selectedCategory, {
      onSuccess: () => {
        closeCategoryDeleteConfirmDialog();
      },
    });
  };

  const {
    mutate: changeUseAutoPinnedAnswerMutation,
    isPending: isChangingUseTeamFileConfig,
  } = useMutation({
    mutationFn: async () => {
      await updateAvatarConfigUseAutoPinnedAnswer(candidateId);
    },
    onSettled: async () => {
      queryClient.invalidateQueries([candidateId]);
    },
    onMutate: async () => {
      await queryClient.cancelQueries([
        AVATAR_CONFIG_DETAILS,
        candidateId,
        'auto pinned answer',
      ]);

      const useAutoPinnedAnswer = queryClient.getQueryData([
        AVATAR_CONFIG_DETAILS,
        candidateId,
        'auto pinned answer',
      ]);

      queryClient.setQueryData(
        [AVATAR_CONFIG_DETAILS, candidateId, 'auto pinned answer'],
        !useAutoPinnedAnswer,
      );
      return { useAutoPinnedAnswer };
    },
    onError: (_error, _, context) => {
      queryClient.setQueryData(
        [AVATAR_CONFIG_DETAILS, candidateId, 'auto pinned answer'],
        context.useAutoPinnedAnswer,
      );
    },
  });

  const checkedCache = (cache) => {
    if (cache.cacheId === selectedCache?.cacheId) {
      setSelectedCache(null);
    } else {
      setSelectedCache(cache);
    }
  };

  return (
    <PinnedQuestionContext.Provider
      value={{
        page,
        subPage,
        isCheckedQuestion,
        mainCategory,
        selectedCategory,
        toggleQuestion,
        debouncedSearch,
        handleChangeSearch,
        openCacheCategoryDialog,
        openCacheDialog,
        setOpenCacheCategoryDialog,
        setOpenCacheDialog,
        openAddCacheCategoryDialog,
        openAddCacheDialog,
        closeAddCacheCategoryDialog,
        closeAddCacheDialog,
        handleChangePage,
        handleChangeSubPage,
        avatarCacheSubCategories,
        categoryDeleteConfirmDialog,
        openCategoryDeleteConfirmDialog,
        closeCategoryDeleteConfirmDialog,
        handleDeleteCategory,
        selectedCache,
        checkedCache,
        isEdit,
        setSelectedCategory,
      }}
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={avatarConfigDetails?.useAutoPinnedAnswer}
                onClick={changeUseAutoPinnedAnswerMutation}
              />
            }
            label="자동"
          />
          <Typography variant="body2">
            유사 질문 자동 추천 (유사도가 높은 질문을 자동으로 추천합니다)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!avatarConfigDetails?.useAutoPinnedAnswer}
                onClick={changeUseAutoPinnedAnswerMutation}
              />
            }
            label="수동"
          />
          <Typography variant="body2">
            고정 질문 직접 등록 (고정질문을 카테고리별로 직접 편집하고
            추천합니다)
          </Typography>
        </Grid>
      </Grid>
      {children}
    </PinnedQuestionContext.Provider>
  );
};
