import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  iconButtonStyle: {
    width: 72,
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 84,
    // border:'1px solid #D3D4D4!important',
    // boxShadow:'0 2px 10px #C6CACF',
    // backgroundColor: '#fff !important',
    '@media all and (max-width: 640px)': {
      width: 60,
      height: 60,
    },
    '@media all and (max-width: 480px)': {
      width: 52,
      height: 52,
    },
    '&:hover': {
      backgroundColor: '#fff!important',
    },
  },
  recordingBtnStyle: {
    border: '0 none',
    boxShadow: '1px 1px 15px #93B5F18a',
    backgroundColor: '#fff!important',
  },
  recording: {
    width: 6,
    height: 40,
    borderRadius: 4,
    display: 'inline-block',
    position: 'relative',
    color: '#93B5F1',
    background: '#93B5F1',
    animation: `$animloader 0.3s 0.3s linear infinite alternate`,
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      width: 6,
      height: 38,
      borderRadius: 4,
      background: '#93B5F1',
      top: '50%',
      left: 16,
      transform: 'translateY(-50%)',
      animation: `$animloader 0.3s  0.45s  linear infinite alternate`,
    },
    '&:before': {
      left: -16,
      animationDelay: '0s',
    },
    '@media all and (max-width: 640px)': {
      width: 6,
      height: 36,
      '&::after, &::before': {
        width: 6,
        left: 12,
        height: 36,
      },
      '&:before': {
        left: -12,
      },
    },
    '@media all and (max-width: 480px)': {
      width: 5,
      height: 26,
      '&::after, &::before': {
        width: 5,
        left: 10,
        height: 26,
      },
      '&:before': {
        left: -10,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  recordingChat: {
    width: 4,
    height: 20,
    animation: `$animloaderChat 0.3s 0.3s linear infinite alternate`,
    '&::after, &::before': {
      width: 4,
      left: 8,
      height: 20,
      animation: `$animloaderChat 0.3s  0.45s  linear infinite alternate`,
    },
    '&:before': {
      left: -8,
    },
  },
  '@keyframes animloader': {
    '0%': { height: 38 },
    '100%': { height: 5 },
  },
  '@keyframes animloaderChat': {
    '0%': { height: 20 },
    '100%': { height: 5 },
  },
});
