import toast from 'react-hot-toast';
import { useOutletContext } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';

import { createNewTeamAvatar } from 'api/avatars/createNewTeamAvatar';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useCreateAvatarMutation = () => {
  const queryClient = useQueryClient();
  const { teamId } = useOutletContext();

  return useMutation({
    mutationFn: async (data) => await createNewTeamAvatar(data),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: teamAvatarsQueries.lists(teamId),
      });
    },
  });
};
