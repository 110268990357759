import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFileUsableMutation } from 'react-query/mutations/data-files/useFileUsableMutation';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  width: '100%',
  margin: 0,
  paddingLeft: 10,
  boxSizing: 'border-box',
  '& .MuiCheckbox-root': {
    margin: '0 12px',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
export const CheckBox = ({ checked, disabled, fileId }) => {
  const mutation = useFileUsableMutation();
  return (
    <FormControlLabelStyled
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onClick={() => mutation.mutate(fileId)}
        />
      }
    />
  );
};
