import { EmptyBox } from 'pages/user/features/question/components/styled';
import { profileType } from 'constants/profileType';
import { ReactComponent as IconSmileyWink } from 'assets/images/IconSmileyWink.svg';
import { useContext } from 'react';
import { ChatContext } from '../context/ChatProvider';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import LogoNieVerti from 'assets/images/LogoNieVerti.png';
export const LogoBox = styled(Box)(() => ({
  padding: '24px 0 16px',
  '& > svg': {
    width: 200,
    height: 200,
  },
  '@media all and (max-width: 480px)': {
    padding: '16px 0 10px',
    '& > svg': {
      width: 120,
      height: 120,
    },
  },
}));

export const GreetingBlock = () => {
  const { simpleProfile } = useContext(ChatContext);
  if (simpleProfile.isLoading || simpleProfile.isError)
    return (
      <EmptyBox>
        <IconSmileyWink />
      </EmptyBox>
    );

  const greeting =
    simpleProfile.data.profileList.find(
      (item) => item.type === profileType.SelfIntroduction,
    )?.value ?? '무엇을 도와드릴까요?';
  return (
    <EmptyBox>
      {simpleProfile.data.avatarId ===
      '0ee9ea3b-74b1-4595-93fe-0db6f52ad229' ? (
        <LogoBox>
          {/*<LogoPlant />*/}
          <img src={LogoNieVerti} alt={'기후생태 리스크 방안'} />
        </LogoBox>
      ) : (
        <IconSmileyWink />
      )}
      {greeting}
    </EmptyBox>
  );
};
