import { defaultInstance } from 'lib/axios/instance';

const avatarEndpointPrefix = '/api/v1/avatars';

export const getPublicAvatars = async (searchText) => {
  return await defaultInstance.get(`${avatarEndpointPrefix}/public`, {
    params: {
      search: searchText,
    },
  });
};
