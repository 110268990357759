import { useState } from 'react';
import toast from 'react-hot-toast';

import { useQueryClient } from '@tanstack/react-query';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { cacheQueries } from 'react-query/factory/cacheQueries';
import { useAvatarFileDeleteMutation } from 'react-query/mutations/data-files/avatar/useAvatarFileDeleteMutation';

import { deleteFileCache } from 'api/data-files/avatars/deleteFileCache';
import { fetchFileEditUrl } from 'api/data-files/fetchFileEditUrl';

import { CacheClearConfirmDialog } from 'components/Dialog/CacheClearConfirmDialog';
import { DeleteConfirmDialog } from 'components/Dialog/DeleteConfirmDialog';

const PrimaryIconButton = styled(IconButton)(() => ({
  position: 'relative',
  // '& svg': {
  // 	color: '#33cf13',
  // },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 10,
    right: 5,
    width: 6,
    height: 6,
    backgroundColor: '#33cf13',
    borderRadius: 16,
  },
}));
export const FileManagementCell = ({ data }) => {
  const { fileStatus } = data;
  const queryClient = useQueryClient();

  const mutation = useAvatarFileDeleteMutation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    mutation.mutate(data.fileObjectId, { onSuccess: handleClose });
  };
  const promiseEditUrl = async () => {
    const promise = fetchFileEditUrl(data.fileObjectId);
    toast.promise(promise, {
      loading: '파일 정보를 불러오는 중입니다.',
      success: '파일을 편집할 수 있습니다.',
      error: '오류가 발생했습니다.',
    });
    return promise;
  };

  const handleRequestEditUrl = async () => {
    try {
      const url = await promiseEditUrl();
      window.open(url);
    } catch {
      toast.error('서버와의 접속이 원활하지 않습니다.');
    }
  };

  const [openCacheClear, setOpenCacheClear] = useState(false);
  const [isClearingCache, setIsClearingCache] = useState(false);
  const handleOpenCacheClear = () => setOpenCacheClear(true);
  const handleCloseCacheClear = () => setOpenCacheClear(false);
  const handleCacheClear = async () => {
    setIsClearingCache(true);
    try {
      await deleteFileCache(data);
      toast.success('수정사항을 갱신했습니다.');
      handleCloseCacheClear();
    } catch {
      toast.error('서버와의 접속이 원활하지 않습니다.');
    }
    setIsClearingCache(false);
    queryClient.invalidateQueries({ queryKey: cacheQueries.all() });
  };

  return (
    <>
      <Box>
        {fileStatus === 'Embedded' && (
          <Tooltip title="업데이트" onClick={handleOpenCacheClear}>
            <IconButton>
              <ResetTvIcon />
            </IconButton>
          </Tooltip>
        )}
        {(fileStatus === 'Labeling' || fileStatus === 'Embedded') && (
          <Tooltip title="편집 가능 &#128279;" onClick={handleRequestEditUrl}>
            <PrimaryIconButton>
              <EditNoteRoundedIcon />
            </PrimaryIconButton>
          </Tooltip>
        )}
        <Tooltip title="삭제" onClick={handleOpen}>
          <IconButton>
            <DeleteOutlineRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {openCacheClear && (
        <CacheClearConfirmDialog
          open={openCacheClear}
          pending={isClearingCache}
          maxWidth="lg"
          handleClose={handleCloseCacheClear}
          handleDelete={handleCacheClear}
        />
      )}
      {open && (
        <DeleteConfirmDialog
          open={open}
          pending={mutation.isPending}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
