import max from 'assets/images/MAX_temporary-txt.png';

export const MaxLogo = ({ width, height }) => (
  <img
    src={max}
    alt={'logo'}
    width={width || '102px'}
    height={height || '42px'}
  />
);
