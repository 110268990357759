import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { updateUserAnswerFeedback } from 'api/users/updateUserAnswerFeedback';

import { ReactComponent as ThumbDownActiveIcon } from 'assets/images/ThumbDownActiveIcon.svg';
import { ReactComponent as ThumbDownIcon } from 'assets/images/ThumbDownIcon.svg';
import { ReactComponent as ThumbUpActiveIcon } from 'assets/images/ThumbUpActiveIcon.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/images/ThumbUpIcon.svg';

const ACTION = [
  {
    value: 'Like',
    icon: <ThumbUpIcon />,
    activeIcon: <ThumbUpActiveIcon />,
  },
  {
    value: 'DisLike',
    icon: <ThumbDownIcon />,
    activeIcon: <ThumbDownActiveIcon />,
  },
];
export const LikeHateGroup = ({ userQuestionHistoryId }) => {
  const { avatarId } = useParams();
  const [action, setAction] = useState('');
  const handleActionToggle = async (clickAction) => {
    let likeValue = 'None';
    if (action === clickAction) {
      setAction('');
    } else {
      likeValue = clickAction;
      setAction(clickAction);
    }
    try {
      await updateUserAnswerFeedback({
        avatarId,
        id: userQuestionHistoryId,
        likeValue,
      });
    } catch {
      setAction(action);
      toast.error('서버와의 통신이 원활하지 않습니다.');
    }
  };
  return (
    <>
      {ACTION.map((item) => (
        <IconButton
          key={item.value}
          onClick={() => {
            handleActionToggle(item.value);
          }}
        >
          {action === item.value ? item.activeIcon : item.icon}
        </IconButton>
      ))}
    </>
  );
};
