import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0',
  },
  spinner: {
    width: 48,
    height: 48,
    display: 'inline-block',
    position: 'relative',
    color: '#7691C1', //#fbc809
    // border:'1px solid #d2d2d2',
    // boxShadow:'0 2px 6px #fbc80970',
    animation: `$rotation 1s linear infinite`,
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      width: 24,
      height: 24,
      top: '50%',
      left: '50%',
      transform: 'scale(0.5) translate(0, 0)',
      backgroundColor: '#7691C1', //#fbc809
      borderRadius: '50%',
      animation: `$animloader 1s infinite ease-in-out`,
    },
    '&:before': {
      backgroundColor: '#93B5F1', //#fbc809
      transform: 'scale(0.5) translate(-48px, -48px)',
    },
  },
  '@keyframes rotation': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  '@keyframes animloader': {
    '50%': { transform: 'scale(1) translate(-50%, -50%)' },
  },
});
