import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import NoRowsComponent from 'ui/datagrid/NoRowsComponent';
import LoadingSpinner from 'ui/LoadingSpinner';

import { CommonStack } from 'components/styledMUI/CommonStack';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { PageTitle } from 'components/styledMUI/PageTitle';
import { RootBox } from 'components/styledMUI/RootBox';

const ListBox = styled('div')(({ theme }) => ({
  padding: '12px 0',
  border: '1px solid #e8edf7',
  borderRadius: 10,
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderBottom: '1px dashed #ddd',
  '& > span': {
    marginRight: 8,
  },
  '& .MuiListItemText-root span': {
    fontWeight: 700,
  },
  // whiteSpace: 'pre-line',
  // wordBreak: 'break-all',
}));

export default function ViewAllList({
  title,
  isLoading,
  page,
  setPage,
  data,
  keyField,
  valueField,
}) {
  const { candidateId } = useParams();
  const navigate = useNavigate();
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <RootBox>
      {/*<CandidateLayout />*/}
      <ContentBox component="main" maxWidth="xl">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={4}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => navigate(`/candidate/monitoring/${candidateId}`)}
            >
              <ArrowBackIosNewRoundedIcon />
            </IconButton>
            <PageTitle>{title} 전체보기</PageTitle>
          </Box>
        </Box>
        {isLoading ? (
          <LoadingSpinner isLoading={isLoading} />
        ) : (
          <ListBox>
            <List>
              {data.items.length > 0 ? (
                data.items.map((item) => {
                  return (
                    <ListItemStyled key={item[keyField]}>
                      <ListItemText primary={item[valueField]} />
                    </ListItemStyled>
                  );
                })
              ) : (
                <NoRowsComponent />
              )}
            </List>
          </ListBox>
        )}

        <CommonStack spacing={2} mt={3}>
          <Pagination
            page={page}
            count={!isLoading ? data.maxPage : 1}
            onChange={handleChange}
          />
        </CommonStack>
      </ContentBox>
    </RootBox>
  );
}
