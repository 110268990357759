import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';
import { bodyToFormData } from 'lib/utils/bodyToFormData';
import { convertObjectToBlob } from 'lib/utils/convertObjectToBlob';

export const updateAvatarProfile = async (
  avatarDetail,
  profileList,
  image,
  candidateId,
) => {
  const touchedDataConcatenated = {
    ...avatarDetail,
    profileList: profileList,
  };
  const avatarDetailBlob = convertObjectToBlob(touchedDataConcatenated);
  const body = {
    avatarDetail: avatarDetailBlob,
    image: image.file ? image.file : null,
  };
  const formData = bodyToFormData(body);
  await tokenInstance.post(
    `/api/v1/avatars/config-detail/update/${candidateId}`,
    formData,
    MULTIPART_HEADER,
  );
};
