import { queryOptions } from '@tanstack/react-query';

import { fetchAvatarDescriptions } from 'api/avatars/fetchAvatarDescriptions';
import { fetchSimpleAvatarProfile } from 'api/avatars/fetchSimpleAvatarProfile';

export const avatarsQueries = {
  all: () => ['avatar'],
  simpleProfiles: (id) => [...avatarsQueries.all(), 'simple-profile', id],
  simpleProfile: (id, isPreview = false) =>
    queryOptions({
      queryKey: avatarsQueries.simpleProfiles(id),
      queryFn: () => fetchSimpleAvatarProfile(id, isPreview),
      meta: {
        errorMessage: '아바타 데이터를 불러오지 못했습니다.',
      },
      enabled: !!id,
      staleTime: 1000 * 60 * 5,
    }),
  descriptions: (id, isPinned) => [
    ...avatarsQueries.all(),
    'description',
    id,
    isPinned,
  ],
  descriptionsList: (id, isPinned) =>
    queryOptions({
      queryKey: avatarsQueries.descriptions(id, isPinned),
      queryFn: () => fetchAvatarDescriptions(id, isPinned),
      staleTime: 1000 * 60 * 5,
    }),
};
