import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchAvatars = async (params) => {
  const { data } = await tokenInstance.get(`${API.AVATARS}`, {
    params,
  });
  return data;
};
