import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInput = styled(TextField)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  '& input': {
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-notchedOutline': {},
}));

const TitleStyled = styled(Typography)(() => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  margin: '0 0 16px',
}));

export const DirectAnswerInput = ({ answer, onChange, disabled }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#F4F7FE',
        p: 3,
        borderRadius: 4,
        mt: 3,
        mb: 2,
      }}
    >
      <TitleStyled>직접입력하기 (입력한 답변이 우선적용됩니다.)</TitleStyled>
      <StyledInput
        disabled={disabled}
        value={answer}
        onChange={onChange}
        placeholder="질문 답변 입력해주세요"
        multiline
        rows={4}
      ></StyledInput>
    </Box>
  );
};
