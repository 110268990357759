import { useState } from 'react';

import { Box } from '@mui/material';

import { SearchBar } from 'ui/inputs/SearchBar';
import { PageTitle } from 'ui/titles/PageTitle';

import { TeamFilesGrid } from 'pages/teams/features/team-data/components/blocks/TeamFilesGrid';
import { UploadBlock } from 'pages/teams/features/team-data/components/blocks/UploadBlock';

import { useDebouncedValue } from 'rooks';

export const TeamData = () => {
  const [searchValue, setSearchValue] = useState('');

  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const [filter, hotUpdate] = useDebouncedValue(searchValue, 500);

  return (
    <>
      <PageTitle>팀 정보</PageTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <UploadBlock />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            hotUpdate();
          }}
        >
          <SearchBar
            placeholder="파일 검색"
            value={searchValue}
            onChange={handleChangeSearchValue}
          />
        </form>
      </Box>
      <TeamFilesGrid filter={filter} />
    </>
  );
};
