import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Button } from '@mui/material';

import {
  FabStyle,
  FabTitle,
  FabWrap,
} from 'pages/user/features/question/components/styled';

import { avatarsQueries } from 'react-query/factory/avatarsQueries';

import { ChatContext } from '../context/ChatProvider';

import { ReactComponent as ArrowRightIcon } from 'assets/images/ArrowRightIcon.svg';
import { ReactComponent as IconOath } from 'assets/images/IconOath.svg';

export const FloatingButtonList = () => {
  const { avatarId } = useParams();
  const { handleAddQuestion } = useContext(ChatContext);

  const [oath, setOath] = useState(true);
  const handleClickOath = () => {
    setOath(!oath);
  };
  const descriptions = useQuery(
    avatarsQueries.descriptionsList(avatarId, true),
  );

  if (
    descriptions.isLoading ||
    descriptions.isError ||
    !descriptions.data.length
  )
    return <FabWrap sx={{ height: 20 }} />;
  return (
    <FabWrap>
      <FabTitle variant="extended" onClick={handleClickOath}>
        <IconOath /> 공약 <ArrowRightIcon style={{ stroke: '#7396d3' }} />
      </FabTitle>
      {oath && (
        <FabStyle>
          {descriptions.data.map((description) => (
            <Button
              key={description.descriptionNum}
              onClick={() =>
                handleAddQuestion({
                  content: description.title,
                  timestamp: Date.now(),
                  answer: description.value,
                })
              }
              disableRipple
            >
              {description.title}
            </Button>
          ))}
        </FabStyle>
      )}
    </FabWrap>
  );
};
