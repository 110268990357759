import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DATE_UTIL } from '../../lib/utils/date.util';
import AllVisitorChart from './components/monitoring/AllVisitorChart.js';
import { IdeaSection } from './components/monitoring/IdeaSection';
import { QuestionSection } from './components/monitoring/QuestionSection';

import { ContentBox } from 'components/styledMUI/ContentBox';

const PageTitle = styled('h3')(({ theme }) => ({
  // margin:'1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

const BoxStyled = styled('div')(({ theme }) => ({
  marginBottom: 20,
}));

const TitleBoxStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
}));

const SubTitle = styled('h4')(({ theme }) => ({
  width: '100%',
  margin: '3.5rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.125rem',
  borderBottom: '1px dashed #ddd',
  paddingBottom: 12,
}));

const UiStyled = styled('div')(({ theme }) => ({
  '& ol': {
    margin: 0,
    paddingLeft: 20,
  },
  '& ul': {
    paddingLeft: 20,
    listStyle: 'square',
  },
  '& li': {
    justifyContent: 'flex-start',
    textAlign: 'left',
    fontSize: '1rem',
    color: '#121212',
    margin: '16px 0',
    padding: '0 42px 16px 8px',
  },
}));

const CommonButton = styled(Link)(({ theme }) => ({
  position: 'absolute',
  top: '3rem',
  right: 0,
  color: '#276AE2',
  border: '1px solid #276AE2',
  backgroundColor: '#EEF3FD',
  padding: '8px 24px',
  marginLeft: 24,
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '#EEF3FD',
  },
}));
export default function Monitoring() {
  const [date, setDate] = useState(DATE_UTIL.getInputValueFormatToday());
  const [period, setPeriod] = useState('Month');

  const handleChange = (event, newValue) => {
    setPeriod(newValue);
  };
  return (
    <ContentBox component="main" maxWidth="xl">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle>모니터링</PageTitle>
      </Box>
      <Box>
        <BoxStyled>
          <SubTitle>질문 집계</SubTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            gap={4}
          >
            <Tabs value={period} onChange={handleChange}>
              <Tab label="월간" value={'Month'} />
              <Tab label="연간" value={'Year'} />
            </Tabs>
            <input
              value={date ?? DATE_UTIL.getInputValueFormatToday()}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              id="month"
              type="month"
              name="month"
              min="2023-12"
              max={DATE_UTIL.getInputValueFormatToday()}
            />
          </Box>

          <Box mb={8}>
            <AllVisitorChart period={period} date={date} />
          </Box>
        </BoxStyled>
        <QuestionSection />
        <IdeaSection />
      </Box>
      {/*  Dialog  */}
      {/*<CommonListDialog*/}
      {/*    open={openIdea}*/}
      {/*    handleClose={handleCloseIdea}*/}
      {/*    title={'정책 제안'}*/}
      {/*    ranking={idea}*/}
      {/*    fullScreen={true}*/}
      {/*/>*/}
      {/*  // Dialog  */}
    </ContentBox>
  );
}
