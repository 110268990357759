import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { usePaginationProps } from 'hooks/usePaginationProps';

import { DataBox } from 'ui/datagrid';
import LoadingSpinner from 'ui/LoadingSpinner';

import { CheckBox } from 'pages/teams/features/team-data/components/cells/CheckBox';
import { DataCheck } from 'pages/teams/features/team-data/components/cells/DataCheck';
import { FileManagementCell } from 'pages/teams/features/team-data/components/cells/FileManagementCell';

import { filesQueries } from 'react-query/factory/filesQueries';

import { DATE_UTIL } from 'lib/utils/date.util';

export const TeamFilesGrid = ({ filter }) => {
  const { teamId } = useOutletContext();
  const pagination = usePaginationProps();
  const teamFiles = useQuery(
    filesQueries.list(
      'Team',
      teamId,
      pagination.rowsPerPage,
      filter,
      pagination.page + 1,
    ),
  );

  const columns = [
    {
      field: 'usable',
      headerName: '사용 여부',
      renderCell: ({ row }) => (
        <CheckBox
          checked={row.usable}
          disabled={row.fileStatus !== 'Embedded'}
          fileId={row.fileObjectId}
        />
      ),
    },
    { field: 'name', headerName: '파일명', width: 600 },
    {
      field: 'createdDatetime',
      headerName: '등록 시간',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 160,
      renderCell: ({ row }) => (
        <>
          {DATE_UTIL.converterUTCToTimeZone(
            row.createdDatetime,
            DATE_UTIL.FORMAT.DEFAULT_FORMAT,
          )}
        </>
      ),
    },
    {
      field: 'dataCheck',
      headerName: '데이터 확인',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 160,
      renderCell: ({ row }) => <DataCheck data={row} />,
    },
    {
      field: 'management',
      headerName: '관리',
      // width: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => <FileManagementCell data={row} />,
    },
  ];

  if (teamFiles.isLoading)
    return <LoadingSpinner isLoading={teamFiles.isLoading} />;
  if (teamFiles.isError) return null;

  return (
    <DataBox
      getRowId={(row) => row.fileObjectId}
      columns={columns}
      data={teamFiles.data.items}
      total={teamFiles.data.totalItems}
      pagination={pagination}
    />
  );
};
