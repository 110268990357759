export const USER_TYPE = [
  { id: 1, gender: 'Male', age: 'RUnder20s' },
  { id: 2, gender: 'Male', age: 'R20s' },
  { id: 3, gender: 'Male', age: 'R30s' },
  { id: 4, gender: 'Male', age: 'R40s' },
  { id: 5, gender: 'Male', age: 'R50s' },
  { id: 6, gender: 'Male', age: 'ROver60s' },
  { id: 7, gender: 'Female', age: 'RUnder20s' },
  { id: 8, gender: 'Female', age: 'R20s' },
  { id: 9, gender: 'Female', age: 'R30s' },
  { id: 10, gender: 'Female', age: 'R40s' },
  { id: 11, gender: 'Female', age: 'R50s' },
  { id: 12, gender: 'Female', age: 'ROver60s' },
];

export const AGE = [
  { text: '20대 미만', value: 'RUnder20s' },
  { text: '20대', value: 'R20s' },
  { text: '30대', value: 'R30s' },
  { text: '40대', value: 'R40s' },
  { text: '50대', value: 'R50s' },
  { text: '60대 이상', value: 'ROver60s' },
];
