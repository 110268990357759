import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { filesQueries } from 'react-query/factory/filesQueries';

import { uploadTeamFile } from 'api/data-files/teams/uploadTeamFile';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useTeamFileUploadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ formData, teamId }) => uploadTeamFile(formData, teamId),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: filesQueries.all(),
      });
    },
  });
};
