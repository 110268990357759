import { useContext, useState } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CacheList } from './CacheList';
import { MissingQuestionContext } from './MissingQuestionContext';

import { CommonInputBase } from 'components/styledMUI/CommonInputBase';
import { SearchIconWrapper } from 'components/styledMUI/SearchIconWrapper';

const TitleStyled = styled(Typography)(() => ({
  fontSize: '1.125rem',
  fontWeight: 600,
  margin: '0 0 16px',
}));

const Search = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  border: '1px solid #e8edf7',
  borderRadius: 10,
}));

const StyledInputBase = styled(CommonInputBase)(() => ({
  '&.MuiInputBase-root': {
    padding: '4px 0',
    width: '100%',
  },
}));

const ListStyled = styled(List)(({ theme }) => ({
  margin: '8px 0',
  backgroundColor: '#fff',
  border: '1px solid #d4d9e7',
  borderRadius: 10,
  minHeight: '10vh',
  '& .MuiListItem-root': {
    padding: 0,
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.938rem',
    fontWeight: 800,
    color: '#266be2',
    marginBottom: 4,
    '&:before': {
      content: '"Q."',
      display: 'inline-flex',
      marginRight: 4,
    },
  },
  '& .MuiListItemText-secondary': {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#000',
    '&:before': {
      content: '"A."',
      display: 'inline-flex',
      marginRight: 4,
    },
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
  },
}));

export const AvatarCacheBox = () => {
  const { search, handleChangeSearch, debouncedSearch } = useContext(
    MissingQuestionContext,
  );

  return (
    <Box
      sx={{
        backgroundColor: '#F4F7FE',
        p: 3,
        borderRadius: 4,
      }}
    >
      {/* 답변완료 */}
      <TitleStyled>유사 질문에서 찾기</TitleStyled>
      <Search>
        <SearchIconWrapper>
          <SearchRoundedIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="질문을 검색해 주세요"
          value={search}
          onChange={handleChangeSearch}
        />
      </Search>
      <CacheList search={debouncedSearch} />
    </Box>
  );
};
