import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { TOAST_MESSAGE } from '../../../../constants/toast-message';

import { createUserIdea } from 'api-legacy/user';
import { ReactComponent as IdeaIcon } from 'assets/images/IconIdaaFlat.svg';

const BootstrapDialog = styled(Dialog)(() => ({
  '&.MuiDialog-root': {
    '& .MuiPaper-root': {
      width: '100%',
      borderRadius: 16,
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D3D4D4',
      padding: '14px 40px',
      // marginBottom:30,
      '& p': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: '12px 0 12px 8px',
      },
    },
    '& .MuiDialogContent-root': {
      fontSize: '1.375rem',
      padding: '24px 40px',
      color: '#161616',
      lineHeight: 1.37,
      '& li': {
        marginBottom: 12,
      },
    },
    '& .MuiDialogActions-root': {
      padding: '16px 40px 24px',
      alignItems: 'center',
    },
    '@media all and (max-width: 640px)': {
      '& .MuiDialogTitle-root': {
        padding: '16px 24px',
        '& p': {
          fontSize: '1.5rem!important',
        },
      },
      '& .MuiDialogContent-root': {
        fontSize: '1.125rem',
        padding: '16px 24px',
      },
      '& .MuiDialogActions-root': {
        padding: '0 24px 24px',
      },
    },
    '@media all and (max-width: 420px)': {
      '& .MuiDialogTitle-root': {
        padding: '8px 16px',
        '& p': {
          fontSize: '1.25rem!important',
        },
      },
    },
  },
}));
const StyledInput = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: '1.5rem',
    backgroundColor: '#fff',
    border: '1px solid #D3D4D4',
    borderRadius: 10,
    '& textarea': {
      padding: '12px 20px',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: 0,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
    {
      border: '0',
    },
}));

const CancleButtonStyle = styled(Button)(() => ({
  '&.MuiButton-root': {
    color: '#240B0D',
    backgroundColor: '#DBDBDB',
    borderRadius: 10,
    fontSize: '1.25rem',
    padding: '8px 40px',
    textDecoration: 'none',
    marginLeft: 30,
    '&:hover': {
      backgroundColor: '#DBDBDB',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '8px 20px',
      marginLeft: 0,
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1rem',
      padding: '6px 14px',
    },
  },
}));

const ConfirmButtonStyle = styled(Button)(() => ({
  '&.MuiButton-root': {
    color: '#fff',
    backgroundColor: '#93b5f1',
    borderRadius: 10,
    fontSize: '1.25rem',
    padding: '8px 40px',
    textDecoration: 'none',
    marginLeft: 30,
    '&:hover': {
      backgroundColor: '#93b5f1',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '8px 20px',
      marginLeft: 20,
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1rem',
      padding: '6px 14px',
    },
  },
}));

const DialogTitleStyle = styled(DialogTitle)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& svg': {
    width: 40,
    height: 40,
  },
  '@media all and (max-width: 440px)': {
    '& svg': {
      width: 32,
      height: 32,
    },
  },
}));

export default function IdeaDialog(props) {
  const { avatarId } = useParams();
  const location = useLocation();
  const { open, setOpen } = props;

  // const  [ideaConfirm, setIdeaConfirm] = useState(false);
  const [ideaText, setIdeaText] = useState('');

  const { mutate: createUserIdeasMutation } = useMutation({
    mutationFn: async (params) => {
      return await createUserIdea(params);
    },
    onError: () => {
      toast.error('아이디어 제안에 실패하였습니다.');
    },
    onSuccess: () => {
      toast(TOAST_MESSAGE.SUCCESS.IDEA_SUCCESS);

      handleClose();
    },
  });

  const handleIdeaConfirm = () => {
    // setIdeaConfirm(!ideaConfirm);
    if (ideaText.trim().length > 0) {
      createUserIdeasMutation({
        userTypeId: location.state ? location.state.userTypeId : 0,
        avatarId,
        value: ideaText,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIdeaText('');
  };

  const handleChangeIdeaText = (e) => {
    setIdeaText(e.target.value);
  };

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <DialogTitleStyle>
        <IdeaIcon width="42px" height="42px" />
        <p>아이디어 제안</p>
      </DialogTitleStyle>
      <DialogContent>
        {/*{ideaConfirm == false ? (*/}
        {/*	<TextField*/}
        {/*		onChange={handleChangeIdeaText}*/}
        {/*		value={ideaText}*/}
        {/*		placeholder="아이디어를 제안해주세요."*/}
        {/*		variant="outlined"*/}
        {/*		className={classes.inputStyle}*/}
        {/*		multiline*/}
        {/*		rows={4}*/}
        {/*	/>*/}
        {/*) : (*/}
        {/*	<Box className={classes.loadingBox}>*/}
        {/*		<span className={classes.spinner}></span>*/}
        {/*	</Box>*/}
        {/*)}*/}
        <StyledInput
          onChange={handleChangeIdeaText}
          value={ideaText}
          placeholder="아이디어를 제안해주세요."
          variant="outlined"
          multiline
          rows={6}
        />
      </DialogContent>
      <DialogActions>
        <CancleButtonStyle onClick={handleClose}>취소</CancleButtonStyle>
        <ConfirmButtonStyle onClick={handleIdeaConfirm}>
          확인
        </ConfirmButtonStyle>
      </DialogActions>
    </BootstrapDialog>
  );
}
