import { Link, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LogoBox } from 'pages/teams/features/team-info/components/atoms/LogoBox';
import { Paragraph } from 'pages/teams/features/team-info/components/atoms/Paragraph';
import { ButtonGroup } from 'pages/teams/features/team-info/components/blocks/ButtonGroup';
import { InfoItem } from 'pages/teams/features/team-info/components/items/InfoItem';

const EditButton = styled(Link)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#d32f2f',
  marginLeft: 'auto',
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
}));

export const TeamInfoMain = () => {
  const { teamInfo } = useOutletContext();
  if (teamInfo.isLoading) return <Box>로딩중</Box>;
  if (teamInfo.isError) return <Box>에러</Box>;

  return (
    <Box>
      <InfoItem title={'팀명'}>
        <Paragraph value={teamInfo.data.team.name} />
      </InfoItem>
      <InfoItem title={'타이틀'}>
        <Paragraph value={teamInfo.data.team.title} />
      </InfoItem>
      <InfoItem title={'로고'}>
        <LogoBox src={teamInfo.data.logoObjectUrl} alt={'팀 로고'} />
      </InfoItem>
      <ButtonGroup>
        <EditButton to="/team/info/edit">수정</EditButton>
      </ButtonGroup>
    </Box>
  );
};
