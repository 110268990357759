import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';
import { setLocalStorage } from 'lib/utils/localStorage.util';

export const fetchTeamInfo = async () => {
  const managerId = localStorage.getItem('id');
  const { data } = await tokenInstance.get(`${API.TEAM}/${managerId}`);
  setLocalStorage('teamId', data.team.id);
  return data;
};
