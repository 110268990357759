import toast from 'react-hot-toast';

import { QueryCache, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.meta?.errorMessage) {
        toast.error(query.meta.errorMessage);
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
