export const TOAST_MESSAGE = {
  SUCCESS: {
    LOGIN_SUCCESS: (name) => `${name} 님 반갑습니다.`,
    IDEA_SUCCESS: '아이디어를 제안하였습니다.',
    CREATE_SUCCESS: '추가되었어요.',
    UPDATE_SUCCESS: '수정되었어요.',
    DELETE_SUCCESS: '삭제되었어요.',
  },
  ERROR: {
    SERVER_ERROR:
      '서버와의 접속이 원활하지 않습니다.\n잠시후에 다시 시도해주세요.',
    LOGIN_ERROR: '아이디와 비밀번호를 확인해주세요.',

    // 시스템관리자, 팀관리자 - 계정 생성 시 이미 존재하는 ID 에러 : DuplicatedLoginId
    DUPLICATED_LOGIN_ID_ERROR: '이미 존재하는 아이디 입니다.',

    // 시스템관리자, 팀관리자 - 비밀번호, 계정 사용여부 업데이트 시 에러 : UserNotFound
    USER_NOT_FOUND_ERROR: '존재하지 않는 아이디입니다.',

    // 시스템관리자 - 캐릭터 생성 시 이미지 확장자를 찾지 못했을 경우 에러 : TypeNotFound
    TYPE_NOT_FOUND_ERROR: '이미지 파일의 확장자를 찾을 수 없습니다.',

    // 채팅방 - stt, ai질문 전송 시, 비속어가 있는 경우 에러 : BadWordDetected
    BAD_WORD_DETECTED_ERROR: '질문에 부적절한 표현이 발견되었습니다.',

    // 채팅방 - stt 작업 중 오디오 파일을 읽을 수 없는 경우 에러 : GetAudioInputStreamFailed
    GET_AUDIO_INPUT_STREAM_ERROR: '음성 파일을 읽을 수 없습니다.',

    // 아바타 관리자, 팀관리자 - 파일 편집 리다이렉트 시 토큰 만료 에러 : Expired
    TOKEN_EXPIRED_ERROR: '토큰 시간이 만료되었습니다.',

    //파일 편집 리다이렉트 시 파일을 찾을 수 없을 때 에러 : CanNotFoundFile
    CAN_NOT_FOUND_FILE_ERROR: '파일을 찾을 수 없습니다.',

    //profiles-simple api - 비공개 계정인 경우 에러 : IsNotPublicAvatar
    AVATAR_IS_NOT_PUBLIC: '비공개된 아바타입니다.',

    CREATE_ERROR: '추가하지 못했습니다.',
    UPDATE_ERROR: '수정하지 못했습니다.',
    DELETE_ERROR: '삭제하지 못했습니다.',
  },
};
