import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import LoadingSpinner from 'ui/LoadingSpinner';

import { cacheQueries } from 'react-query/factory/cacheQueries';

import { Answer } from './Answer';

import { CommonStack } from 'components/styledMUI/CommonStack';
import { DATE_UTIL } from 'lib/utils/date.util';

const AccordionWrap = styled('div')(({ theme }) => ({
  marginTop: 10,
  border: '1px solid #d4d9e7',
  borderRadius: 10,
  overflow: 'hidden',
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  // border: '1px solid #ddd',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&:first-of-type .MuiAccordionSummary-root': {
    borderTop: 0,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  borderTop: '1px solid #d4d9e7',
  backgroundColor: '#F4F7FE',
  flexDirection: 'row',
  padding: '6px 16px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& svg': {
      width: 24,
      height: 24,
    },
    '&.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.75rem',
      marginRight: 6,
      marginBottom: 0,
      padding: '0 4px',
      fontWeight: 600,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '& .MuiTypography-h6': {
      width: '100%',
      paddingRight: 32,
      fontSize: '1rem',
      whiteSpace: 'normal',
      display: '-webkit-box',
      webkitLineClamp: '1',
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    '&.Mui-expanded': {
      '& .MuiTypography-h6': {
        webkitBoxOrient: 'unset',
        overflow: 'visible',
      },
    },
    '& .MuiTypography-caption': {
      color: '#9b9b9b',
      fontSize: '0.875rem',
      fontWeight: 500,
      padding: 0,
      marginRight: 0,
    },
  },
}));

export const AiCacheGrid = ({ orderBy, search }) => {
  const { candidateId } = useParams();
  const [page, setPage] = useState(1);

  const {
    isLoading,
    isError,
    data: avatarCache,
    isSuccess,
  } = useQuery(
    cacheQueries.cache(
      candidateId,
      'allow empty string',
      orderBy,
      page,
      search,
    ),
  );
  if (isLoading) return <LoadingSpinner isLoading={true} />;
  if (isError) return null;
  return (
    <Grid container spacing={2} mt={2}>
      {isSuccess && (
        <Grid item xs={12}>
          <AccordionWrap>
            {avatarCache.items.length > 0 ? (
              <>
                {avatarCache.items.map((cache) => (
                  <Accordion key={cache.cacheId}>
                    <AccordionSummary>
                      <Typography variant="h6">{cache.question}</Typography>
                      <Typography variant="caption">
                        {DATE_UTIL.converterUTCToTimeZone(
                          cache.createdDatetime,
                          DATE_UTIL.FORMAT.FULL_FORMAT_KOREAN,
                        )}
                      </Typography>
                      <Typography variant="caption">
                        <span>유사질문: {cache.hitCount}</span>&nbsp;
                        <span>좋아요: {cache.likeCount}</span>&nbsp;
                        <span>싫어요: {cache.disLikeCount}</span>
                      </Typography>
                    </AccordionSummary>
                    <Answer cache={cache} />
                  </Accordion>
                ))}
              </>
            ) : (
              <Box sx={{ m: 6, display: 'flex', justifyContent: 'center' }}>
                <Typography>
                  {search.length > 0
                    ? '검색 결과가 없습니다.'
                    : 'AI 질문 답변 목록이 없습니다.'}
                </Typography>
              </Box>
            )}
          </AccordionWrap>

          {avatarCache.maxPage > 1 && (
            <CommonStack spacing={2} mt={3}>
              <Pagination
                count={avatarCache.maxPage}
                page={page}
                onChange={(_event, page) => setPage(page)}
              />
            </CommonStack>
          )}
        </Grid>
      )}
    </Grid>
  );
};
