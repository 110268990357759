import { styled } from '@mui/material/styles';

export const BoxComponent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '24px 32px',
  borderRadius: 10,
  '& .MuiFormControl-root': {
    width: '100%',
    marginBottom: 12,
  },
}));
