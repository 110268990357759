import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(() => ({
  '&.MuiDialog-root': {
    '& .MuiPaper-root': {
      width: 320,
      borderRadius: 16,
    },
    '& .MuiDialogTitle-root': {
      padding: '16px 24px',
      margin: '12px 0 0',
      '& p': {
        fontSize: '1.375rem',
        fontWeight: 'bold',
        margin: '12px 0 12px 8px',
      },
    },
    '& .MuiDialogContent-root': {
      fontSize: '1.375rem',
      padding: '24px 40px',
      color: '#161616',
      lineHeight: 1.37,
      '& li': {
        marginBottom: 12,
      },
    },
    '& .MuiDialogActions-root': {
      padding: '16px 24px',
      alignItems: 'center',
    },
    '@media all and (max-width: 640px)': {
      '& .MuiDialogTitle-root': {
        padding: '16px 24px',
        '& p': {
          fontSize: '1.5rem!important',
        },
      },
      '& .MuiDialogContent-root': {
        fontSize: '1.125rem',
        padding: '16px 24px',
      },
      '& .MuiDialogActions-root': {
        padding: '24px',
      },
    },
    '@media all and (max-width: 420px)': {
      '& .MuiDialogTitle-root': {
        padding: '8px 16px',
        '& p': {
          fontSize: '1.25rem!important',
        },
      },
    },
  },
}));

const TopBoxStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& svg': {
    width: 40,
    height: 40,
  },
  '@media all and (max-width: 440px)': {
    '& svg': {
      width: 32,
      height: 32,
    },
  },
}));

const ExplainStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CancleButton = styled(Button)(() => ({
  '&.MuiButton-root': {
    color: '#32383e',
    backgroundColor: '#fff',
    borderRadius: 10,
    fontSize: '1.125rem',
    padding: '10px 24px',
    textDecoration: 'none',
    whiteSpace: 'pre',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '8px 24px',
      marginLeft: 5,
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1rem',
      padding: '6px 14px',
    },
  },
}));

const ConfirmButton = styled(Button)(() => ({
  '&.MuiButton-root': {
    color: '#fff',
    backgroundColor: '#32383e',
    borderRadius: 10,
    fontSize: '1.125rem',
    padding: '10px 24px',
    textDecoration: 'none',
    whiteSpace: 'pre',
    '&:hover': {
      backgroundColor: '#32383e',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '8px 24px',
      marginLeft: 5,
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1rem',
      padding: '6px 14px',
    },
  },
}));

export const DeleteConfirmDialog = ({
  open,
  handleClose,
  maxWidth,
  handleDelete,
  pending,
}) => {
  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth || 'sm'}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TopBoxStyle>
          <p>삭제하시겠습니까?</p>
        </TopBoxStyle>
      </DialogTitle>
      <ExplainStyle>
        <p>삭제된 데이터는 복구할 수 없습니다.</p>
      </ExplainStyle>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <CancleButton onClick={handleClose}>취소</CancleButton>
        <ConfirmButton disabled={pending} onClick={handleDelete}>
          확인
        </ConfirmButton>
      </DialogActions>
    </BootstrapDialog>
  );
};
