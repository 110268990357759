import { useRef } from 'react';

import { Input } from '@mui/material';
import { styled } from '@mui/material/styles';

import handleFileUpload from 'lib/utils/handleFileUpload';

const VisuallyHiddenInput = styled(Input)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function useFileInput(format, handler, accept) {
  const ref = useRef();

  const openDirectory = () => {
    if (!ref) return;
    if (accept) {
      ref.current.accept = accept;
    }
    ref.current.click();
  };

  const FileInput = () => (
    <VisuallyHiddenInput
      id="file-input"
      type="file"
      inputRef={ref}
      onChange={(event) => {
        handleFileUpload(event, format, handler);
        ref.current.value = null;
      }}
    ></VisuallyHiddenInput>
  );

  return [FileInput, openDirectory];
}
