import { useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BoxComponent } from 'ui/dialog-items/BoxComponent';
import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogInput } from 'ui/dialog-items/DialogInput';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';

import { useChangePasswordMutation } from 'react-query/mutations/managers/useChangePasswordMutation';

import { regExType, validation } from 'lib/utils/validation.util';

const LabelText = styled(Typography)(() => ({
  fontSize: '0.938rem',
  marginBottom: 4,
}));

export const ChangePasswordDialog = ({ id, open, handleClose }) => {
  const [message, setMessage] = useState('');

  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const validateSubmit = () => {
    if (!validation(regExType.PasswordRegEx, password))
      return {
        enable: false,
        reason:
          '알파벳, 숫자, 특수 문자를 포함한 8자 이상의 비밀번호를 입력해주세요.',
      };
    if (!validation(regExType.PasswordRegEx, checkPassword))
      return {
        enable: false,
        reason:
          '알파벳, 숫자, 특수 문자를 포함한 8자 이상의 비밀번호를 입력해주세요.',
      };
    if (password !== checkPassword) {
      return {
        enable: false,
        reason: '비밀번호를 재확인해주세요.',
      };
    }

    return { enable: true };
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangeCheckPassword = (event) => {
    setCheckPassword(event.target.value);
  };

  const mutation = useChangePasswordMutation();
  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = validateSubmit();
    if (validation.enable) {
      mutation.mutate(
        { id, password },
        {
          onSuccess: handleClose,
        },
      );
    } else {
      setMessage(validation.reason);
    }
  };
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogHeader title={'비밀번호 변경'} handleClose={handleClose} />
      <form onSubmit={handleSubmit}>
        <BoxComponent>
          <FormControl>
            <LabelText>새 비밀번호</LabelText>
            <DialogInput
              value={password}
              onChange={handleChangePassword}
              placeholder="새 비밀번호를 입력해주세요"
              type="password"
              autoComplete="off"
            />
          </FormControl>
          <FormControl>
            <LabelText>새 비밀번호 확인</LabelText>
            <DialogInput
              value={checkPassword}
              onChange={handleChangeCheckPassword}
              placeholder="새 비밀번호를 다시 입력해주세요"
              type="password"
              autoComplete="off"
            />
          </FormControl>
          <Box
            sx={{
              '& span': {
                color: '#CA4242',
                width: '100%',
              },
            }}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <span>{message}</span>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <CancelButton disabled={mutation.isPending} onClick={handleClose}>
                취소
              </CancelButton>
              <SubmitButton
                type="submit"
                disabled={!validateSubmit() || mutation.isPending}
              >
                변경
              </SubmitButton>
            </Box>
          </Box>
        </BoxComponent>
      </form>
    </DialogWrapper>
  );
};
