import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

import { BoxComponent } from 'ui/dialog-items/BoxComponent';
import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogInput } from 'ui/dialog-items/DialogInput';
import { DialogItemLabel } from 'ui/dialog-items/DialogItemLabel';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';
import { ReactComponent as PlantIcon1 } from '../../assets/images/PlantIcon1.svg';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { CommonIconButton } from '../styledMUI/CommonIconButton';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import useFileInput from '../../hooks/useFileInput';
import { ICON } from '../../constants/fileFormats';
import useImageUpload from '../../hooks/useImageUpload';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useCreateAvatarCacheCategoryMutation } from '../../react-query/mutations/questions/useCreateAvatarCacheCategoryMutation';
import { useUpdateAvatarCacheCategoryMutation } from '../../react-query/mutations/questions/useUpdateAvatarCacheCategoryMutation';
import toast from 'react-hot-toast';
const CommonSelect = styled(Select)(() => ({
  backgroundColor: '#fff',
  marginBottom: 12,
  height: 47,
  '& svg': {
    color: '#869592',
  },
}));

const ListStyled = styled('ul')(() => ({
  padding: 0,
  paddingLeft: 20,
  '& li': {
    marginBottom: 4,
  },
}));

export const AddCacheCategoryDialog = ({
  candidateId,
  title,
  open,
  handleClose,
  mainCategory,
  avatarCacheCategories,
  selectedCategory,
  isEdit,
  setSelectedCategory,
}) => {
  const [image, changeImage] = useImageUpload();
  const [FileInput, openDirectory] = useFileInput(ICON, changeImage, 'image/*');

  const [categoryData, setCategoryData] = useState({
    avatarId: candidateId,
    question: isEdit ? selectedCategory.question : '',
    answer: isEdit ? selectedCategory.answer : '',
    parentId: mainCategory
      ? !isEdit
        ? mainCategory.categoryId
        : mainCategory.categoryId !== selectedCategory.categoryId
          ? mainCategory.categoryId
          : null
      : null,
    categoryId: isEdit ? selectedCategory.categoryId : null,
    imageUrl: isEdit ? selectedCategory.iconObject?.downloadUrl : image.url,
  });

  const { mutate: createAvatarCacheCategoryMutation } =
    useCreateAvatarCacheCategoryMutation();

  const { mutate: updateAvatarCacheCategoryMutation } =
    useUpdateAvatarCacheCategoryMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (categoryData.question) {
      isEdit
        ? updateAvatarCacheCategoryMutation(
            { inputData: categoryData, image: image.file },
            {
              onSuccess: () => {
                handleClose();
                setSelectedCategory(categoryData);
              },
            },
          )
        : createAvatarCacheCategoryMutation(
            { inputData: categoryData, image: image.file },
            {
              onSuccess: () => {
                handleClose();
              },
            },
          );
    } else {
      return toast('카테고리 제목을 입력해주세요.');
    }
  };

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogHeader title={title} handleClose={handleClose} />
      <BoxComponent>
        <FormControl>
          <DialogItemLabel>상위 카테고리</DialogItemLabel>
          <CommonSelect
            name={'parentId'}
            value={categoryData.parentId ? categoryData.parentId : 'null'}
            disabled={!isEdit}
            onChange={handleChange}
            IconComponent={() => (
              <Box style={{ marginRight: 10, display: 'flex' }}>
                <ExpandMoreRoundedIcon />
              </Box>
            )}
            displayEmpty
          >
            <MenuItem key={'null'} value={null}>
              없음 (최상위 카테고리로 생성)
            </MenuItem>
            {avatarCacheCategories?.items?.map((category) => (
              <MenuItem key={category.categoryId} value={category.categoryId}>
                {category.question}
              </MenuItem>
            ))}
          </CommonSelect>
          <DialogItemLabel>카테고리</DialogItemLabel>
          <DialogInput
            name={'question'}
            // readOnly={mutation.isPending}
            placeholder="카테고리 제목을 입력해주세요."
            value={categoryData.question || ''}
            onChange={handleChange}
          />
          <DialogInput
            name={'answer'}
            value={categoryData.answer || ''}
            placeholder="내용"
            fullWidth
            multiline
            rows={4}
            onChange={handleChange}
          />
          <DialogItemLabel>아이콘</DialogItemLabel>
          <Box
            display="flex"
            mb={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ maxWidth: 380 }}>
                {image.url
                  ? image.file
                    ? image.file.name
                    : '이름 없음'
                  : '기본 아이콘'}
              </Typography>
              {image.file.name && (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={changeImage}
                  aria-label="close"
                  style={{ marginLeft: 5 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Tooltip
              placement="right"
              // disableInteractive={usableMutation.isPending}
              title={
                <ListStyled>
                  <li>등록 가능한 파일 종류 : PNG, ICO, SVG, JPEG, JPG </li>
                  <li>파일 크기에 따라 업로드와 처리시간이 소요됩니다.</li>
                </ListStyled>
              }
            >
              <CommonIconButton onClick={openDirectory}>
                <>
                  <PostAddRoundedIcon />
                  아이콘 변경{FileInput()}
                </>
              </CommonIconButton>
            </Tooltip>
          </Box>
          <DialogItemLabel>미리보기</DialogItemLabel>
          <Box display="flex">
            <DialogInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {categoryData.imageUrl ? (
                      <img
                        src={categoryData.imageUrl}
                        alt={'icon'}
                        style={{ width: 40, height: 40 }}
                      />
                    ) : (
                      <PlantIcon1 />
                    )}
                  </InputAdornment>
                ),
              }}
              // onChange={handleChangeValue}
              name="value"
              value={categoryData.question || ''}
              placeholder="제목"
              disabled
              multiline
            />
          </Box>
        </FormControl>
        <Box
          sx={{
            '& span': { color: '#CA4242' },
          }}
          display="flex"
          justifyContent="flex-end"
          width="100%"
        >
          {/*<span>{message}</span>*/}
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={handleClose}>취소</CancelButton>
            <SubmitButton
              // disabled={!characters.data || mutation.isPending}
              onClick={handleSubmit}
            >
              {isEdit ? '수정' : '등록'}
            </SubmitButton>
          </Box>
        </Box>
      </BoxComponent>
    </DialogWrapper>
  );
};
