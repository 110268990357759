import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TOAST_MESSAGE } from 'constants/toast-message';
import { createCategory } from '../../../api/category/createCategory';
import { categoryQueries } from '../../factory/categoryQueries';
import { updateCategory } from '../../../api/category/updateCategory';

export const useUpdateAvatarCacheCategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      await updateCategory(params);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: categoryQueries.all(),
      });
    },
  });
};
