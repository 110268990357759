import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { defaultInstance } from 'lib/axios/instance';

export const queryAudioQuestion = async ({ audioBlob }) => {
  const formData = new FormData();
  formData.append('audio-file', audioBlob, 'recording.wav');

  const { data } = await defaultInstance.post(
    `${API.USERS}/stt`,
    formData,
    MULTIPART_HEADER,
  );
  return data;
};
