import { useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as ShareIcon } from 'assets/images/IconShareFlat.svg';
import { useStyles } from 'components/styles/TopBarStyles';
import { sharePC } from 'lib/utils/share';

const BootstrapDialog = styled(Dialog)(() => ({
  '&.MuiDialog-root': {
    '& .MuiPaper-root': {
      width: '100%',
      borderRadius: 16,
    },
    '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #D3D4D4',
      padding: '16px 24px',
      // marginBottom:30,
      '& p': {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: '12px 0 12px 8px',
      },
    },
    '& .MuiDialogContent-root': {
      fontSize: '1.375rem',
      padding: '24px 40px',
      color: '#161616',
      lineHeight: 1.37,
      '& li': {
        marginBottom: 12,
      },
    },
    '& .MuiDialogActions-root': {
      padding: '16px 24px',
      alignItems: 'center',
    },
    '@media all and (max-width: 640px)': {
      '& .MuiDialogTitle-root': {
        padding: '16px 24px',
        '& p': {
          fontSize: '1.5rem!important',
        },
      },
      '& .MuiDialogContent-root': {
        fontSize: '1.125rem',
        padding: '16px 24px',
      },
      '& .MuiDialogActions-root': {
        padding: '24px',
      },
    },
    '@media all and (max-width: 420px)': {
      '& .MuiDialogTitle-root': {
        padding: '8px 16px',
        '& p': {
          fontSize: '1.25rem!important',
        },
      },
    },
  },
}));

const TopBoxStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& svg': {
    width: 40,
    height: 40,
  },
  '@media all and (max-width: 440px)': {
    '& svg': {
      width: 32,
      height: 32,
    },
  },
}));

const ShareButton = styled(Button)(() => ({
  '&.MuiButton-root': {
    color: '#fff',
    backgroundColor: '#93b5f1',
    borderRadius: 10,
    fontSize: '1.25rem',
    padding: '10px 24px',
    textDecoration: 'none',
    whiteSpace: 'pre',
    marginLeft: 10,
    '&:hover': {
      backgroundColor: '#93b5f1',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '8px 24px',
      marginLeft: 5,
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1rem',
      padding: '6px 14px',
    },
  },
}));

const StyledInput = styled(TextField)(() => ({
  width: '100%',
  border: 'none',
  '& input': {
    fontSize: '1rem',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      borderRadius: 10,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 10,
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '0 none',
  },
  '& .MuiFormControl-fullWidth': {
    width: '100%',
  },
}));

export default function ShareDialog(props) {
  const params = useParams();
  const { open, setOpen, copyUrl } = props;

  const originalPath = window.location.origin;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TopBoxStyle>
          <ShareIcon />
          <p>프로필 링크 공유</p>
        </TopBoxStyle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <StyledInput
          disabled
          value={`${originalPath}/profile/${
            params.candidateId ? params.candidateId : params.avatarId
          }`}
        ></StyledInput>
        <ShareButton
          onClick={() =>
            sharePC(
              'profile',
              params.candidateId ? params.candidateId : params.avatarId,
            )
          }
        >
          복사
        </ShareButton>
      </DialogActions>
      {props.fromAdmin && (
        <span
          style={{ width: '100%', textAlign: 'center', marginBottom: '12px' }}
        >
          환경 설정에서 외부 노출 여부를 확인해주세요!
        </span>
      )}
    </BootstrapDialog>
  );
}
