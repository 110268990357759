import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiDialog-container > .MuiPaper-root': {
    minWidth: 640,
    backgroundColor: '#F4F7FE',
  },
  '& header': {
    backgroundColor: '#fff',
    borderBottom: '1px solid #d4d9e7',
    paddingRight: '0!important',
  },
}));
