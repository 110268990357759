import { useState } from 'react';

import { Box, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import CandidateVisitorChart from 'pages/teams/features/monitoring/components/CandidateVisitorChart.js';

import { PageTitle } from 'components/styledMUI/PageTitle';
import { DATE_UTIL } from 'lib/utils/date.util';

const BoxStyled = styled('div')(() => ({
  marginBottom: 20,
}));

const SubTitle = styled('h4')(() => ({
  width: '100%',
  margin: '3.5rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.125rem',
  borderBottom: '1px dashed #ddd',
  paddingBottom: 12,
}));

export const Monitoring = () => {
  const [date, setDate] = useState(DATE_UTIL.getInputValueFormatToday());

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle>모니터링</PageTitle>
      </Box>
      <Box>
        <BoxStyled>
          <SubTitle>캐릭터별 질의응답 수</SubTitle>
          <input
            value={date ?? DATE_UTIL.getInputValueFormatToday()}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            id="month"
            type="month"
            name="month"
            min="2023-12"
            max={DATE_UTIL.getInputValueFormatToday()}
          />
          <Box mb={8}>
            <CandidateVisitorChart date={date} />
          </Box>
        </BoxStyled>
      </Box>
    </>
  );
};
