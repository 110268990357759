import { Link, useLocation } from 'react-router-dom';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  minHeight: 42,
  px: 2.5,
  '& .MuiListItemText-root span': {
    fontWeight: 700,
  },
}));
export const SidebarMenu = ({ item }) => {
  const { pathname } = useLocation();
  return (
    <ListItem disablePadding sx={{ display: 'block' }} key={item.title}>
      <ListItemButtonStyled
        sx={{
          justifyContent: open ? 'initial' : 'center',
          backgroundColor: pathname === item.link ? '#ECF3FF' : '#fff',
        }}
        component={Link}
        to={item.link}
      >
        <ListItemIcon
          sx={{
            width: 32,
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography={true}
          primary={item.title}
          sx={{
            fontWeight: 700,
            opacity: open ? 1 : 0,
            color: pathname === item.link ? '#276ae2' : '#000',
          }}
        />
      </ListItemButtonStyled>
    </ListItem>
  );
};
