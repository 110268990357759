import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Box } from '@mui/material';
import { PinnedQuestionController } from './components/pinnedQuestion/PinnedQuestionController';
import { PinnedQuestionHandler } from './components/pinnedQuestion/PinnedQuestionHandler';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { PageTitle } from 'components/styledMUI/PageTitle';
import { AVATAR_CONFIG_DETAILS } from '../../constants/queryKeys';
import { getAvatarConfigDetails } from '../../api-legacy/avatars/getAvatarDescription';

export default function PinnedQuestionManagement() {
  const { candidateId } = useParams();

  const [page, setPage] = useState(1);

  const [subPage, setSubPage] = useState(1);

  const { data: avatarConfigDetails, isLoading } = useQuery({
    queryKey: [AVATAR_CONFIG_DETAILS, candidateId],
    queryFn: async () => {
      const { data } = await getAvatarConfigDetails(candidateId);
      return data;
    },
  });

  if (isLoading) return <></>;
  return (
    <ContentBox component="main" maxWidth="xl">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <PageTitle>고정 답변 관리</PageTitle>
      </Box>
      <PinnedQuestionHandler
        page={page}
        subPage={subPage}
        setPage={setPage}
        setSubPage={setSubPage}
        candidateId={candidateId}
        avatarConfigDetails={avatarConfigDetails}
      >
        <PinnedQuestionController candidateId={candidateId} />
      </PinnedQuestionHandler>
    </ContentBox>
  );
}
