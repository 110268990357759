import toast from 'react-hot-toast';
import { useOutletContext } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { teamInfoQueries } from 'react-query/factory/teamInfoQueries';

import { updateTeamInfo } from 'api/infos/updateTeamInfo';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useTeamInfoMutation = () => {
  const queryClient = useQueryClient();
  const { teamId } = useOutletContext();

  return useMutation({
    mutationFn: (formData) => updateTeamInfo(teamId, formData),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: teamInfoQueries.infos(),
      });
    },
  });
};
