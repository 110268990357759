import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchFileEditUrl = async (fileId) => {
  const { data } = await tokenInstance.get(`${API.FILES}/${fileId}/edit`, {
    params: { managerId: localStorage.getItem('id') },
  });

  return data;
};
