import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderButton = styled(Button)(() => ({
  minWidth: 'auto',
  margin: '0 8px 0 16px',
  // color:'#0F0F10',
  '& svg': {
    width: 26,
    height: 26,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
