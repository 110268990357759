import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubmitButton = styled(Button)(() => ({
  minWidth: 72,
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  margin: '0 4px',
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));
