import { useState } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AiCacheGrid } from './components/AiQuestion/AiCacheGrid';

import { CommonInputBase } from 'components/styledMUI/CommonInputBase';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { PageTitle } from 'components/styledMUI/PageTitle';
import { SearchIconWrapper } from 'components/styledMUI/SearchIconWrapper';
import { useDebouncedValue } from 'rooks';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  border: '1px solid #e8edf7',
  borderRadius: 10,
}));

const CustomInputBase = styled(CommonInputBase)(({ theme }) => ({
  '&.MuiInputBase-root': {
    padding: '8px 0',
    width: '100%',
  },
}));

export default function AiQuestionManagement() {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const [orderBy, setOrderBy] = useState('');
  const handleTabOrderBy = (event, newValue) => {
    setOrderBy(newValue);
  };

  return (
    <ContentBox component="main" maxWidth="xl">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <PageTitle>AI 질문 답변 목록</PageTitle>
        <Tabs value={orderBy} onChange={handleTabOrderBy}>
          <Tab label="최신순" value={''} />
          <Tab label="질문순" value={'HitCount'} />
          <Tab label="좋아요순" value={'Like'} />
          <Tab label="싫어요순" value={'DisLike'} />
        </Tabs>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Search>
          <SearchIconWrapper>
            <SearchRoundedIcon />
          </SearchIconWrapper>
          <CustomInputBase
            placeholder="질문을 검색해 주세요"
            value={search}
            onChange={handleChange}
          />
        </Search>
      </Box>
      {/* 누락된 질문이 없을 때 */}
      {/*<Box sx={{m:6, display:'flex', justifyContent:'center'}} >*/}
      {/*    <Typography>누락된 질문이 없습니다.</Typography>*/}
      {/*</Box>*/}
      {/* // 누락된 질문이 없을 때 */}
      {/*<Divider />*/}
      <AiCacheGrid search={debouncedSearch} orderBy={orderBy} />
    </ContentBox>
  );
}
