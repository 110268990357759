import { PageTitle } from 'ui/titles/PageTitle';

import { TeamInfoEditForm } from './components/blocks/TeamInfoEditForm';

export const TeamInfoEdit = () => {
  return (
    <>
      <PageTitle>팀 정보</PageTitle>
      <TeamInfoEditForm />
    </>
  );
};
