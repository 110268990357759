import { queryOptions } from '@tanstack/react-query';

import { fetchAvatarCache } from 'api/caches/fetchAvatarCache';
import { fetchFrequentCache } from 'api/caches/fetchFrequentCache';

export const cacheQueries = {
  all: () => ['cache'],
  frequentList: (avatarId) =>
    queryOptions({
      queryKey: [...cacheQueries.all(), 'most-frequent', avatarId],
      queryFn: () => fetchFrequentCache(avatarId),
    }),
  caches: (candidateId, mode, orderBy, page, search) => [
    ...cacheQueries.all(),
    candidateId,
    mode,
    orderBy,
    page,
    search,
  ],
  cache: (candidateId, mode, orderBy, page, search) =>
    queryOptions({
      queryKey: cacheQueries.caches(candidateId, mode, orderBy, page, search),
      queryFn: () => fetchAvatarCache(candidateId, orderBy, page, search),
      // enabled: !!search,
      meta: { errorMessage: '데이터를 불러오지 못했습니다.' },
    }),
};
