import { useState } from 'react';

import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import { IconButton, Tooltip } from '@mui/material';

import { AvatarListDialog } from 'components/Dialog/AvatarListDialog';

export const AvatarMatchingTooltip = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Tooltip title="캐릭터 지정" onClick={handleOpenDialog}>
        <IconButton>
          <HowToRegRoundedIcon />
        </IconButton>
      </Tooltip>
      {openDialog && (
        <AvatarListDialog
          id={id}
          open={openDialog}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
