import { Fragment, memo } from 'react';
import toast from 'react-hot-toast';

import { Typography } from '@mui/material';

import {
  IconBox,
  IconButtonStyle,
  UserChat,
} from 'pages/user/features/question/components/styled';

import { ReactComponent as FileCopyActiveIcon } from 'assets/images/FileCopyActiveIcon.svg';

const UserQuestion = ({ question }) => {
  const handleCopyAnswer = async () => {
    try {
      await navigator.clipboard.writeText(question);
      toast.success('클립보드에 질문이 복사되었습니다.');
    } catch (e) {
      toast.error('복사에 실패하였습니다');
    }
  };
  return (
    <UserChat>
      <Typography variant="body1">
        {question.split('\n').map((line, index, { length }) => (
          <Fragment key={`${index}-${line}`}>
            {line}
            {index < length && <br />}
          </Fragment>
        ))}
      </Typography>
      <IconBox sx={{ justifyContent: 'end' }}>
        <IconButtonStyle disableripple="true" onClick={handleCopyAnswer}>
          <FileCopyActiveIcon />
        </IconButtonStyle>
      </IconBox>
    </UserChat>
  );
};
export default memo(UserQuestion);
