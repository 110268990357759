import { Box } from '@mui/material';

import { useStyles } from '../components/styles/LoadingSpinnerStyles';

const LoadingSpinner = ({ isLoading }) => {
  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <Box className={classes.loadingBox}>
          <span className={classes.spinner} />
        </Box>
      )}
    </>
  );
};

export default LoadingSpinner;
