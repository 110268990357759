import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { usePaginationProps } from 'hooks/usePaginationProps';

import { DataBox } from 'ui/datagrid';
import LoadingSpinner from 'ui/LoadingSpinner';

import { AvatarManagementCell } from 'pages/teams/features/management/components/cells/AvatarManagementCell';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';

export const AvatarsGrid = ({ filter }) => {
  const { teamId } = useOutletContext();
  const pagination = usePaginationProps();

  const avatars = useQuery(
    teamAvatarsQueries.list(
      teamId,
      pagination.rowsPerPage,
      filter,
      pagination.page + 1,
    ),
  );

  const columns = [
    { field: 'name', headerName: '캐릭터', width: 200 },
    { field: 'characterName', headerName: '목소리', width: 140 },
    {
      field: 'age',
      headerName: '나이',
      type: 'number',
      width: 120,
    },
    {
      field: 'gender',
      headerName: '성별',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => <>{params.value === 'Male' ? '남자' : '여자'}</>,
    },
    {
      field: 'management',
      headerName: '캐릭터 관리',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { id, name } = params.row;
        return <AvatarManagementCell id={id} name={name} />;
      },
    },
  ];
  if (avatars.isLoading)
    return <LoadingSpinner isLoading={avatars.isLoading} />;
  return (
    <DataBox
      data={avatars.data.items}
      columns={columns}
      total={avatars.data.totalItems}
      pagination={pagination}
    />
  );
};
