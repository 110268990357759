import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import Cache from 'pages/user/features/question/components/items/Cache';
import { GridBox } from 'pages/user/features/question/components/styled';

import { cacheQueries } from 'react-query/factory/cacheQueries';

import { ChatContext } from '../context/ChatProvider';

export const CacheGrid = ({ initPrompt, chatHistory, handleAddChat }) => {
  const { avatarId } = useParams();
  const { frequent } = useContext(ChatContext);

  const frequentCaches = useQuery(cacheQueries.frequentList(avatarId));
  if (
    frequentCaches.isLoading ||
    frequentCaches.isError ||
    !frequentCaches.data.length
  )
    return null;

  if (frequent?.length)
    return (
      <GridBox>
        {frequent.map((cache) => (
          <Cache
            initPrompt={initPrompt}
            chatHistory={chatHistory}
            handleAddChat={handleAddChat}
            key={cache.cacheId}
            cache={cache}
          />
        ))}
      </GridBox>
    );

  return (
    <GridBox>
      {frequentCaches.data.map((cache) => (
        <Cache
          initPrompt={initPrompt}
          chatHistory={chatHistory}
          handleAddChat={handleAddChat}
          key={cache.cacheId}
          cache={cache}
        />
      ))}
    </GridBox>
  );
};
