import { useMutation } from '@tanstack/react-query';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Tooltip } from '@mui/material';

import { HeaderButton } from 'ui/layout/HeaderButton';

import { doLogout } from 'api/auth/do-logout';

export const Logout = () => {
  const handleLogout = () => {
    logoutMutation.mutate();
  };

  const logoutMutation = useMutation({
    mutationFn: doLogout,
    onSettled: () => {
      localStorage.clear();
      window.location.replace('/login');
    },
  });
  return (
    <Tooltip title="로그아웃" placement="bottom" onClick={handleLogout}>
      <HeaderButton disableRipple>
        <LogoutRoundedIcon />
      </HeaderButton>
    </Tooltip>
  );
};
