import { useState } from 'react';
import { Box, Button, Chip, TextField, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { useDeleteCacheAnswerMutation } from 'react-query/mutations/questions/useDeleteCacheAnswerMutation';
import { useUpdateCacheAnswerMutation } from 'react-query/mutations/questions/useUpdateCacheAnswerMutation';
import { DeleteConfirmDialog } from 'components/Dialog/DeleteConfirmDialog';
import { useDeleteAvatarCategoryCacheMutation } from '../../../../react-query/mutations/questions/useDeleteAvatarCategoryCacheMutation';

const StyledInput = styled(TextField)(() => ({
  width: '100%',
  boxSizing: 'border-box',
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginBottom: 12,
  },
  '& input, textarea': {
    fontSize: '1rem',
    maxHeight: 300,
    overflow: 'auto !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d4d9e7',
  },
}));

const CommonButton = styled(Button)(() => ({
  // width:'100%',
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '8px 24px',
  marginTop: 8,
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  '&.Mui-disabled': {
    color: '#fff',
    backgroundColor: '#333',
    opacity: 0.3,
  },
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));
const DeleteButton = styled(CommonButton)(() => ({
  backgroundColor: '#fff',
  color: '#cf222e',
  border: '1px solid',
  '&.Mui-disabled': {
    backgroundColor: '#fff',
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: '#cf222e',
  },
}));

export const Answer = ({ cache, selectedCategory }) => {
  const update = useUpdateCacheAnswerMutation();
  const deleteCategoryCache = useDeleteAvatarCategoryCacheMutation();
  const [value, setValue] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleUpdate = () => {
    if (!value) return;
    update.mutate(
      {
        ...cache,
        answer: value,
      },
      {
        onSuccess: () => {
          setValue(null);
        },
      },
    );
  };
  const handleDelete = () => {
    deleteCategoryCache.mutate(selectedCategory, {
      onSuccess: () => {
        setDeleteDialog(false);
        selectedCategory.cacheId = null;
      },
    });
  };
  const handleClickDeleteDialog = () => {
    setDeleteDialog(true);
  };

  return (
    <AccordionDetails>
      {!cache ? (
        <Typography variant="h7" ml={1}>
          등록된 캐시가 없습니다.
        </Typography>
      ) : (
        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <Chip label="캐시" />
            <Typography variant="h6" ml={1}>
              {value ?? cache?.question ?? ''}
            </Typography>
          </Box>
          <StyledInput
            placeholder="질문 답변 작성"
            multiline
            // rows={3}
            value={value ?? cache?.answer ?? ''}
            // onChange={handleChange}
          ></StyledInput>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="8px"
          >
            <DeleteButton onClick={handleClickDeleteDialog}>삭제</DeleteButton>
          </Box>
          <DeleteConfirmDialog
            title={'삭제하시겠습니까?'}
            open={deleteDialog}
            handleClose={() => setDeleteDialog(false)}
            handleDelete={handleDelete}
            pending={deleteCategoryCache.isPending}
            maxWidth={'xs'}
          />
        </Box>
      )}
    </AccordionDetails>
  );
};
