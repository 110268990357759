import { styled } from '@mui/material/styles';

export const Loader = styled('span')(() => ({
  width: 20,
  height: 20,
  border: '2px solid',
  borderColor: '#4a4c50 transparent',
  borderRadius: '50%',
  display: 'inline-block',
  animation: 'rotation 1s linear infinite',
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
