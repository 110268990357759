import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { IconButton, Tooltip } from '@mui/material';

import { useUpdateActivatedMutation } from 'react-query/mutations/managers/useUpdateActivatedMutation';

export const ChangeActivateTooltip = ({ id, activated }) => {
  const mutation = useUpdateActivatedMutation();

  return (
    <Tooltip
      disableInteractive={mutation.isPending}
      title="계정 활성화/비활성화"
      onClick={() => mutation.mutate(id)}
    >
      <IconButton>
        {activated ? <PersonRoundedIcon /> : <PersonOffRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
};
