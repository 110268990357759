import { useState } from 'react';

import { DeleteConfirmDialog } from '../../../../components/Dialog/DeleteConfirmDialog';
import { useDeleteAvatarMissingQuestionMutation } from '../../../../react-query/mutations/avatars/useDeleteAvatarMissingQuestionMutation';
import { MissingQuestionContext } from './MissingQuestionContext';

import { useDebouncedValue } from 'rooks';

export const MissingQuestionHandler = ({
  disabled,
  page,
  setPage,
  checkedQuestion,
  setCheckedQuestion,
  checkedCache,
  setCheckedCache,
  setAnswer,
  children,
}) => {
  const [showAll, setShowAll] = useState(false);
  const handleShowAllCheckbox = (checked) => {
    setCheckedQuestion([]);
    setCheckedCache({ cacheId: null });
    setAnswer('');
    setSearch('');
    setShowAll(checked);
  };

  const handleChangePage = (_, page) => {
    setCheckedQuestion([]);
    setCheckedCache({ cacheId: null });
    setAnswer('');
    setSearch('');
    setPage(page);
  };

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const isCheckedQuestion = (question) =>
    checkedQuestion.length > 0 &&
    checkedQuestion.some(
      (obj) => obj.missingQuestionNum === question.missingQuestionNum,
    );

  const toggleQuestion = (question) => {
    if (isCheckedQuestion(question)) {
      const updatedArr = checkedQuestion.filter(
        (obj) => obj.missingQuestionNum !== question.missingQuestionNum,
      );
      setCheckedQuestion(updatedArr);

      setCheckedCache({ cacheId: null });
      setAnswer('');
      setSearch('');
    } else {
      setCheckedQuestion((prevState) => {
        const newStateArray = [...prevState];
        newStateArray[newStateArray.length] = {
          ...newStateArray[newStateArray.length],
          missingQuestionNum: question.missingQuestionNum,
          avatarId: question.avatarId,
          createdDatetime: question.createdDatetime,
          handleType: question.handleType,
          handled: question.handled,
          handledAnswer: question.handledAnswer,
          handledCacheId: question.handledCacheId,
          processed: question.processed,
          question: question.question,
          show: question.show,
          updatedDatetime: question.updatedDatetime,
        };
        return newStateArray;
      });
      if (question.handled) {
        if (question.handleType === 'Cache') {
          setCheckedCache({
            cacheId: question.handledCacheId,
            answer: question.handledAnswer,
          });
          setSearch(question.question.split(' ')[0]);
          setAnswer('');
        } else {
          setCheckedCache({ cacheId: null });
          setAnswer(question.handledAnswer);
          setSearch('');
        }
      } else {
        setCheckedCache({ cacheId: null });
        setSearch('');
        setAnswer('');
      }
    }
  };

  const isCheckedCache = (cache) => cache.cacheId === checkedCache?.cacheId;
  const toggleCache = (cache) => {
    if (cache.cacheId === checkedCache?.cacheId) {
      setCheckedCache({ cacheId: null });
    } else {
      setCheckedCache(cache);
    }
  };

  const deleteMutation = useDeleteAvatarMissingQuestionMutation();
  const handleDelete = (data) => {
    deleteMutation.mutate(data, {
      onSuccess: () => {
        setCheckedQuestion([]);
        setCheckedCache({ cacheId: null });
        setSearch('');
        setAnswer('');
        handleClose();
      },
    });
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MissingQuestionContext.Provider
      value={{
        disabled,
        page,
        handleChangePage,
        isCheckedQuestion,
        toggleQuestion,
        checkedQuestion,
        isCheckedCache,
        toggleCache,
        showAll,
        handleShowAllCheckbox,
        search,
        handleChangeSearch,
        debouncedSearch,
        handleOpen,
      }}
    >
      {children}
      {open && checkedQuestion.length > 0 && (
        <DeleteConfirmDialog
          open={open}
          pending={deleteMutation.isPending}
          handleClose={handleClose}
          handleDelete={() => handleDelete(checkedQuestion)}
        />
      )}
    </MissingQuestionContext.Provider>
  );
};
