import { useState } from 'react';

import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import { IconButton, Tooltip } from '@mui/material';

import { ChangePasswordDialog } from 'components/Dialog/ChangePasswordDialog';

export const ChangePasswordTooltip = ({ id }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <Tooltip title="비밀번호 변경" onClick={handleOpenDialog}>
        <IconButton>
          <LockResetRoundedIcon />
        </IconButton>
      </Tooltip>
      {openDialog && (
        <ChangePasswordDialog
          id={id}
          open={openDialog}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
