import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Title } from 'pages/user/features/question/components/items/Title';
import { ChatMain } from 'pages/user/features/question/components/Sections/ChatMain';
import { Header } from 'pages/user/features/question/components/Sections/Header';
import {
  BottomToggleButton,
  Container,
  Contents,
  ToggleButton,
} from 'pages/user/features/question/components/styled';
import ChatProvider from './components/context/ChatProvider';
import { AsideMenu } from './components/Sections/AsideMenu';
import { useQuery } from '@tanstack/react-query';
import { avatarsQueries } from '../../../../react-query/factory/avatarsQueries';
import { useParams } from 'react-router-dom';

export const Question = () => {
  const { avatarId } = useParams();
  const [prompt, setPrompt] = useState('');
  const handleChangePrompt = (event) => {
    setPrompt(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const [usePinnedAnswer, setUsePinnedAnswer] = useState(false);
  const [bottomMenuOpen, setBottomMenuOpen] = useState(true);
  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClickBottomMenuOpen = () => {
    setBottomMenuOpen(!bottomMenuOpen);
  };

  useEffect(() => {
    const bodyHeight = document.body.scrollHeight;
    window.scrollTo(0, bodyHeight);
  }, [bottomMenuOpen]);

  const simpleProfile = useQuery(avatarsQueries.simpleProfile(avatarId));
  useEffect(() => {
    if (simpleProfile.data)
      setUsePinnedAnswer(!simpleProfile?.data?.useAutoPinnedAnswer);
  }, [simpleProfile.data]);

  if (simpleProfile.isLoading || simpleProfile.isError) return false;
  return (
    <ChatProvider
      prompt={prompt}
      handleChangePrompt={handleChangePrompt}
      simpleProfile={simpleProfile}
    >
      <Header
        setBottomMenuOpen={setBottomMenuOpen}
        usePinnedAnswer={usePinnedAnswer}
        setUsePinnedAnswer={setUsePinnedAnswer}
        useAutoPinnedAnswer={simpleProfile?.data?.useAutoPinnedAnswer}
      />
      <Container>
        {simpleProfile?.data?.useAutoPinnedAnswer && (
          <Box
            sx={{
              position: 'relative',
              width: open ? 290 : 0,
              height: 'calc(100vh - 64px)',
            }}
          >
            {open && <AsideMenu />}
            <Box
              sx={{
                position: 'relative',
                width: open ? 290 : 0,
                height: 'calc(100vh - 64px)',
              }}
            >
              <ToggleButton
                sx={{
                  left: open ? 290 : 0,
                }}
                color="neutral"
                variant="outlined"
                onClick={handleClickOpen}
              >
                {open ? '닫기' : '열기'}
              </ToggleButton>
            </Box>
          </Box>
        )}
        <Contents
          sx={{
            backgroundColor: usePinnedAnswer ? '#ECF0EF' : '#f6f9fe',
          }}
        >
          {simpleProfile?.data?.useAutoPinnedAnswer && <Title />}
          <ChatMain
            usePinnedAnswer={usePinnedAnswer}
            bottomMenuOpen={bottomMenuOpen}
            setBottomMenuOpen={setBottomMenuOpen}
          />
          {usePinnedAnswer && (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: bottomMenuOpen ? 30 : 0,
              }}
            >
              <BottomToggleButton
                sx={{
                  left: 'calc(50% - 100px)',
                }}
                color="neutral"
                variant="outlined"
                onClick={handleClickBottomMenuOpen}
              >
                {bottomMenuOpen ? '고정 답변 닫기' : '고정 답변 열기'}
              </BottomToggleButton>
            </Box>
          )}
        </Contents>
      </Container>
    </ChatProvider>
  );
};
