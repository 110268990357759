import { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useOutletContext, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { recordsQueries } from '../../../../react-query/factory/recordsQueries';
import LoadingSpinner from '../../../../ui/LoadingSpinner';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
    },
  },
};

export default function AllVisitorChart({ period, date }) {
  const { candidateId } = useParams();
  const [year, month] = date.split('-');

  const parentRef = useRef();
  const chartRef = useRef();
  const params = {
    period,
    year,
    month,
    id: candidateId,
  };
  useEffect(() => {
    const handleResize = () => {
      if (parentRef && chartRef) {
        chartRef.current.reset();
        chartRef.current.resize(parentRef.current.offsetWidth, 800);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const records = useQuery(recordsQueries.avatar(params));
  if (records.isLoading)
    return <LoadingSpinner isLoading={records.isLoading} />;
  if (records.isError) return null;
  return (
    <div ref={parentRef}>
      <Bar ref={chartRef} options={options} data={records.data} />
    </div>
  );
}
