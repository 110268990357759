import { Box, FormControl, Select, Typography } from '@mui/material';

import { BoxComponent } from 'ui/dialog-items/BoxComponent';
import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogInput } from 'ui/dialog-items/DialogInput';
import { DialogItemLabel } from 'ui/dialog-items/DialogItemLabel';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { CommonSwitch } from '../styledMUI/CommonSwitch';
import { AvatarCacheBox } from '../../pages/avatars/components/pinnedQuestion/AvatarCacheBox';
import { useMutation } from '@tanstack/react-query';
import {
  createAvatarPinnedQuestionCache,
  updateAvatarPinnedQuestionCache,
} from '../../api-legacy/avatars/questions';
import { TOAST_MESSAGE } from '../../constants/toast-message';
import { queryClient } from '../../react-query/query-client';
import { PinnedQuestionContext } from '../../pages/avatars/components/pinnedQuestion/PinnedQuestionContext';

export const AddCacheDialog = ({
  candidateId,
  title,
  open,
  handleClose,
  selectedCategory,
}) => {
  const [useSimilarQuestion, setUseSimilarQuestion] = useState(false);
  const { selectedCache } = useContext(PinnedQuestionContext);
  const [cacheData, setCacheData] = useState({
    avatarId: candidateId,
    categoryId: selectedCategory.categoryId,
    question: selectedCategory.question,
    answer: '',
    handledCacheId: '',
    handledAnswer: '',
    handled: false,
    handleType: 'Direct',
    processed: false,
  });

  const { mutate: createAvatarCacheCategoryMutation, isPending } = useMutation({
    mutationFn: async () => {
      const handledQuestion = {
        ...cacheData,
        handled: true,
        // handleType: answer.trim().length ? 'Direct' : 'Cache',
        // handledCacheId: answer.trim().length ? null : checkedCache.cacheId,
        // handledAnswer: answer.trim().length ? answer : checkedCache.answer,
      };
      await createAvatarPinnedQuestionCache(handledQuestion);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
      handleClose();
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['Pinned Questions', candidateId]);
    },
  });

  const { mutate: updateAvatarCacheCategoryMutation } = useMutation({
    mutationFn: async () => {
      const handledQuestion = {
        ...cacheData,
        handled: true,
        handledCacheId: selectedCache?.cacheId,
        handleType: 'Cache',
        // handledAnswer: answer.trim().length ? answer : checkedCache.answer,
      };
      await updateAvatarPinnedQuestionCache(handledQuestion);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
      handleClose();
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['Pinned Questions', candidateId]);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCacheData({
      ...cacheData,
      [name]: value,
    });
  };

  const changeUseSimilarQuestion = () => {
    setUseSimilarQuestion((prevState) => !prevState);
  };

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogHeader title={title} handleClose={handleClose} />
      <BoxComponent>
        <FormControl>
          <DialogItemLabel>상위 카테고리</DialogItemLabel>
          <DialogInput
            // onChange={handleChangeValue}
            name="value"
            value={selectedCategory.question || ''}
            placeholder="제목"
            disabled
          />
          <Box display="flex" alignItems="center">
            <Typography variant="body2">캐시정보</Typography>
            <CommonSwitch
              // disabled={
              //   files.isLoading || isLoadingConfig || usableMutation.isPending
              // }
              checked={useSimilarQuestion}
              onClick={changeUseSimilarQuestion}
            />
            <Typography variant="body2">
              {useSimilarQuestion ? '직접입력' : '유사질문에서 검색'}
            </Typography>
          </Box>
          {!useSimilarQuestion ? (
            <>
              <DialogInput
                name={'question'}
                // readOnly={mutation.isPending}
                placeholder="질문을 입력해주세요."
                value={cacheData.question || ''}
                onChange={handleChange}
              />
              <DialogInput
                name={'handledAnswer'}
                value={cacheData.handledAnswer || ''}
                placeholder="답변을 입력해주세요."
                fullWidth
                multiline
                rows={10}
                onChange={handleChange}
              />
            </>
          ) : (
            <AvatarCacheBox />
          )}
        </FormControl>
        <Box
          sx={{
            '& span': { color: '#CA4242' },
          }}
          display="flex"
          justifyContent="flex-end"
          width="100%"
        >
          {/*<span>{message}</span>*/}
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={handleClose}>취소</CancelButton>
            <SubmitButton
              // disabled={!characters.data || mutation.isPending}
              onClick={
                useSimilarQuestion
                  ? updateAvatarCacheCategoryMutation
                  : createAvatarCacheCategoryMutation
              }
            >
              등록
            </SubmitButton>
          </Box>
        </Box>
      </BoxComponent>
    </DialogWrapper>
  );
};
