import { useState } from 'react';
import toast from 'react-hot-toast';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, TablePagination, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import NoRowsComponent from 'ui/datagrid/NoRowsComponent';
import LoadingSpinner from 'ui/LoadingSpinner';

import { updateManagerActivated } from 'api/managers/updateManagerActivated';

import { AddManagerDialog } from '../../components/Dialog/AddManagerDialog';
import { ChangePasswordDialog } from '../../components/Dialog/ChangePasswordDialog';
import { TOAST_MESSAGE } from '../../constants/toast-message';
import { usePaginationProps } from '../../hooks/usePaginationProps';
import { managersQueries } from '../../react-query/factory/managersQueries';
import { DataBox } from '../../ui/datagrid';
import { DeleteManagerTooltip } from './DeleteManagerTooltip';
import Setting from './Setting';

import { ReactComponent as UserProfileAddIcon } from 'assets/images/UserProfileAddIcon.svg';
import { CommonIconButton } from 'components/styledMUI/CommonIconButton';
import { CommonInputBase } from 'components/styledMUI/CommonInputBase';
import { CommonSearch as Search } from 'components/styledMUI/CommonSearch';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { DataGridBox } from 'components/styledMUI/DataGridBox';
import { Loader } from 'components/styledMUI/Loader';
import { RootBox } from 'components/styledMUI/RootBox';
import { SearchIconWrapper } from 'components/styledMUI/SearchIconWrapper';
import { useDebouncedValue } from 'rooks';

const PageTitle = styled('h3')(({ theme }) => ({
  margin: '0 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));

export default function AccountManagement(props) {
  const queryClient = useQueryClient();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, hotUpdateDebouncedSearch] = useDebouncedValue(
    searchText,
    500,
  );
  const [selectId, setSelectId] = useState();
  const [addUser, setAddUser] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [isPendingId, setIsPendingId] = useState('');

  const handleClickAddUserDialog = () => {
    setAddUser(true);
  };
  const handleCloseAddUserDialog = () => {
    setAddUser(false);
  };

  const handleOpenPasswordDialog = (id) => {
    setOpenPassword(true);
    setSelectId(id);
  };

  const handleClosePasswordDialog = () => {
    setOpenPassword(false);
  };
  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const pagination = usePaginationProps();

  const managers = useQuery(
    managersQueries.list(
      'Team',
      localStorage.getItem('id'),
      pagination.rowsPerPage,
      debouncedSearch,
      pagination.page + 1,
    ),
  );
  const { mutate: updateManagerActivatedMutation } = useMutation({
    mutationFn: (managerId) => updateManagerActivated(managerId),
    onSuccess: () => {
      setIsPendingId('');
      toast(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onMutate: (data) => {
      setIsPendingId(data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: managersQueries.all(),
      });
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
  });

  const columns = [
    // {
    // 	field: 'id',
    // 	headerName: 'id',
    // 	width: 100,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
    },
    { field: 'loginId', headerName: '아이디', flex: 1 },
    {
      field: 'management',
      headerName: '관리',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box display="flex">
            <Tooltip
              title="계정 활성화/비활성화"
              onClick={() => {
                if (!isPendingId) {
                  updateManagerActivatedMutation(params.row.id);
                }
              }}
            >
              <IconButton>
                {isPendingId === params.row.id ? (
                  <Loader />
                ) : params.row.activated ? (
                  <PersonRoundedIcon />
                ) : (
                  <PersonOffRoundedIcon />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="비밀번호 변경">
              <IconButton
                onClick={() => {
                  handleOpenPasswordDialog(params.row.id);
                }}
              >
                <LockResetRoundedIcon />
              </IconButton>
            </Tooltip>
            <DeleteManagerTooltip id={params.row.id} />
          </Box>
        );
      },
    },
  ];

  return (
    <RootBox>
      <Setting />
      <ContentBox component="main" maxWidth="xl">
        <PageTitle>계정 관리</PageTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CommonIconButton onClick={handleClickAddUserDialog}>
            <UserProfileAddIcon />
            계정 추가
          </CommonIconButton>
          <Search>
            <SearchIconWrapper>
              <SearchRoundedIcon />
            </SearchIconWrapper>
            <CommonInputBase
              value={searchText}
              onChange={handleChangeSearchText}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  hotUpdateDebouncedSearch();
                }
              }}
              placeholder="계정 검색"
            />
          </Search>
        </Box>

        {/*  //검색   */}

        {managers.isLoading ? (
          <LoadingSpinner isLoading={true} />
        ) : (
          <DataBox
            data={managers.data.items}
            columns={columns}
            total={managers.data.totalItems}
            pagination={pagination}
          />
        )}
      </ContentBox>
      {addUser && (
        <AddManagerDialog
          open={addUser}
          handleClose={handleCloseAddUserDialog}
          title={'계정 추가'}
          managerType={'Team'}
        />
      )}

      {openPassword && (
        <ChangePasswordDialog
          open={openPassword}
          handleClose={handleClosePasswordDialog}
          id={selectId}
          title={'비밀번호 변경'}
        />
      )}
    </RootBox>
  );
}
