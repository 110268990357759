import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import { ContentsTitle } from 'pages/user/features/question/components/styled';

import { FloatingButtonList } from '../blocks/FloatingButtonList';

export const Title = () => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  useEffect(() => {
    const scrollEvent = () => {
      const scrollY = window.scrollY;

      const direction = scrollY < lastScrollY;
      setLastScrollY(scrollY);
      setIsScrollingUp(direction);
    };
    const debounceScroll = setInterval(() => {
      window.addEventListener('scroll', scrollEvent);
    }, 500);
    return () => {
      clearInterval(debounceScroll);
      window.removeEventListener('scroll', scrollEvent);
    };
  }, [lastScrollY]);

  return (
    <ContentsTitle
      sx={{
        visibility: isScrollingUp ? 'visible' : 'hidden',
        position: isScrollingUp ? 'sticky' : 'static',
        top: 64,
        zIndex: 50,
      }}
    >
      <Typography variant="h5">User</Typography>
      {/*<Typography variant="body1">*/}
      {/*  <IconMessageSquareTyping /> Waiting user input*/}
      {/*</Typography>*/}
      <FloatingButtonList />
    </ContentsTitle>
  );
};
