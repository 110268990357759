import { createContext, memo, useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryTextQuestion } from 'api/users/queryTextQuestion';
import { API } from '../../../../../../constants/api-endpoints';
import { defaultInstance } from '../../../../../../lib/axios/instance';
import { Root } from '../styled';

export const ChatContext = createContext();

const ChatProvider = ({
  prompt,
  handleChangePrompt,
  simpleProfile,
  children,
}) => {
  const { avatarId } = useParams();
  const ipAuthorization = useQuery({
    queryKey: ['ip authorization', avatarId],
    queryFn: async () => {
      const { data } = await defaultInstance.get(`${API.USERS}/ip`, {
        params: { avatarId },
      });
      return data;
    },
    staleTime: Infinity,
    meta: {
      errorMessage: '',
    },
  });

  const [questions, setQuestions] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [frequent, setFrequent] = useState([]);
  const initQuestion = () => {
    setQuestions([]);
  };
  const handleAddQuestion = useCallback((data) => {
    setQuestions((prev) => [...prev, data]);
  }, []);
  const handleAddAnswer = useCallback((data) => {
    setQuestions((prev) =>
      prev.map((question) =>
        question.timestamp === data.timestamp ? data : question,
      ),
    );
  }, []);
  const handleAddChatHistory = useCallback((data) => {
    setChatHistory((prev) => [...prev, data]);
  }, []);
  const handleChangeDocuments = useCallback((data) => {
    setDocuments(data);
  }, []);
  const handleChangeFrequent = useCallback((data) => {
    setFrequent(data);
  }, []);
  const scrollToBottom = useCallback(() => {
    if (!questions.length) return;
    const bodyHeight = document.body.scrollHeight;
    window.scrollTo(0, bodyHeight);
  }, [questions]);
  const handleRequestQuery = useCallback(
    async (data) => {
      handleAddQuestion(data);
      const params = {
        avatarId,
        question: data.content,
        userTypeId: null,
        initPrompt:
          prompt ||
          '질문에 대해 가능한 자세하고 보기 좋게 문단을 나눠서 대답해주세요.',
        userQuestionHistoryIds:
          chatHistory.length > 10
            ? chatHistory.slice(chatHistory.length - 10, chatHistory.length - 1)
            : chatHistory,
      };
      try {
        const {
          answer,
          userQuestionHistoryId,
          mostFrequentQnA,
          relevantDocuments,
        } = await queryTextQuestion(params);
        handleAddChatHistory(userQuestionHistoryId);
        handleChangeFrequent(mostFrequentQnA);
        handleChangeDocuments(relevantDocuments);
        handleAddAnswer({ ...data, answer, userQuestionHistoryId });
      } catch (error) {
        handleChangeFrequent([]);
        handleChangeDocuments([]);
        handleAddAnswer({
          ...data,
          answer: '오류가 발생했습니다.\n잠시 후에 다시 시도해주세요.',
        });
      }
    },
    [
      avatarId,
      chatHistory,
      handleAddAnswer,
      handleAddChatHistory,
      handleAddQuestion,
      handleChangeDocuments,
      handleChangeFrequent,
      prompt,
    ],
  );

  const value = useMemo(
    () => ({
      prompt,
      handleChangePrompt,
      questions,
      handleAddQuestion,
      documents,
      frequent,
      handleRequestQuery,
      simpleProfile,
      initQuestion,
    }),
    [
      documents,
      frequent,
      handleAddQuestion,
      handleChangePrompt,
      prompt,
      questions,
      handleRequestQuery,
      simpleProfile,
      initQuestion,
    ],
  );

  if (ipAuthorization.isLoading) return null;
  if (ipAuthorization.isError) {
    return <Navigate to={'/'} />;
  }
  if (ipAuthorization.data === false) {
    toast.error('허용되지 않은 접근입니다.');
    return <Navigate to={'/'} />;
  }

  return (
    <ChatContext.Provider value={value}>
      <Root ref={scrollToBottom}>{children}</Root>
    </ChatContext.Provider>
  );
};

export default memo(ChatProvider);
