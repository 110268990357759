import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { charactersQueries } from 'react-query/factory/charactersQueries';

import { createCharacter } from 'api/characters/createCharacter';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useCreateCharacterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      await createCharacter(params);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.CREATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.CREATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: charactersQueries.all(),
      });
    },
  });
};
