import { useQuery } from '@tanstack/react-query';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, Container, Toolbar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import { MATCHED_AVATARS } from 'constants/queryKeys';

import { Logout } from '../../ui/layout/Logout';
import { CandidateCard } from './components/CandidateCard';

import { getMatchedAvatars } from 'api-legacy/teams/getMatchedAvatars';
import { RootBox } from 'components/styledMUI/RootBox';

const IconButton = styled(Button)(() => ({
  minWidth: 'auto',
  color: '#0F0F10',
  paddingLeft: 0,
  '&:hover': {
    color: '#0F0F10',
    backgroundColor: 'transparent',
  },
}));

const TopbarIconButton = styled(Button)(() => ({
  minWidth: 'auto',
  margin: '0 8px 0 16px',
  // color:'#0F0F10',
  '& svg': {
    width: 26,
    height: 26,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const NameTag = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#27c3e2',
  color: '#fff',
  borderRadius: 10,
  marginRight: 12,
  padding: '16px 12px',
  fontWeight: 600,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  borderBottom: '1px solid #e8edf7',
  borderLeft: '1px solid #e8edf7',
  color: '#0F0F10',
  '& .MuiTypography-h6': {
    fontSize: '1.375rem',
    fontWeight: '700',
    marginLeft: 24,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const ContainerBox = styled(Container)(() => ({
  flexGrow: 1,
  padding: 0,
  margin: '94px 32px',
  borderRadius: 16,
  // backgroundColor: '#fff',
  // boxShadow: '0 0 10px rgb(182 182 182 / 40%)',
  boxSizing: 'border-box',
  '@media all and (min-width: 600px)': {
    padding: 40,
  },
}));

const BoxStyled = styled(Box)(() => ({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& p': {
    marginBottom: 40,
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500,
  },
}));
export default function CandidateSelection() {
  const managerId = localStorage.getItem('id');

  const {
    data: matchedAvatarsData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [MATCHED_AVATARS],
    queryFn: async () => await getMatchedAvatars(managerId),
  });

  if (isLoading) return <></>;
  return (
    <RootBox>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6" noWrap component="div">
                담당 목록
              </Typography>
            </Box>
            <Box display="flex" height={'100%'}>
              <NameTag>Personal</NameTag>
              <Logout />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ContainerBox component="main" maxWidth="xl">
        <BoxStyled>
          {isSuccess && matchedAvatarsData.length ? (
            matchedAvatarsData.map((candidate) => (
              <CandidateCard key={candidate.id} candidate={candidate} />
            ))
          ) : (
            <Typography>관리할 캐릭터가 없습니다.</Typography>
          )}
        </BoxStyled>
      </ContainerBox>
    </RootBox>
  );
}
