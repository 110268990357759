import { memo, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, Typography } from '@mui/material';

import LoadingSpinner from 'ui/LoadingSpinner';

import {
  AiChat,
  IconBox,
  IconButtonStyle,
} from 'pages/user/features/question/components/styled';

import { avatarsQueries } from 'react-query/factory/avatarsQueries';
import { LikeHateGroup } from './LikeHateGroup';

import MarkdownPreview from '@uiw/react-markdown-preview';
import { ReactComponent as FileCopyActiveIcon } from 'assets/images/FileCopyActiveIcon.svg';
import { ReactComponent as IconSoundOffFlat } from 'assets/images/IconSoundOffFlat.svg';
import { ReactComponent as IconSoundOnFlat } from 'assets/images/IconSoundOnFlat.svg';
import { ImageDialog } from 'components/Dialog/ImageDialog';
import { ReactComponent as PlantAvatarIcon } from 'assets/images/PlantAvatarIcon.svg';

const AiAnswer = ({ answer, loading, index, userQuestionHistoryId }) => {
  const { avatarId } = useParams();
  const [playing, setPlaying] = useState();
  const [largeImage, setLargeImage] = useState();
  const handleCloseImageDialog = () => {
    setLargeImage(null);
  };
  const simpleProfile = useQuery(avatarsQueries.simpleProfile(avatarId));
  const requestVoice = useCallback(async () => {
    if (!simpleProfile.data || !answer || loading) return;
  }, [answer, simpleProfile.data]);

  const handleCopyAnswer = async () => {
    try {
      await navigator.clipboard.writeText(answer);
      toast.success('클립보드에 답변이 복사되었습니다.');
    } catch (e) {
      toast.error('복사에 실패하였습니다');
    }
  };

  const replacedAnswer = answer
    ? answer.replace('\n', '<br/>')
    : '답변을 요청했습니다.';

  const handlePlayAudio = () => {
    if (playing === index) {
      setPlaying(null);
      window.speechSynthesis.cancel();
      return;
    }

    const replacedAnswer = answer
      .replaceAll(/<[^>]*>/g, '') // HTML 태그 대체
      .replaceAll(/!\[(.*?)\]\(.*?\)/g, '$1 이미지')
      .replaceAll(/[#+*`~\-+>!()_]/g, ' ') // 일반 특수문자 대체
      .replaceAll(/(\*\*|__)/g, ' ') // 굵게 표시 대체
      .replaceAll(/(\*|_)/g, ' ') // 기울임 표시 대체
      .replaceAll(/!\[.*?\]\(.*?\)/g, ' ') // 이미지 대체
      .replaceAll(/\[.*?\]\(.*?\)/g, ' ') // 링크 대체
      .split('\n')
      .join('  ');

    // console.log('replacedAnswer', replacedAnswer);
    // console.log('answer', answer);

    const utterance = new SpeechSynthesisUtterance(replacedAnswer);

    utterance.lang = 'ko-KR';

    utterance.onend = () => {
      setPlaying(null);
    };

    window.speechSynthesis.speak(utterance);

    setPlaying(index);
  };
  return (
    <>
      <AiChat ref={requestVoice}>
        <Typography
          sx={{
            height: 40,
          }}
          variant="subtitle1"
        >
          <PlantAvatarIcon style={{ width: 40, height: 40 }} />
          <IconButtonStyle disabled={loading} onClick={handlePlayAudio}>
            {playing === index ? <IconSoundOnFlat /> : <IconSoundOffFlat />}
          </IconButtonStyle>
        </Typography>
        <Box
          ref={(node) => {
            if (!node) return;
            const images = node.querySelectorAll('img');
            images.forEach((image) => {
              image.addEventListener('click', (event) => {
                setLargeImage(event.target.src);
              });
            });
          }}
          sx={{
            maxWidth: '80%',
            background: '#dfe8ef',
            borderRadius: 2,
            overflow: 'hidden',

            '& img': {
              backgroundColor: '#dfe8ef !important',
              marginTop: '16px',
              objectFit: 'scale-down',
              width: '100%',
              maxHeight: '240px',
            },
            '& img:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <MarkdownPreview
            style={{
              padding: '14px 16px',
              textAlign: 'left',
              boxSizing: 'border-box',
              color: 'inherit',
              background: '#dfe8ef',
            }}
            source={replacedAnswer}
          />
          <LoadingSpinner isLoading={loading} />
        </Box>
        <IconBox>
          <LikeHateGroup userQuestionHistoryId={userQuestionHistoryId} />
          <IconButtonStyle disableripple="true" onClick={handleCopyAnswer}>
            <FileCopyActiveIcon />
          </IconButtonStyle>
        </IconBox>
      </AiChat>
      {largeImage && (
        <ImageDialog
          open={!!largeImage}
          imageSrc={largeImage}
          handleClose={handleCloseImageDialog}
        />
      )}
    </>
  );
};

export default memo(AiAnswer);
