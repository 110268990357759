import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const createCharacter = async (params) => {
  const formData = new FormData();
  formData.append('file', params.image);
  formData.append('object', JSON.stringify(params.inputData));

  await tokenInstance.put(`${API.CHARACTERS}`, formData, MULTIPART_HEADER);
};
