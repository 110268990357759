import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TOAST_MESSAGE } from 'constants/toast-message';
import { categoryQueries } from '../../factory/categoryQueries';
import { deleteAvatarCategoryCache } from '../../../api/category/deleteAvatarCategoryCache';

export const useDeleteAvatarCategoryCacheMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => deleteAvatarCategoryCache(params),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.DELETE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.DELETE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: categoryQueries.all() });
    },
  });
};
