import { memo } from 'react';

import AiAnswer from 'pages/user/features/question/components/items/AiAnswer';
import UserQuestion from 'pages/user/features/question/components/items/UserQuestion';
import { ChatBox } from 'pages/user/features/question/components/styled';

const ChatBlock = ({ answer, content, index, userQuestionHistoryId }) => (
  <ChatBox>
    <UserQuestion question={content} />
    <AiAnswer
      answer={answer}
      loading={!answer}
      index={index}
      userQuestionHistoryId={userQuestionHistoryId}
    />
  </ChatBox>
);

export default memo(ChatBlock);
