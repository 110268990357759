import { useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';

import { MATCHED_AVATARS } from 'constants/queryKeys';

import { doLogout } from '../../api/auth/do-logout';

import { getMatchedAvatars } from 'api-legacy/teams/getMatchedAvatars';
import { ReactComponent as MonitoringIcon } from 'assets/images/MonitoringIcon.svg';
import { ReactComponent as MydataIcon } from 'assets/images/MydataIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/images/ProfileIcon.svg';
import { ReactComponent as SearchChatIcon } from 'assets/images/SearchChatIcon.svg';
import { ReactComponent as SettingIcon } from 'assets/images/SettingIcon.svg';
import ShareDialog from 'components/Dialog/ShareDialog';
import { Logo } from 'components/Logo';
import { RootBox } from 'components/styledMUI/RootBox';

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: '1px solid #e8edf7',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const CommonSelect = styled(Select)(() => ({
  border: '1px solid #e8edf7',
  backgroundColor: '#fff',
  borderRadius: 10,
  '& fieldset': {
    border: '0 none',
  },
  '& svg': {
    color: '#869592',
  },
  '& .MuiSelect-select': {
    padding: '12px 14px',
  },
}));

const IconButton = styled(Button)(() => ({
  minWidth: 'auto',
  color: '#0F0F10',
  paddingLeft: 0,
  '&:hover': {
    color: '#0F0F10',
    backgroundColor: 'transparent',
  },
}));

const TopbarIconButton = styled(Button)(() => ({
  minWidth: 'auto',
  margin: '0 8px 0 16px',
  // color:'#0F0F10',
  '& svg': {
    width: 26,
    height: 26,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const NameTag = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#27c3e2',
  color: '#fff',
  borderRadius: 10,
  marginRight: 12,
  padding: '4px 12px',
  fontWeight: 600,
}));

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  borderBottom: '1px solid #e8edf7',
  borderLeft: '1px solid #e8edf7',
  color: '#0F0F10',
  '& .MuiTypography-h6': {
    fontSize: '1.375rem',
    fontWeight: '700',
    marginLeft: 24,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  minHeight: '100vh',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  '& .MuiListItemText-root span': {
    fontWeight: 700,
  },
}));

const drawerWidth = 240;

const menuItem = [
  {
    icon: <MydataIcon />,
    title: 'My 데이터',
    link: '/candidate/data',
    active: false,
  },
  {
    icon: <MonitoringIcon />,
    title: '모니터링',
    link: '/candidate/monitoring',
    active: false,
  },
  // {
  // 	icon: <ChatIcon />,
  // 	title: '테스트',
  // 	link: '/candidate/preview',
  // 	active: false,
  // },
  {
    icon: <SearchChatIcon />,
    title: '고정 답변 관리',
    link: '/candidate/pinned-question',
    active: false,
  },
  {
    icon: <SearchChatIcon />,
    title: '누락 질문 관리',
    link: '/candidate/missing-question',
    active: false,
  },
  {
    icon: <SearchChatIcon />,
    title: 'AI 질문 답변 목록',
    link: '/candidate/ai-question',
    active: false,
  },
  {
    icon: <ProfileIcon />,
    title: '프로필',
    link: '/candidate/profile',
    active: true,
  },
  {
    icon: <SettingIcon />,
    title: '설정',
    link: '/candidate/preferences',
    active: false,
  },
];

export default function CandidateLayout() {
  const theme = useTheme();
  const managerId = localStorage.getItem('id');
  const managerType = localStorage.getItem('type');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { candidateId } = useParams();
  const [open, setOpen] = useState(true);
  const [, setOpenTooltip] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  const {
    data: matchedAvatarsData,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: [MATCHED_AVATARS],
    queryFn: async () => await getMatchedAvatars(managerId),
  });

  const logoutMutation = useMutation({
    mutationFn: doLogout,
    onSuccess: () => {
      localStorage.clear();
      window.location.replace('/login');
    },
    onError: () => {},
  });

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleChangeCandidate = (event) => {
    const candidateId = event.target.value;
    navigate(`/candidate/data/${candidateId}`);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (isLoading) return <></>;
  return (
    <RootBox>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
          >
            <Box display="flex" alignItems="center">
              <Logo />
            </Box>
            <Box display="flex">
              <NameTag>Character</NameTag>
              {isSuccess && (
                <CommonSelect
                  value={candidateId}
                  onChange={handleChangeCandidate}
                  IconComponent={() => (
                    <Box style={{ marginRight: 10, display: 'flex' }}>
                      <ExpandMoreRoundedIcon />
                    </Box>
                  )}
                  displayEmpty
                >
                  {matchedAvatarsData.map((avatar) => (
                    <MenuItem key={avatar.id} value={avatar.id}>
                      {avatar.name}
                    </MenuItem>
                  ))}
                </CommonSelect>
              )}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <TopbarIconButton
                  onClick={() => setShareDialogOpen(true)}
                  disableRipple
                >
                  <ShareTwoToneIcon />
                </TopbarIconButton>
              </ClickAwayListener>
              {managerType === 'Team' && (
                <Tooltip
                  title="관리자 페이지로 이동"
                  placement="bottom"
                  onClick={() => {
                    navigate('/team/management');
                  }}
                >
                  <TopbarIconButton disableRipple>
                    <HomeTwoToneIcon />
                  </TopbarIconButton>
                </Tooltip>
              )}
              {/*<IconLink to='/candidateManager'><SettingsTwoToneIcon/></IconLink>*/}

              <Tooltip
                title="로그아웃"
                placement="bottom"
                onClick={handleLogout}
              >
                <TopbarIconButton disableRipple>
                  <LogoutRoundedIcon />
                </TopbarIconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItem.map((item) => (
            <ListItem disablePadding sx={{ display: 'block' }} key={item.title}>
              <ListItemButtonStyled
                sx={{
                  minHeight: 42,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: pathname.includes(item.link)
                    ? '#ECF3FF'
                    : '#fff',
                }}
                component={Link}
                to={`${item.link}/${candidateId}`}
              >
                <ListItemIcon
                  sx={{
                    width: 32,
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary={item.title}
                  sx={{
                    fontWeight: 700,
                    opacity: open ? 1 : 0,
                    color: pathname.includes(item.link) ? '#276ae2' : '#000',
                  }}
                />
              </ListItemButtonStyled>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {candidateId && <Outlet />}
      {candidateId && shareDialogOpen && (
        <ShareDialog
          open={shareDialogOpen}
          setOpen={setShareDialogOpen}
          fromAdmin={true}
        />
      )}
    </RootBox>
  );
}
