import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Box,
  FormControlLabel,
  ListItemButton,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';
import { useMatchAvatarMutation } from 'react-query/mutations/avatars/useMatchAvatarMutation';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  width: '100%',
  margin: 0,
  justifyContent: 'space-between',
  // borderBottom:'1px solid #d4d9e7',
  boxSizing: 'border-box',
  '& .MuiCheckbox-root': {
    margin: '0 12px',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  // borderTop:'1px solid #d4d9e7',
  padding: 0,
  '&:first-of-type': {
    borderTop: 0,
  },
  '&.selected': {
    backgroundColor: '#ececec',
  },
}));

export const AvatarListDialog = ({ id, open, handleClose }) => {
  const { teamId } = useOutletContext();
  const [page, setPage] = useState(1);

  const [avatarId, setAvatarId] = useState(null);

  const handleChange = (event) => {
    setAvatarId(event.target.value);
  };

  const avatars = useQuery(teamAvatarsQueries.list(teamId, 5, '', page));
  const matchedAvatar = useQuery(teamAvatarsQueries.matchedAvatar(id));

  const mutation = useMatchAvatarMutation();

  return (
    <DialogWrapper
      sx={{
        '& .MuiDialog-container > .MuiPaper-root': {
          minWidth: 360,
          backgroundColor: '#dae0e6',
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogHeader title={'캐릭터 지정'} handleClose={handleClose} />
      <Box sx={{ width: '100%' }}>
        <RadioGroup
          sx={{ backgroundColor: '#fff', p: 2, m: 4, borderRadius: '10px' }}
          value={avatarId ?? matchedAvatar.data[0]?.id ?? ''}
          onChange={handleChange}
        >
          {avatars.isSuccess &&
            matchedAvatar.isSuccess &&
            avatars.data.items.map((avatar) => (
              <FormControlLabelStyled
                value={avatar.id}
                key={avatar.id}
                control={<Radio />}
                disabled={matchedAvatar.data.some(
                  (matchedAvatar) => matchedAvatar.id === avatar.id,
                )}
                labelPlacement="start"
                label={
                  <ListItemButtonStyled disableRipple>
                    <Typography style={{ fontSize: '1rem', fontWeight: 600 }}>
                      {avatar.name}
                    </Typography>
                  </ListItemButtonStyled>
                }
              />
            ))}
        </RadioGroup>
        {avatars.data.maxPage && (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            p={3}
            pt={0}
            style={{ boxSizing: 'border-box' }}
          >
            <Pagination
              count={avatars.data.maxPage}
              page={page}
              onChange={(_event, page) => setPage(page)}
            />
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          p={3}
          pt={0}
          style={{ boxSizing: 'border-box' }}
        >
          <CancelButton onClick={handleClose}>취소</CancelButton>
          <SubmitButton
            onClick={() =>
              mutation.mutate(
                { avatarId, managerId: id },
                {
                  onSuccess: handleClose,
                },
              )
            }
            disabled={
              matchedAvatar.isLoading ||
              mutation.isPending ||
              matchedAvatar.data.some(
                (matchedAvatar) => matchedAvatar.id === avatarId,
              ) ||
              !(avatarId && id)
            }
          >
            추가
          </SubmitButton>
        </Box>
      </Box>
    </DialogWrapper>
  );
};
