import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogSelection = styled(Select)(() => ({
  backgroundColor: '#fff',
  '& fieldset': {
    border: '1px solid #d4d9e7',
  },
  '& svg': {
    color: '#869592',
  },
  '& .MuiSelect-select': {
    padding: '12px 14px',
  },
}));
