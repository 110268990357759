import { useState } from 'react';

import { Box } from '@mui/material';

import { StyledIconButton } from 'ui/buttons/StyledIconButton';
import { SearchBar } from 'ui/inputs/SearchBar';
import { PageTitle } from 'ui/titles/PageTitle';

import { ManagersGrid } from 'pages/teams/features/management/components/blocks/ManagersGrid';

import { ReactComponent as UserProfileAddIcon } from 'assets/images/UserProfileAddIcon.svg';
import { AddManagerDialog } from 'components/Dialog/AddManagerDialog';
import { useDebouncedValue } from 'rooks';

export const ManagerManagement = () => {
  const [searchValue, setSearchValue] = useState('');
  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };
  const [filter, hotUpdate] = useDebouncedValue(searchValue, 500);

  const [openAddManagerDialog, setOpenAddManagerDialog] = useState(false);
  const handleOpenManagerDialog = () => {
    setOpenAddManagerDialog(true);
  };
  const handleCloseAddManagerDialog = () => {
    setOpenAddManagerDialog(false);
  };
  return (
    <>
      <PageTitle>계정 관리</PageTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StyledIconButton onClick={handleOpenManagerDialog}>
          <UserProfileAddIcon /> 계정 관리
        </StyledIconButton>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            hotUpdate();
          }}
        >
          <SearchBar
            placeholder="계정 검색"
            value={searchValue}
            onChange={handleChangeSearchValue}
          />
        </form>
      </Box>
      <ManagersGrid filter={filter} />

      {/*	계정추가 다이얼로그 작업 필요 */}
      {openAddManagerDialog && (
        <AddManagerDialog
          managerType={'Avatar'}
          open={openAddManagerDialog}
          handleClose={handleCloseAddManagerDialog}
        />
      )}
    </>
  );
};
