import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

const Search = styled('div')(() => ({
  position: 'relative',
  width: '14rem',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  border: '1px solid #e8edf7',
  borderRadius: 10,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export const SearchBar = ({ placeholder, disabled, searchValue, onChange }) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchRoundedIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={placeholder}
        disabled={disabled}
        value={searchValue}
        onChange={onChange}
      />
    </Search>
  );
};
