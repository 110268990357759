import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, TablePagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { usePaginationProps } from 'hooks/usePaginationProps';

import NoRowsComponent from 'ui/datagrid/NoRowsComponent';
import LoadingSpinner from 'ui/LoadingSpinner';

import { managersQueries } from 'react-query/factory/managersQueries';

export const ManagersDialogGrid = ({ filter, columns }) => {
  const { teamId } = useOutletContext();

  const pagination = usePaginationProps();

  const managers = useQuery(
    managersQueries.list(
      'Avatar',
      teamId,
      pagination.rowsPerPage,
      filter,
      pagination.page + 1,
    ),
  );
  if (managers.isLoading)
    return <LoadingSpinner isLoading={managers.isLoading} />;

  return (
    <Box
      sx={{
        width: '100%',
        '& .cellCaption': {
          '&>div': {
            padding: '3px 6px',
            color: '#0b2759',
            backgroundColor: '#d3e1fa',
            borderRadius: 20,
            marginRight: '5px',
          },
        },
      }}
    >
      <DataGrid
        rows={managers.data.items}
        columns={columns}
        slots={{
          noRowsOverlay: NoRowsComponent,
          footer: () => (
            <Box
              sx={{
                height: 52,
                display: 'flex',
                justifyContent: 'end',
                alignContent: 'center',
              }}
            >
              <TablePagination
                component={Box}
                sx={{
                  height: '100%',
                }}
                count={managers.data.totalItems ?? 10}
                page={pagination.page ?? 0}
                onPageChange={(event, page) => {
                  pagination.onPageChange(page);
                }}
                onRowsPerPageChange={(event) => {
                  const { value } = event.target;
                  pagination.onPageChange(0);
                  pagination.onPerPageChange(value);
                }}
                rowsPerPage={pagination.rowsPerPage ?? 5}
                rowsPerPageOptions={[5, 10, 20]}
                labelRowsPerPage="페이지당 데이터: "
              />
            </Box>
          ),
        }}
        rowCount={pagination.rowsPerPage ?? 5}
        //
        sx={{
          backgroundColor: '#fff',
          m: 3,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#333',
            color: '#fff',
            '& svg': {
              color: '#fff',
            },
          },
        }}
      />
    </Box>
  );
};
