import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import { Logo } from 'components/Logo';

const drawerWidth = 240;

const AppHeader = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  borderBottom: '1px solid #e8edf7',
  borderLeft: '1px solid #e8edf7',
  color: '#0F0F10',
  '& .MuiTypography-h6': {
    fontSize: '1.375rem',
    fontWeight: '700',
    marginLeft: 24,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const IconButton = styled(Button)(() => ({
  minWidth: 'auto',
  color: '#0F0F10',
  paddingLeft: 0,
  '&:hover': {
    color: '#0F0F10',
    backgroundColor: 'transparent',
  },
}));

export const Header = ({ open, handleDrawerOpen, children }) => {
  return (
    <AppHeader position="fixed" open={open}>
      <Toolbar>
        <IconButton
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuRoundedIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
        >
          <Box display="flex" alignItems="center">
            <Logo />
          </Box>
          {children}
        </Box>
      </Toolbar>
    </AppHeader>
  );
};
