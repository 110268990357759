import { API } from 'constants/api-endpoints';

import { defaultInstance } from 'lib/axios/instance';

export const doLogin = async (loginData) => {
  return await defaultInstance.post(`${API.AUTH}/signin`, {
    loginId: loginData.id,
    password: loginData.password,
  });
};
