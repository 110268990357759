import { useContext } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DATE_UTIL } from '../../../../lib/utils/date.util';
import { PinnedQuestionContext } from './PinnedQuestionContext';
import { ReactComponent as PlantIcon1 } from '../../../../assets/images/PlantIcon1.svg';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  margin: 0,
  boxSizing: 'border-box',
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const CaptionText = styled(Typography)(() => ({
  marginLeft: 35,
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  // border: '1px solid #ddd',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&:first-of-type .MuiAccordionSummary-root': {
    borderTop: 0,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  borderTop: '1px solid #d4d9e7',
  flexDirection: 'row',
  padding: '6px 16px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& svg': {
      width: 24,
      height: 24,
    },
    '&.Mui-expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '0.75rem',
      marginRight: 6,
      marginBottom: 0,
      padding: '0 4px',
      fontWeight: 600,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '& .MuiTypography-h6': {
      width: '100%',
      paddingRight: 32,
      fontSize: '1rem',
      whiteSpace: 'normal',
      display: '-webkit-box',
      webkitLineClamp: '1',
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    '&.Mui-expanded': {
      '& .MuiTypography-h6': {
        webkitBoxOrient: 'unset',
        overflow: 'visible',
      },
    },
    '& .MuiTypography-caption': {
      color: '#9b9b9b',
      fontSize: '0.875rem',
      fontWeight: 500,
      padding: 0,
      marginRight: 0,
    },
  },
}));

export const PinnedQuestionItem = ({ category }) => {
  const context = useContext(PinnedQuestionContext);
  const { isCheckedQuestion, mainCategory, toggleQuestion, disabled } = context;
  return (
    <Accordion
      key={category.categoryId}
      expanded={mainCategory?.categoryId === category.categoryId}
    >
      <AccordionSummary onClick={(e) => toggleQuestion(e, category)}>
        <Box display="flex" alignItems="center">
          <FormControlLabelStyled
            control={
              <Checkbox
                disabled={disabled}
                checked={isCheckedQuestion(category)}
                onClick={(e) => toggleQuestion(e, category)}
              />
            }
            label={''}
          />
          {category.iconObject?.downloadUrl ? (
            <img
              src={category.iconObject?.downloadUrl}
              alt={'icon'}
              style={{ width: 30, height: 30 }}
            />
          ) : (
            <PlantIcon1 />
          )}
          <Typography variant="h6" ml={1}>
            {category.question}
          </Typography>
        </Box>
        <CaptionText variant="caption">
          {DATE_UTIL.converterUTCToTimeZone(
            category.createdDatetime,
            DATE_UTIL.FORMAT.FULL_FORMAT_KOREAN,
          )}
        </CaptionText>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h7">{category.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
