import * as Typo from 'pages/teams/features/team-data/components/atoms/Typography';

import { FILE_STATUS } from 'constants/file-status';

export const DataCheck = ({ data }) => {
  const { fileStatus } = data;
  const text = FILE_STATUS[fileStatus];

  return (
    <>
      {fileStatus === 'Uploaded' && (
        <Typo.DefaultTypography>{text}</Typo.DefaultTypography>
      )}
      {fileStatus === 'Labeling' && (
        <Typo.DefaultTypography>{text}</Typo.DefaultTypography>
      )}
      {fileStatus === 'Embedded' && (
        <Typo.CompleteTypography>{text}</Typo.CompleteTypography>
      )}
      {fileStatus === 'Error' && (
        <Typo.DangerTypography>{text}</Typo.DangerTypography>
      )}
    </>
  );
};
