import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const createCategory = async (params) => {
  const formData = new FormData();
  formData.append('file', params.image);
  formData.append('object', JSON.stringify(params.inputData));

  await tokenInstance.post(
    `${API.AVATARS}/category`,
    formData,
    MULTIPART_HEADER,
  );
};
