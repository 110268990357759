import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import LoadingSpinner from 'ui/LoadingSpinner';

import ViewAllList from 'pages/avatars/ViewAllList';

import { USER_IDEAS } from 'constants/queryKeys';

import { getUserIdeas } from 'api-legacy/user';

export default function IdeaList() {
  const params = useParams();
  const [page, setPage] = useState(1);

  const {
    data: userIdeas,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [USER_IDEAS, params.candidateId, page],
    queryFn: async () => {
      const { data } = await getUserIdeas({
        page: page,
        id: params.candidateId,
        search: '',
      });
      return data;
    },
    enabled: !!params.candidateId,
  });

  if (isError) return null;

  return (
    <ViewAllList
      title={'아이디어'}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      data={userIdeas}
      keyField="id"
      valueField="value"
    />
  );
}
