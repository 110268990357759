import { queryOptions } from '@tanstack/react-query';

import { fetchTeamQuestionRecords } from 'api/chart/fetchTeamQuestionRecords';

import { fetchAvatarQuestionRecords } from '../../api/chart/fetchAvatarQuestionRecords';

export const recordsQueries = {
  all: () => ['records'],
  teams: () => [...recordsQueries.all(), 'team'],
  team: ({ id, year, month, orderBy }) =>
    queryOptions({
      queryKey: [...recordsQueries.teams(), id, orderBy, year, month],
      queryFn: () => fetchTeamQuestionRecords({ id, year, month, orderBy }),
      select: (data) => {
        return {
          labels: data.labels,
          datasets: [
            {
              id: 'Answered',
              label: '답변 완료',
              data: data.answeredQuestionRecords,
              backgroundColor: 'rgba(255, 99, 132, 1)',
              fill: false,
            },
            {
              id: 'Missing',
              label: '미응답',
              data: data.missingQuestionRecords,
              backgroundColor: 'rgba(255, 205, 86, 1)',
              fill: false,
            },
          ],
        };
      },
    }),
  avatars: () => [...recordsQueries.all(), 'avatar'],
  avatar: ({ id, year, month, period }) =>
    queryOptions({
      queryKey: [...recordsQueries.avatars(), id, period, year, month],
      queryFn: () => fetchAvatarQuestionRecords({ id, year, month, period }),
      select: (data) => {
        return {
          labels: data.labels,
          datasets: [
            {
              id: 'Answered',
              label: '답변 완료',
              data: data.answeredQuestionRecords,
              backgroundColor: 'rgba(255, 99, 132, 1)',
              fill: false,
            },
            {
              id: 'Missing',
              label: '미응답',
              data: data.missingQuestionRecords,
              backgroundColor: 'rgba(255, 205, 86, 1)',
              fill: false,
            },
          ],
        };
      },
    }),
};
