import { makeAutoObservable } from 'mobx';

export default class UserStore {
  constructor(props) {
    this.startAudioId = '';

    makeAutoObservable(this);
  }

  setStartAudioId = (audioId) => {
    this.startAudioId = audioId;
  };
}
