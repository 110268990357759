import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ExposeSwitch = styled(Switch)(({ theme }) => ({
  width: 80,
  height: 46,
  padding: 8,
  '& .MuiSwitch-switchBase': {
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px)',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 24,
      height: 24,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 24 24'><path fill='${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/></svg>')`,
      left: 14,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24' width='24' viewBox='0 0 24 24'><path fill='${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}' d='M19,13H5V11H19V13Z' /></svg>')`,
      right: 14,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 24,
    height: 24,
    margin: 2,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1,
  },
}));
