import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#fff', //#9ebedd
  },
  container: {
    maxWidth: 1200,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarWrap: {
    '& img': {
      maxWidth: '64px',
      maxHeight: '64px',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      aspectRatio: '3/4',

      '@media all and (max-width: 640px)': {
        maxWidth: '48px',
        maxHeight: '48px',
      },
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  topArea: {
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // padding:'0px 20px',
    // '@media all and (max-width: 350px)': {
    //     padding:'0px 10px',
    // },
    '@media all and (max-width: 640px)': {
      height: 78,
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 64,
      height: 64,
      '@media all and (max-width: 640px)': {
        width: 48,
        height: 48,
      },
    },
  },
  name: {
    fontSize: '1.75rem',
    fontWeight: 600,
    marginLeft: 16,
    '@media all and (max-width: 640px)': {
      fontSize: '1.5rem',
    },
    '@media all and (max-width: 420px)': {
      fontSize: '1.25rem',
    },
  },
  iconBtnBox: {
    '& > button.MuiButtonBase-root': {
      marginLeft: 20,
      '& > svg': {
        width: 36,
        height: 36,
        '& path': {
          stroke: '#0F0F10',
          strokeWidth: 3,
        },
      },
      '@media all and (max-width: 440px)': {
        marginLeft: 10,
        '& > svg': {
          width: 32,
          height: 32,
        },
      },
      '@media all and (max-width: 340px)': {
        marginLeft: 0,
      },
    },
  },

  bottomArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 40px',
    '@media all and (max-width: 640px)': {
      padding: '8px 24px',
    },
  },
  count: {
    color: '#9A9B9E',
    fontSize: '1.375rem',
    marginLeft: 6,
    '& b': {
      color: '#27C3E2',
      fontWeight: 800,
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1rem',
    },
  },
  btnStyle: {
    color: '#fff',
    backgroundColor: '#333',
    borderRadius: 10,
    fontSize: '1.5rem',
    padding: '12px 40px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#333',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '12px 20px',
    },
  },
  dialogStyle: {
    '&.MuiDialog-root': {
      '& .MuiPaper-root': {
        width: '100%',
        borderRadius: 20,
      },
      '& .MuiDialogTitle-root': {
        borderBottom: '1px solid #e8edf7',
        padding: '24px 40px',
        // marginBottom:30,
        '& p': {
          fontSize: '1.875rem',
          fontWeight: 'bold',
          margin: '12px 0',
        },
      },
      '& .MuiDialogContent-root': {
        fontSize: '1.375rem',
        padding: '24px 40px',
        color: '#161616',
        lineHeight: 1.37,
        '& li': {
          marginBottom: 12,
        },
      },
      '& .MuiDialogActions-root': {
        padding: '16px 40px 24px',
        alignItems: 'center',
      },
      '@media all and (max-width: 640px)': {
        '& .MuiDialogTitle-root': {
          padding: '16px 24px',
          '& p': {
            fontSize: '1.5rem!important',
          },
        },
        '& .MuiDialogContent-root': {
          fontSize: '1.125rem',
          padding: '16px 24px',
        },
        '& .MuiDialogActions-root': {
          padding: '0 24px 24px',
        },
      },
      '@media all and (max-width: 420px)': {
        '& .MuiDialogTitle-root': {
          padding: '8px 16px',
          '& p': {
            fontSize: '1.25rem!important',
          },
        },
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  btnCancelStyle: {
    '&.MuiButton-root': {
      color: '#240B0D',
      backgroundColor: '#DBDBDB',
      borderRadius: 10,
      fontSize: '1.5rem',
      padding: '8px 40px',
      textDecoration: 'none',
      marginLeft: 30,
      '&:hover': {
        backgroundColor: '#DBDBDB',
      },
      '@media all and (max-width: 640px)': {
        fontSize: '1.125rem',
        padding: '8px 20px',
        marginLeft: 0,
      },
      '@media all and (max-width: 420px)': {
        fontSize: '1rem',
        padding: '6px 14px',
      },
    },
  },
  btnConfirmStyle: {
    '&.MuiButton-root': {
      color: '#fff',
      backgroundColor: '#27C3E2',
      borderRadius: 10,
      fontSize: '1.5rem',
      padding: '8px 40px',
      textDecoration: 'none',
      marginLeft: 30,
      '&:hover': {
        backgroundColor: '#27C3E2',
      },
      '@media all and (max-width: 640px)': {
        fontSize: '1.125rem',
        padding: '8px 20px',
        marginLeft: 20,
      },
      '@media all and (max-width: 420px)': {
        fontSize: '1rem',
        padding: '6px 14px',
      },
    },
  },
  inputStyle: {
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '1.5rem',
      backgroundColor: '#fff',
      border: '1px solid #e8edf7',
      borderRadius: 10,
      '& textarea': {
        padding: '12px 20px',
      },
      '@media all and (max-width: 640px)': {
        fontSize: '1.125rem',
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
      {
        border: '0',
      },
  },
});
