import { Link, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getUserIdeas } from '../../../../api-legacy/user';
import { USER_IDEAS } from '../../../../constants/queryKeys';
import { cacheQueries } from '../../../../react-query/factory/cacheQueries';

const BoxStyled = styled('div')(() => ({
  marginBottom: 20,
}));

const TitleBoxStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
}));

const SubTitle = styled('h4')(({ theme }) => ({
  width: '100%',
  margin: '3.5rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.125rem',
  borderBottom: '1px dashed #ddd',
  paddingBottom: 12,
}));

const UiStyled = styled('div')(({ theme }) => ({
  '& ol': {
    margin: 0,
    paddingLeft: 20,
  },
  '& ul': {
    paddingLeft: 20,
    listStyle: 'square',
  },
  '& li': {
    justifyContent: 'flex-start',
    textAlign: 'left',
    fontSize: '1rem',
    color: '#121212',
    margin: '16px 0',
    padding: '0 42px 16px 8px',
  },
}));

const CommonButton = styled(Link)(({ theme }) => ({
  position: 'absolute',
  top: '3rem',
  right: 0,
  color: '#276AE2',
  border: '1px solid #276AE2',
  backgroundColor: '#EEF3FD',
  padding: '8px 24px',
  marginLeft: 24,
  fontSize: '0.938rem',
  fontWeight: 600,
  borderRadius: 8,
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '#EEF3FD',
  },
}));

export const IdeaSection = () => {
  const { candidateId } = useParams();
  const ideas = useQuery({
    queryKey: [USER_IDEAS, candidateId, 1],
    queryFn: async () => {
      const { data } = await getUserIdeas({
        page: 1,
        id: candidateId,
        search: '',
      });
      return data;
    },
    enabled: !!candidateId,
  });

  return (
    <BoxStyled>
      <TitleBoxStyled>
        <SubTitle>아이디어 제안</SubTitle>
        <CommonButton to={`/candidate/monitoring/idea/${candidateId}`}>
          전체보기
        </CommonButton>
      </TitleBoxStyled>
      {ideas.isSuccess && (
        <UiStyled>
          <ul>
            {ideas.data.items.map((data) => (
              <li key={data.id}>{data.value}</li>
            ))}
          </ul>
        </UiStyled>
      )}
    </BoxStyled>
  );
};
