import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Input = styled(TextField)(() => ({
  minWidth: 360,
  border: '0 none',
  '& input': {
    fontSize: '1.25rem',
    '&:before, &:after': {
      borderBottom: '1px solid #d4d9e7',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 10,
  },
}));
