import { PageTitle } from 'ui/titles/PageTitle';

import { TeamInfoMain } from 'pages/teams/features/team-info/components/blocks/TeamInfoMain';

export const TeamInfo = () => {
  return (
    <>
      <PageTitle>팀 정보</PageTitle>
      <TeamInfoMain />
    </>
  );
};
