import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const fetchCategoryList = async (avatarId) => {
  const { data } = await tokenInstance.get(
    `${API.AVATARS}/${avatarId}/categories`,
  );

  return data;
};

export const fetchCategory = async (avatarId, page = 1) => {
  const { data } = await tokenInstance.get(`${API.AVATARS}/categories`, {
    params: { page, id: avatarId },
  });

  return data;
};
export const fetchSubCategory = async (avatarId, categoryId, page = 1) => {
  const { data } = await tokenInstance.get(`${API.AVATARS}/categories/sub`, {
    params: { page, id: avatarId, subId: categoryId },
  });
  return data;
};
