import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import ViewAllList from 'pages/avatars/ViewAllList';

import { cacheQueries } from 'react-query/factory/cacheQueries';

export default function QuestionLank() {
  const [page, setPage] = useState(1);
  const { candidateId } = useParams();
  const cache = useQuery(
    cacheQueries.cache(candidateId, 'allow empty string', 'HitCount', page, ''),
  );
  if (cache.isError) return null;
  return (
    <ViewAllList
      title={'질문 순위'}
      isLoading={cache.isLoading}
      page={page}
      setPage={setPage}
      data={cache.data}
      keyField={'cacheId'}
      valueField={'question'}
    />
  );
}
