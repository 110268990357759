import { useQuery } from '@tanstack/react-query';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';

export const MatchedAvatar = ({ managerId }) => {
  const matchedAvatar = useQuery(teamAvatarsQueries.matchedAvatar(managerId));

  if (matchedAvatar.isLoading) return null;
  return (
    <>
      {matchedAvatar.isSuccess &&
        matchedAvatar.data.map((avatar) => (
          <div key={avatar.id}>{avatar.name}</div>
        ))}
    </>
  );
};
