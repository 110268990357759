import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useDeleteAvatarMutation } from 'react-query/mutations/avatars/useDeleteAvatarMutation';

import { DeleteConfirmDialog } from '../../../../../../components/Dialog/DeleteConfirmDialog';

import { ManagerTableDialog } from 'components/Dialog/ManagerTableDialog';

const StyledLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '6px 12px',
  fontSize: '0.938rem',
  fontWeight: 500,
  borderRadius: 10,
  margin: '0 6px',
  '& svg': {
    width: 20,
    marginRight: 4,
  },
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));

const StyledButton = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: '#1B9FB9',
  padding: '6px 12px',
  fontSize: '0.938rem',
  fontWeight: 500,
  borderRadius: 10,
  margin: '0 6px',
  '& svg': {
    width: 20,
    marginRight: 4,
  },
  '&:hover': {
    backgroundColor: '#1B9FB9',
  },
}));

export const AvatarManagementCell = ({ id, name }) => {
  const mutation = useDeleteAvatarMutation();

  const [openManagerTable, setOpenManagerTable] = useState(false);
  const avatarRef = useRef(null);
  const handleOpenManagerDialog = () => {
    avatarRef.current = { id, name };
    setOpenManagerTable(true);
  };
  const handleCloseManagerDialog = () => {
    setOpenManagerTable(false);
    avatarRef.current = null;
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    mutation.mutate(id, { onSuccess: handleClose });
  };
  return (
    <Box display="flex">
      <StyledLink to={`/candidate/data/${id}`}>
        <PersonAddAlt1OutlinedIcon /> 캐릭터 관리
      </StyledLink>
      <StyledButton onClick={handleOpenManagerDialog}>
        <ManageAccountsOutlinedIcon /> 매니저 지정
      </StyledButton>
      <IconButton onClick={handleOpen}>
        <DeleteOutlineRoundedIcon />
      </IconButton>

      {openManagerTable && (
        <ManagerTableDialog
          open={openManagerTable}
          handleClose={handleCloseManagerDialog}
          currentAvatar={avatarRef.current}
        />
      )}
      {open && (
        <DeleteConfirmDialog
          open={open}
          pending={mutation.isPending}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  );
};
