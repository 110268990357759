import { styled } from '@mui/material/styles';

export const DefaultTypography = styled('p')(() => ({
  margin: 0,
  color: '#111',
  fontWeight: 600,
}));

export const DangerTypography = styled(DefaultTypography)(() => ({
  color: '#d32f2f',
}));

export const CompleteTypography = styled(DefaultTypography)(() => ({
  color: '#276ae2',
}));
