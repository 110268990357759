import { Button, Input, List, Textarea } from '@mui/joy';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(() => ({}));
export const TopBar = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #D3D4D4',
  zIndex: 1,
  boxSizing: 'border-box',
  '& *': {
    fontFamily: 'Pretendard !important',
  },
}));
export const TitleStyle = styled(Typography)(() => ({
  fontSize: '1.25rem',
  fontWeight: 800,
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: ' #fff',
  },
}));

export const Container = styled(Box)(() => ({
  display: 'flex',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: 64,
  boxSizing: 'border-box',
}));
export const SideBar = styled(Box)(() => ({
  position: 'fixed',
  width: 'inherit',
  height: 'calc(100vh - 64px)',
  // minHeight: 'calc(100vh - 64px)',
  padding: '16px 24px',
  borderRight: '1px solid #D3D4D4',
  boxSizing: 'border-box',
  zIndex: 10,
}));

export const SideBarTitle = styled(Typography)(() => ({
  fontSize: '0.938rem',
  fontWeight: 700,
  color: '#276AE2',
  textAlign: 'left',
  marginBottom: 8,
}));
export const ListBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: 30,
  '& .MuiTypography-subtitle1': {
    marginBottom: 8,
    fontSize: '0.875rem',
    fontWeight: 700,
    color: '#333',
  },
  '& .MuiTypography-body1': {
    fontSize: '0.938rem',
    fontWeight: 700,
    color: '#333',
    cursor: 'pointer',
  },
}));

export const InputStyle = styled(Input)(() => ({
  '&.MuiInput-root': {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'none',
    '& input': {},
  },
}));

export const InputBoxStyle = styled(Box)(() => ({
  width: '100%',
  padding: '4.5px 8px',
  border: '1px solid #cdd7e1',
  borderRadius: 6,
  boxSizing: 'border-box',
  '& .MuiButton-root': {
    display: 'flex',
    padding: '4px 12px',
    marginLeft: 'auto',
    fontSize: '0.875rem',
    letterSpacing: '-0.4px',
  },
}));

export const ListStyle = styled(List)(() => ({
  height: 260,
  width: '100%',
  padding: '4.5px 8px',
  border: '1px solid #cdd7e1',
  borderRadius: 6,
  overflowY: 'auto',
  '& .MuiListItem-root': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 4px',
    '& .MuiButtonBase-root': {
      width: '100%',
      padding: '4px 0',
      '& .MuiListItemIcon-root': {
        minWidth: 32,
      },
    },
  },
  '& .MuiListItemText-root': {
    display: '-webkit-box',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
  },
}));

export const TextBox = styled(Box)(() => ({
  color: '#32383e',
  lineHeight: '21px',
  fontSize: '0.875rem',
  textAlign: 'left',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      height: 28,
      '&:hover': {
        backgroundColor: ' transparent',
      },
    },
  },
}));

export const TextareaStyle = styled(Textarea)(() => ({
  '&.MuiTextarea-root': {
    width: '100%',
    padding: 12,
    fontSize: '1.125rem',
    backgroundColor: '#fff',
  },
  '& .MuiIconButton-root': {
    '& > svg': {
      width: 32,
      height: 32,
      '@media all and (max-width: 768px)': {
        width: 28,
        height: 28,
      },
      '@media all and (max-width: 480px)': {
        width: 24,
        height: 24,
      },
    },
  },
  '@media all and (max-width: 768px)': {
    '&.MuiTextarea-root': {
      fontSize: '1rem',
    },
  },
  '@media all and (max-width: 480px)': {
    '&.MuiTextarea-root': {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '0.938rem',
    },
    '& .MuiTextarea-textarea': {
      height: 'inherit!important',
    },
    '& .MuiTextarea-endDecorator': {
      margin: 0,
      '& .MuiIconButton-root': {
        padding: 0,
        width: 28,
        minWidth: 28,
        height: 28,
        minHeight: 28,
      },
    },
  },
}));

export const Contents = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  minHeight: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#f6f9fe',
}));

export const ContentsWrap = styled(Box)(() => ({
  position: 'relative',
  maxWidth: 1200,
  width: '65%',
  minHeight: 'calc(100vh - 78px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media all and (max-width: 1200px)': {
    width: '100%',
    maxWidth: 1200,
  },
}));

export const ToggleButton = styled(Button)(() => ({
  position: 'fixed',
  top: '50%',
  zIndex: 5,
  width: 24,
  height: 72,
  borderLeft: '0 none',
  borderRadius: '0 6px 6px 0',
  fontSize: '0.75rem',
  padding: '0 4px',
  backgroundColor: '#32383e',
  color: '#fff',
  border: '0 none',
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: ' #32383e',
  },
}));

export const BottomToggleButton = styled(Button)(() => ({
  position: 'fixed',
  top: `calc(100% - 40px)`,
  zIndex: 5,
  width: 200,
  height: 40,
  borderLeft: '0 none',
  borderRadius: '6px 6px 0 0',
  fontSize: '0.85rem',
  padding: '0 4px',
  backgroundColor: '#32383e',
  color: '#fff',
  border: '0 none',
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: ' #32383e',
  },
}));

export const ContentsTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  padding: '14px 20px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #D3D4D4',
  boxSizing: 'border-box',
  '& .MuiTypography-h5': {
    color: '#333',
    fontWeight: 700,
  },
  '& .MuiTypography-body1': {
    display: ' flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: '#FF3D3D',
    marginTop: 3,
    '& svg': {
      marginRight: 4,
    },
  },
}));

export const ChatBox = styled(Box)(() => ({
  width: '100%',
  //height: 'calc(100vh - 48px - 85px )', //전체높이 - Topbar - ChatboxTitle
  overflowY: 'auto',
  display: 'inline-flex',
  flexDirection: 'column',
  padding: '20px 10px 20px 20px',
  boxSizing: 'border-box',
  '& > div:first-of-type': {
    marginTop: 0,
  },
  '@media all and (max-width: 1200px)': {
    padding: '20px 20px 20px 48px',
  },
}));

export const EmptyBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#333',
  '& svg': {
    marginBottom: 10,
  },
  '@media all and (max-width: 768px)': {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  '@media all and (max-width: 480px)': {
    fontSize: '1rem',
  },
}));

export const Grid1RowBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gridGap: 10,
  backgroundColor: '#ECF0EF', //#f4f9fe
  padding: 20,
  marginTop: 'auto',
  borderRadius: 6,
}));

export const GridBox = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: 20,
  backgroundColor: '#f4f9fe',
  padding: 20,
  marginTop: 'auto',
  borderRadius: 6,
}));
export const GridBoxItem = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  '& .MuiTypography-root': {
    display: 'block',
    width: '100%',
    textAlign: 'left',
  },
}));
export const ChatInput = styled(Box)(() => ({
  // width: '-webkit-fill-available',
  // position: 'fixed',
  // bottom: 0,
  position: 'sticky',
  left: 0,
  bottom: 0,
  width: '100%',
  display: 'grid',
  // gridTemplateColumns: '1fr 48px 48px',
  gridGap: 14,
  padding: '0px 16px 20px 16px',
  boxSizing: 'border-box',
  backgroundColor: '#f6f9fe',
  '& ."MuiTextarea-textarea': {
    height: 'max-content',
  },
}));

export const ChatInputStyle = styled(InputStyle)(() => ({
  width: '100%',
  '& input': {
    fontSize: '1rem',
  },
}));

export const UserChat = styled(Box)(() => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'flex-end',
  marginTop: '2rem',
  '& p': {
    maxWidth: '80%',
    padding: '14px 16px',
    backgroundColor: '#276AE2',
    color: '#fff',
    borderRadius: 6,
    textAlign: 'left',
    boxSizing: 'border-box',
  },
}));
export const AiChat = styled(Box)(() => ({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: 20,
  '& .MuiTypography-subtitle1': {
    display: ' flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#333',
    marginBottom: 6,
    '& svg': {},
  },
  '& .MuiTypography-body1': {
    maxWidth: '80%',
    padding: '14px 16px',
    backgroundColor: '#dfe8ef',
    color: '#333',
    borderRadius: 6,
    textAlign: 'left',
    boxSizing: 'border-box',
  },
}));

export const IconBox = styled(Box)(() => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'flex-start',
  '& > button': {
    '& svg': {
      width: 20,
      height: 20,
    },
    '&:hover, @media (hover: hover), &:active, &[aria-pressed="true"]': {
      backgroundColor: '#fff!important',
    },
  },
}));

export const FabWrap = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 5,
  boxSizing: 'border-box',
}));

export const FabTitle = styled(Button)(() => ({
  minWidth: 'max-content',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  marginRight: 6,
  color: '#7396d3',
  fontSize: '0.938rem',
  fontWeight: 700,
  '& svg': {
    width: 20,
    height: 20,
    '&:last-child path': {
      stroke: '#7396d3',
    },
  },
}));

export const FabStyle = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  gap: 12,
  padding: '3px 0',
  '& Button': {
    minWidth: 'max-content',
    color: '#333',
    fontSize: '0.875rem',
    fontWeight: 500,
    // backgroundColor: '#93b5f1',
    border: '1px solid #d3d4d4',
    boxShadow: 'none',
    padding: '2px 10px',
    borderRadius: 25,
    '&:hover, &:active,': {
      backgroundColor: '#f6f9fe!important',
    },
    '@media (hover: hover),  &[aria-pressed="true"]': {
      backgroundColor: '#fff!important',
    },
  },
  '&::-webkit-scrollbar': {
    height: 6,
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 6,
    background: '#ccc',
  },
}));
