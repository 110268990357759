import { useState } from 'react';

import { Box } from '@mui/material';

import { StyledIconButton } from 'ui/buttons/StyledIconButton';
import { SearchBar } from 'ui/inputs/SearchBar';
import { PageTitle } from 'ui/titles/PageTitle';

import { AvatarsGrid } from 'pages/teams/features/management/components/blocks/AvatarsGrid';

import { ReactComponent as UserProfileAddIcon } from 'assets/images/UserProfileAddIcon.svg';
import { AddAvatarDialog } from 'components/Dialog/AddAvatarDialog';
import { useDebouncedValue } from 'rooks';

export const AvatarManagement = () => {
  const [searchValue, setSearchValue] = useState('');
  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const [filter, hotUpdate] = useDebouncedValue(searchValue, 500);

  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
  const handleOpenAvatarDialog = () => {
    setOpenAvatarDialog(true);
  };
  const handleCloseAvatarDialog = () => {
    setOpenAvatarDialog(false);
  };

  return (
    <>
      <PageTitle>캐릭터 관리</PageTitle>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StyledIconButton onClick={handleOpenAvatarDialog}>
          <UserProfileAddIcon /> 캐릭터 추가
        </StyledIconButton>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            hotUpdate();
          }}
        >
          <SearchBar
            placeholder="캐릭터 검색"
            value={searchValue}
            onChange={handleChangeSearchValue}
          />
        </form>
      </Box>
      <AvatarsGrid filter={filter} />

      {openAvatarDialog && (
        <AddAvatarDialog
          open={openAvatarDialog}
          handleClose={handleCloseAvatarDialog}
          title={'캐릭터 추가'}
        />
      )}
    </>
  );
};
