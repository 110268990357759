import { memo, useContext, useState } from 'react';
import toast from 'react-hot-toast';

import { Box } from '@mui/material';

import { Record } from 'pages/user/features/question/components/items/Record';
import { Submit } from 'pages/user/features/question/components/items/Submit';
import {
  ChatInput,
  TextareaStyle,
} from 'pages/user/features/question/components/styled';

import { ChatContext } from '../context/ChatProvider';

const ChatInputBar = () => {
  const { handleRequestQuery } = useContext(ChatContext);

  const [inputValue, setInputValue] = useState('');
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSTT = (text) => {
    setInputValue(text);
  };
  const handleSubmit = async () => {
    if (!inputValue.length) {
      toast.error('질문을 입력해주세요.');
      return;
    }
    if (inputValue.length > 512) {
      toast.error('질문을 조금만 더 짧게 입력해주세요.');
      return;
    }
    setInputValue('');
    const question = { content: inputValue, timestamp: Date.now() };
    await handleRequestQuery(question);
  };

  return (
    <ChatInput>
      <TextareaStyle
        placeholder="궁금한 내용을 요청하세요."
        minRows={1}
        sx={{
          '&.MuiTextarea-root': {
            boxShadow: '1px 1px 7px 0px #c0c9d7',
          },
        }}
        endDecorator={
          <Box
            sx={{
              display: 'flex',
              flex: 'auto',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <Record handleSTT={handleSTT} />
            <Submit query={handleSubmit} />
          </Box>
        }
        value={inputValue}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (event.nativeEvent.isComposing) {
              return;
            }
            handleSubmit();
          }
        }}
      />
    </ChatInput>
  );
};

export default memo(ChatInputBar);
