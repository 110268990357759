import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CommonSwitch = styled(Switch)(() => ({
  width: 80,
  height: 46,
  padding: 8,
  '& .MuiSwitch-switchBase': {
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px)',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      width: 24,
      // height: 24,
      fontSize: '0.813rem',
      fontWeight: 800,
    },
    '&:before': {
      content: '"ON"',
      left: 14,
    },
    '&:after': {
      content: '"OFF"',
      right: 14,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 24,
    height: 24,
    margin: 2,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1,
  },
}));
