import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const updateCharacter = async (params) => {
  const formData = new FormData();
  if (params.image) {
    formData.append('file', params.image);
  }
  formData.append('object', JSON.stringify(params.inputData));

  await tokenInstance.post(`${API.CHARACTERS}`, formData, MULTIPART_HEADER);
};
