import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: 20,
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
  '& .sub-title': {
    alignSelf: 'start',
    fontSize: '1rem',
    flexBasis: '10%',
  },
  '& span': {
    margin: 'auto 0',
  },
  '& p': {
    fontSize: '1.125rem',
    fontWeight: 400,
  },
}));

const SubTitle = styled('h4')(() => ({
  marginRight: 40,
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.125rem',
  borderBottom: '1px dashed #ddd',
  paddingBottom: 12,
}));

export const ProfileWrapper = (props) => {
  return (
    <BoxStyled>
      <SubTitle className={'sub-title'}>{props.name}</SubTitle>
      {props.children}
    </BoxStyled>
  );
};
