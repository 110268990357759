import { Box, Grid, Pagination, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommonStack } from 'components/styledMUI/CommonStack';
import { PinnedQuestionItem } from './PinnedQuestionItem';

const AccordionWrap = styled('div')(({ theme }) => ({
  marginTop: 10,
  border: '1px solid #d4d9e7',
  borderRadius: 10,
  overflow: 'hidden',
}));

export const PinnedQuestionGrid = ({
  page,
  handleChangePage,
  avatarCacheCategories,
}) => {
  return (
    <Grid container>
      {avatarCacheCategories && (
        <Grid item xs={12}>
          <AccordionWrap>
            {avatarCacheCategories?.items?.length > 0 ? (
              <>
                {avatarCacheCategories?.items?.map((category) => (
                  <PinnedQuestionItem
                    key={category.categoryId}
                    category={category}
                  />
                ))}
              </>
            ) : (
              <Box sx={{ m: 5, display: 'flex', justifyContent: 'center' }}>
                <Typography>등록된 카테고리가 없습니다.</Typography>
              </Box>
            )}
          </AccordionWrap>

          {avatarCacheCategories.maxPage > 1 && (
            <CommonStack spacing={2} mt={3}>
              <Pagination
                count={avatarCacheCategories.maxPage}
                page={page}
                onChange={(e, page) => handleChangePage(e, page)}
              />
            </CommonStack>
          )}
        </Grid>
      )}
    </Grid>
  );
};
