import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import useImageUpload from 'hooks/useImageUpload';

import { ImageUploadButton } from 'ui/buttons/ImageUploadButton';

import { Input } from 'pages/teams/features/team-info/components/atoms/Input';
import { LogoBox } from 'pages/teams/features/team-info/components/atoms/LogoBox';
import { ButtonGroup } from 'pages/teams/features/team-info/components/blocks/ButtonGroup';
import { InfoItem } from 'pages/teams/features/team-info/components/items/InfoItem';

import { useTeamInfoMutation } from 'react-query/mutations/infos/useTeamInfoMutation';

import { bodyToFormData } from 'lib/utils/bodyToFormData';
import { convertObjectToBlob } from 'lib/utils/convertObjectToBlob';

const CancelButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#333',
  backgroundColor: 'transparent',
  marginLeft: 20,
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#e1e1e1',
  },
}));
const EditButton = styled(Button)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#276AE2',
  marginLeft: 20,
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#1d5ac8',
  },
}));
export const TeamInfoEditForm = () => {
  const navigate = useNavigate();

  const { teamInfo } = useOutletContext();

  const mutation = useTeamInfoMutation();

  const [team, setTeam] = useState({
    name: null,
    title: null,
  });
  const [image, changeImage] = useImageUpload();
  const body = {
    team: convertObjectToBlob(team),
    logo: image.file ? image.file : null,
  };
  const handleChangeTeamBody = (event) => {
    const { name, value } = event.target;
    setTeam({
      ...team,
      [name]: value,
    });
  };

  const navigateToHome = () => {
    navigate('/team/info');
  };

  const handleUpdate = () => {
    if (!(team.name || team.title || image.file)) {
      navigateToHome();
    }
    const formData = bodyToFormData(body);
    mutation.mutate(formData, {
      onSuccess: () => {
        navigateToHome();
      },
    });
  };

  if (teamInfo.isLoading) return <Box>로딩중</Box>;
  if (teamInfo.isError) return <Box>에러</Box>;
  return (
    <Box>
      <InfoItem title={'팀명'}>
        <Input
          name={'name'}
          value={team.name ?? teamInfo.data.team.name}
          onChange={handleChangeTeamBody}
        />
      </InfoItem>
      <InfoItem title={'타이틀'}>
        <Input
          name={'title'}
          value={team.title ?? teamInfo.data.team.title}
          onChange={handleChangeTeamBody}
        />
      </InfoItem>
      <InfoItem title={'로고'}>
        <LogoBox
          src={image?.url || teamInfo.data.logoObjectUrl}
          alt={'팀 로고'}
        />
        <ImageUploadButton fileHandler={changeImage} />
      </InfoItem>
      <ButtonGroup>
        <CancelButton disabled={mutation.isPending} onClick={navigateToHome}>
          취소
        </CancelButton>
        <EditButton disabled={mutation.isPending} onClick={handleUpdate}>
          확인
        </EditButton>
      </ButtonGroup>
    </Box>
  );
};
