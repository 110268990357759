import { useQuery } from '@tanstack/react-query';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { teamAvatarsQueries } from 'react-query/factory/teamAvatarsQueries';
import { useMatchAvatarMutation } from 'react-query/mutations/avatars/useMatchAvatarMutation';

const TableButton = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: '#4F9BE2',
  padding: '5px 12px',
  fontSize: '0.938rem',
  fontWeight: 500,
  borderRadius: 10,
  margin: '0 6px',
  '& svg': {
    width: 20,
    marginRight: 4,
  },
  '&:hover': {
    backgroundColor: '#4F9BE2',
  },
  '&.Mui-disabled': {
    backgroundColor: '#ddd',
  },
}));

export const MatchButton = ({ avatarId, managerId }) => {
  const matchedAvatar = useQuery(teamAvatarsQueries.matchedAvatar(managerId));
  const mutation = useMatchAvatarMutation();

  return (
    <Box display="flex">
      {matchedAvatar.isSuccess && (
        <TableButton
          onClick={() => mutation.mutate({ avatarId, managerId })}
          disabled={
            matchedAvatar.isLoading ||
            mutation.isPending ||
            matchedAvatar.data.some(
              (matchedAvatar) => matchedAvatar.id === avatarId,
            ) ||
            !(avatarId && managerId)
          }
        >
          <PersonAddAlt1OutlinedIcon /> 지정하기
        </TableButton>
      )}
    </Box>
  );
};
