import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteAvatarMissingQuestion } from 'api/avatars/deleteAvatarMissingQuestion';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useDeleteAvatarMissingQuestionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => deleteAvatarMissingQuestion(data),
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.DELETE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.DELETE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['Missing Questions'],
      });
    },
  });
};
