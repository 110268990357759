import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  Avatar,
  Box,
  Container,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import LoadingSpinner from 'ui/LoadingSpinner';

import { PUBLIC_AVATARS } from 'constants/queryKeys';

import Recording from './Recording';

import { getPublicAvatars } from 'api-legacy/avatar';
import { ReactComponent as SendIcon } from 'assets/images/IconSendFlat.svg';
import { ReactComponent as IconUser } from 'assets/images/IconUserMax.svg';
import { Logo } from 'components/Logo';
import { SendIconBox } from 'components/styledMUI/SendIconBox';
import { useStyles } from 'components/styles/CandidateListStyle';
import { formatPhoneNumber } from 'lib/utils/phoneNumber.util';
import { useDebouncedValue } from 'rooks';

export default function CandidateList() {
  const classes = useStyles();

  const navigate = useNavigate();
  const params = useParams();

  const [searchText, setSearchText] = useState(params.search);
  const [debouncedSearch, hotUpdateDebouncedSearch] = useDebouncedValue(
    searchText,
    500,
  );
  const onClickImg = (id) => {
    navigate(`/profile/${id}`, { state: { userType: 0 } });
  };

  const handleChangeText = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    navigate(`/candidateList/${searchText}`);
  };

  const {
    data: publicAvatarList,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: [PUBLIC_AVATARS, debouncedSearch],
    queryFn: async () => {
      const { data } = await getPublicAvatars(debouncedSearch);
      handleSearch();
      return data;
    },
  });

  const handleSTT = (data) => {
    setSearchText(data);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.searchBox}>
          <Box
            sx={{
              '& :hover': { cursor: 'pointer' },
            }}
            className={classes.logoStyle}
            onClick={() => navigate('/')}
          >
            <Logo />
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              value={searchText}
              onChange={handleChangeText}
              onKeyDown={(e) => e.key === 'Enter' && hotUpdateDebouncedSearch()}
              placeholder="지역명 또는 후보자명을 입력해주세요."
              variant="outlined"
              className={classes.inputStyle}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <SendIconBox
                    onClick={() => hotUpdateDebouncedSearch()}
                    disableRipple
                  >
                    <SendIcon />
                  </SendIconBox>
                ),
              }}
              disableripple={'true'}
            />
            <Recording handleSTT={handleSTT} />
          </Box>
        </Box>
        {isLoading && <LoadingSpinner isLoading={isLoading} />}
        {isSuccess &&
          publicAvatarList.map((item) => (
            <Paper
              key={item.id}
              onClick={() => onClickImg(item.id)}
              className={classes.card}
              elevation={3}
            >
              <Box className={classes.cardImg}>
                {item.avatarImageObject ? (
                  <img
                    src={item.avatarImageObject.downloadUrl}
                    alt="프로필 사진"
                  />
                ) : (
                  <Avatar>
                    <IconUser />
                  </Avatar>
                )}
              </Box>
              <Box className={classes.cardContents}>
                <Box className={classes.cardTitle}>
                  <Typography variant="h6" component={'span'}>
                    {item.name}
                  </Typography>
                </Box>
                <Box className={classes.infoStyle}>
                  {/*{item.typeMeta && (*/}
                  {/*	<Box>*/}
                  {/*		<span>선거구</span>*/}
                  {/*		{item.typeMeta}*/}
                  {/*	</Box>*/}
                  {/*)}*/}
                  {item.teamName && (
                    <Box>
                      <span>소속</span>
                      {item.teamName}
                    </Box>
                  )}
                  {item.phoneNum && (
                    <Box>
                      <span>연락처</span>
                      {formatPhoneNumber(item.phoneNum)}
                    </Box>
                  )}
                </Box>
              </Box>
            </Paper>
          ))}
        {isSuccess && !publicAvatarList.length && (
          <Typography variant="h6" component={'span'}>
            캐릭터를 찾지 못했습니다.
          </Typography>
        )}
      </Container>
    </div>
  );
}
