import { useContext } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { DATE_UTIL } from '../../../../lib/utils/date.util';
import { MissingQuestionContext } from './MissingQuestionContext';

const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  width: '100%',
  margin: 0,
  paddingLeft: 10,
  borderBottom: '1px solid #d4d9e7',
  boxSizing: 'border-box',
  '& .MuiCheckbox-root': {
    margin: '0 12px',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderTop: '1px solid #d4d9e7',
  padding: '16px 26px 16px 0',
  '&:first-of-type': {
    borderTop: 0,
  },
  '&.selected': {
    backgroundColor: '#ececec',
  },
}));

const CaptionPrimary = styled('span')(() => ({
  color: '#1976d2',
  backgroundColor: '#1976d23d',
  fontSize: '0.813rem',
  fontWeight: '800',
  borderRadius: 25,
  padding: '2px 6px',
}));

const CaptionDanger = styled('span')(() => ({
  color: '#fff',
  backgroundColor: '#d32f2f',
  fontSize: '0.813rem',
  borderRadius: 25,
  padding: '2px 6px',
}));

const DateTxt = styled('span')(() => ({
  color: '#9b9b9b',
  fontSize: '0.813rem',
  fontWeight: 600,
  marginLeft: 8,
  letterSpacing: '-0.35px',
}));

export const MissingQuestionItem = ({ question }) => {
  const context = useContext(MissingQuestionContext);
  if (!context)
    return (
      <FormControlLabelStyled
        control={<Checkbox />}
        label={
          <ListItemButtonStyled disableRipple>
            {question.handled ? (
              <CaptionPrimary>답변 완료</CaptionPrimary>
            ) : (
              <CaptionDanger>미답변</CaptionDanger>
            )}

            <ListItemText
              primary={question.question}
              primaryTypographyProps={{
                fontSize: '1.063rem',
                fontWeight: 600,
              }}
            />
          </ListItemButtonStyled>
        }
      />
    );
  const { isCheckedQuestion, toggleQuestion, disabled } = context;
  return (
    <FormControlLabelStyled
      control={
        <Checkbox
          disabled={disabled}
          checked={isCheckedQuestion(question)}
          onClick={() => toggleQuestion(question)}
        />
      }
      label={
        <ListItemButtonStyled disableRipple>
          <Box display="flex" alignItems="center">
            {question.handled ? (
              <CaptionPrimary>답변 완료</CaptionPrimary>
            ) : (
              <CaptionDanger>미답변</CaptionDanger>
            )}
            <DateTxt>
              {DATE_UTIL.converterUTCToTimeZone(
                question.createdDatetime,
                DATE_UTIL.FORMAT.FULL_FORMAT_KOREAN,
              )}
            </DateTxt>
          </Box>
          <ListItemText
            primary={question.question}
            primaryTypographyProps={{
              fontSize: '1.063rem',
              fontWeight: 600,
              marginTop: '4px',
            }}
          />
        </ListItemButtonStyled>
      }
    />
  );
};
