import { useContext, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TopBar } from 'pages/user/features/question/components/styled';
import IdeaDialog from '../../../search/IdeaDialog';
import { ReactComponent as HomeIcon } from 'assets/images/IconHomeFlat.svg';
import { ReactComponent as IdeaIcon } from 'assets/images/IconIdaaFlat.svg';
import { ReactComponent as ShareIcon } from 'assets/images/IconShareFlat.svg';
import { ReactComponent as LogoPlant } from 'assets/images/LogoPlant.svg';
import LogoNie from 'assets/images/LogoNie.png';
import ShareDialog from 'components/Dialog/ShareDialog';
import { Logo } from 'components/Logo';
import { shareMobile } from 'lib/utils/share';
import { ChatContext } from '../context/ChatProvider';
import { CommonSwitch } from '../../../../../../components/styledMUI/CommonSwitch';

export const ButtonListStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& div, a': {
    display: 'inline-flex',
    marginLeft: 30,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
}));
export const LogoStyle = styled(Box)(() => ({
  width: 72,
  objectFit: 'cover',
  // '& img': { width: '100%', height: '100%' },
}));

export const LogoPlantStyle = styled(Box)(() => ({
  width: 240,
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 600,
  color: '#549E44',
  '& > svg': {
    width: 32,
    height: 32,
    marginRight: 6,
  },
}));

export const Header = ({
  usePinnedAnswer,
  setUsePinnedAnswer,
  useAutoPinnedAnswer,
  setBottomMenuOpen,
}) => {
  const { simpleProfile, initQuestion } = useContext(ChatContext);
  const navigate = useNavigate();
  const { avatarId } = useParams();

  const [openIdeaDialog, setOpenIdeaDialog] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <TopBar>
      <LogoStyle
        sx={{
          '& :hover': { cursor: 'pointer' },
        }}
        onClick={() => initQuestion()}
      >
        {simpleProfile?.data?.avatarId ===
        '0ee9ea3b-74b1-4595-93fe-0db6f52ad229' ? (
          // <LogoPlantStyle>
          //   <LogoPlant /> 기후생태 리스크 방안
          // </LogoPlantStyle>
          <img src={LogoNie} alt={'기후생태 리스크 방안'} />
        ) : (
          <Logo />
        )}
      </LogoStyle>
      <ButtonListStyle sx={{ '& :hover': { cursor: 'pointer' } }}>
        {!useAutoPinnedAnswer && (
          <CommonSwitch
            checked={usePinnedAnswer}
            onClick={() => setUsePinnedAnswer(!usePinnedAnswer)}
          />
        )}
        {!usePinnedAnswer && (
          <>
            {/*<div onClick={() => setOpenIdeaDialog(true)}>*/}
            {/*  <IdeaIcon />*/}
            {/*</div>*/}
            <div
              onClick={() => {
                if (navigator.share) {
                  shareMobile(
                    '', // 추후 변경 필요
                    'profile',
                    `${simpleProfile.data.name}의 프로필 페이지를 공유했습니다.`,
                    simpleProfile.data.avatarId,
                  );
                } else {
                  setShareDialogOpen(true);
                }
              }}
            >
              <ShareIcon />
            </div>
          </>
        )}
        {/*<Link to="/">*/}
        {/*  <HomeIcon />*/}
        {/*</Link>*/}
      </ButtonListStyle>

      {/*<IdeaDialog open={openIdeaDialog} setOpen={setOpenIdeaDialog} />*/}

      <ShareDialog open={shareDialogOpen} setOpen={setShareDialogOpen} />
    </TopBar>
  );
};
