import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { BootstrapDialog } from './components/BootstrapDialog';
import { BoxComponent } from './components/BoxComponent';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  color: '#333',
  boxShadow: 'none',
}));

const ListBox = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: '#fff',
  marginBottom: 24,
  border: '1px solid #D3D4D4',
  borderRadius: 10,
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& > span': {
    marginRight: 8,
  },
  '& .MuiListItemText-root span': {
    fontWeight: 700,
  },
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  minWidth: 72,
  color: '#fff',
  backgroundColor: '#276AE2',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 600,
  borderRadius: 8,
  margin: '0 4px',
  '&:hover': {
    backgroundColor: '#276AE2',
  },
}));

export default function CorePromisesViewDialog(props) {
  return (
    <BootstrapDialog open={props.open} onClose={props.handleClose}>
      <AppBarStyled sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBarStyled>
      <BoxComponent>
        <ListBox>
          <List>
            <ListItemStyled>
              <ListItemText secondary={'제목'} />
              {props.previewItem.title.split('\n').map((line, idx) => (
                <ListItemText key={`line ${idx}: ${line}`} primary={line} />
              ))}
              <Divider sx={{ mt: 2 }} />
              <ListItemText secondary={'내용'} />
              {props.previewItem.value.split('\n').map((line, idx) => (
                <ListItemText key={`line ${idx}: ${line}`} primary={line} />
              ))}
            </ListItemStyled>
          </List>
        </ListBox>
        <Box display="flex" justifyContent="flex-end" width="100%">
          {/*<CancelButton onClick={props.handleClose}>취소</CancelButton>*/}
          <PrimaryButton onClick={props.handleClose}>확인</PrimaryButton>
        </Box>
      </BoxComponent>
    </BootstrapDialog>
  );
}
