import { API } from 'constants/api-endpoints';
import { MULTIPART_HEADER } from 'constants/request-config';

import { tokenInstance } from 'lib/axios/instance';

export const uploadAvatarFile = async (file, candidateId) => {
  const formData = new FormData();
  formData.append('file', file);
  await tokenInstance.put(
    `${API.AVATAR_FILES}/${candidateId}`,
    formData,
    MULTIPART_HEADER,
  );
};
