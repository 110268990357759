import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    backgroundColor: '#f7fafe',
    boxSizing: 'border-box',
    // paddingBottom:120,
    '& *': {
      fontFamily: 'Pretendard!important',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-all',
    padding: '30px 0',
    '& .MuiTypography-h4': {
      fontSize: '2.5rem',
      fontWeight: 600,
      marginBottom: 30,
      marginLeft: 10,
    },
    '@media all and (max-width: 640px)': {
      padding: '16px 24px',
      '& .MuiTypography-h4': {
        fontSize: '1.5rem',
      },
    },
  },

  // width: 80,
  // height: 107,
  // aspectRatio: '3/4',
  // '& .MuiAvatar-root': {
  // 	width: 80,
  // 	height: 80,
  // },
  // '& img': {
  // 	width: '100%',
  // 	height: '100%',
  // 	objectFit: 'cover',
  // },
  avatarWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0',
    '@media all and (max-width: 420px)': {
      padding: '30px 0',
    },
    '& img': {
      maxWidth: '280px',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      aspectRatio: '3/4',

      '@media all and (max-width: 640px)': {
        maxWidth: '12rem',
      },
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 280,
      height: 280,
      backgroundColor: '#fff',
      '& > svg': {
        width: 120,
        height: 120,
      },
      '@media all and (max-width: 640px)': {
        width: '12rem',
        height: '12rem',
      },
    },
  },
  barArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  count: {
    color: '#9A9B9E',
    fontSize: '1.25rem',
    marginLeft: 6,
    '& b': {
      color: '#7597d4',
      fontWeight: 800,
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1rem',
    },
  },
  buttonListStyle: {
    '& div, a': {
      display: 'inline-flex',
      marginLeft: 30,
      '& svg': {
        width: 40,
        height: 40,
      },
      '@media all and (max-width: 440px)': {
        marginLeft: 16,
        '& svg': {
          width: 32,
          height: 32,
        },
      },
    },
  },
  btnStyle: {
    color: '#fff',
    backgroundColor: '#333',
    borderRadius: 10,
    fontSize: '1.5rem',
    padding: '12px 40px',
    textDecoration: 'none',
    marginLeft: 30,
    '&:hover': {
      backgroundColor: '#333',
    },
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      padding: '12px 20px',
    },
  },
  card: {
    marginBottom: 30,
    '&.MuiPaper-root': {
      borderRadius: 12,
      border: '1px solid #e6e8ec',
      padding: '50px 40px',
      boxShadow: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: '1.75rem',
      fontWeight: 600,
      marginTop: 10,
    },
    '& .MuiTypography-body2': {
      marginTop: 10,
      fontSize: '1.625rem',
      color: '#161616',
    },
    '@media all and (max-width: 640px)': {
      margin: '0 auto 30px',
      '&.MuiPaper-root': {
        padding: '40px 26px',
      },
      '& .MuiTypography-h5': {
        fontSize: '1.5rem',
      },
    },
  },
  cardTitle: {
    paddingBottom: 10,
    borderBottom: '1px dashed #D3D4D4',
    '& > .MuiTypography-h5': {
      marginBottom: 40,
      paddingBottom: 8,
      borderBottom: '7px solid #93b5f1',
    },
  },
  cardContents: {
    padding: '30px 20px 0',
    fontSize: '1.5rem',
    color: '#161616',
    '& .MuiTypography-h6, & .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '1.625rem',
      marginTop: 40,
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    '& li': {
      color: '#757575',
      marginBottom: 10,
    },
    '@media all and (max-width: 640px)': {
      padding: '20px 0 0',
      fontSize: '1.125rem',
      '& .MuiTypography-h6, & .MuiTypography-root': {
        fontSize: '1.125rem',
      },
      '& ul': {
        margin: '8px 0',
        paddingLeft: 24,
        fontSize: '1rem',
      },
    },
  },
  fabStyle: {
    '&.MuiFab-root': {
      position: 'fixed',
      right: 40,
      bottom: 40,
      width: 84,
      height: 84,
      backgroundColor: '#fff',
      // border:'1px solid #D3D4D4',
      boxShadow: '0px 2px 10px #c9d0da',
      '& svg': {
        width: 48,
        height: 48,
        marginTop: 7,
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
      '@media all and (max-width: 440px)': {
        right: 20,
        bottom: 20,
        width: 62,
        height: 62,
        '& svg': {
          width: 36,
          height: 36,
        },
      },
    },
  },
});
