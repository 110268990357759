import { useContext } from 'react';

import { Typography } from '@mui/material';

import {
  ListBoxStyle,
  TextareaStyle,
} from 'pages/user/features/question/components/styled';

import { ChatContext } from '../context/ChatProvider';

export const PromptBox = () => {
  const { prompt, handleChangePrompt } = useContext(ChatContext);
  return (
    <ListBoxStyle width="100%">
      <Typography variant="subtitle1">Prompt</Typography>
      <TextareaStyle
        value={prompt}
        onChange={handleChangePrompt}
        color="neutral"
        size="sm"
        minRows={4}
        placeholder="질문에 대해 가능한 자세하고 보기 좋게 문단을 나눠서 대답해주세요."
      />
    </ListBoxStyle>
  );
};
