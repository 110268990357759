import { useState } from 'react';

export const usePaginationProps = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChange = (value) => {
    setPage(value);
  };

  const onPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  return { page, rowsPerPage, onPageChange, onPerPageChange };
};
