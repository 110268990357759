export const profileType = {
  BriefHistory: 'BriefHistory',
  AcademicBackground: 'AcademicBackground',
  SelfIntroduction: 'SelfIntroduction',
};

export const HANDLE_TYPE = {
  create: 'Create',
  update: 'Update',
  willBeDeleted: 'Delete',
  exist: 'Exist',
};
