import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { charactersQueries } from 'react-query/factory/charactersQueries';

import { updateCharacter } from 'api/characters/updateCharacter';

import { TOAST_MESSAGE } from 'constants/toast-message';

export const useUpdateCharacterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      await updateCharacter(params);
    },
    onSuccess: () => {
      toast.success(TOAST_MESSAGE.SUCCESS.UPDATE_SUCCESS);
    },
    onError: () => {
      toast.error(TOAST_MESSAGE.ERROR.UPDATE_ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: charactersQueries.all(),
      });
    },
  });
};
