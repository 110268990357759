import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const deleteAvatarMissingQuestion = async (data) => {
  console.log('@ data', data);
  await tokenInstance.delete(`${API.AVATARS}/questions/missing/multi`, {
    data,
  });
};
