import { queryOptions } from '@tanstack/react-query';

import { fetchAvatars } from 'api/avatars/fetchAvatars';
import { fetchMatchedAvatar } from 'api/avatars/fetchMatchedAvatar';

export const teamAvatarsQueries = {
  all: () => ['team-avatars'],
  lists: (teamId) => [...teamAvatarsQueries.all(), teamId],
  list: (teamId, pageSize = 5, search = '', page = 1) =>
    queryOptions({
      queryKey: [...teamAvatarsQueries.lists(teamId), pageSize, search, page],
      queryFn: () => fetchAvatars({ id: teamId, pageSize, search, page }),
      staleTime: Infinity,
      placeholderData: (previousData) => previousData,
    }),
  matched: (managerId) => [...teamAvatarsQueries.all(), managerId],
  matchedAvatar: (managerId) =>
    queryOptions({
      queryKey: teamAvatarsQueries.matched(managerId),
      queryFn: async () => await fetchMatchedAvatar(managerId),
      staleTime: Infinity,
    }),
};
