import { defaultInstance } from 'lib/axios/instance';

const userEndpointPrefix = '/api/v1/users';

export const createUserIdea = async (params) => {
  return await defaultInstance.put(`${userEndpointPrefix}/ideas`, params);
};

export const getUserIdeas = async (params) => {
  return await defaultInstance.get(`${userEndpointPrefix}/ideas`, {
    params: params,
  });
};

export const createUserConnectionHistory = async (params) => {
  return await defaultInstance.put(`${userEndpointPrefix}/connection`, params);
};
