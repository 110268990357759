import { useContext, useState } from 'react';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useDeleteAvatarMissingQuestionMutation } from 'react-query/mutations/avatars/useDeleteAvatarMissingQuestionMutation';

import { MissingQuestionContext } from './MissingQuestionContext';

import { DeleteConfirmDialog } from 'components/Dialog/DeleteConfirmDialog';
import { CommonStack } from 'components/styledMUI/CommonStack';

const DeleteButton = styled(Button)(() => ({
  display: 'inline-block',
  textAlign: 'center',
  color: '#d32f2f',
  marginLeft: 'auto',
  fontWeight: 600,
  textDecoration: 'none',
  border: '1px #d32f2f solid',
  borderRadius: 10,
  justifySelf: 'end',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#d32f2f',
  },
}));
export const MissingQuestionsGrid = ({
  maxPage,
  isSuccess,
  page,
  handleChangePage,
  children,
}) => {
  const { showAll, checkedQuestion, handleShowAllCheckbox, handleOpen } =
    useContext(MissingQuestionContext);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAll ?? false}
              onChange={({ target: { checked } }) =>
                handleShowAllCheckbox(checked)
              }
            />
          }
          label="전체 보기"
        />
        {!showAll && checkedQuestion.length > 0 && !checkedQuestion.handled && (
          <DeleteButton onClick={handleOpen}>삭제하기</DeleteButton>
        )}
      </Grid>
      <Grid item xs={6}>
        <List
          sx={{
            width: '100%',
            border: '1px solid #d4d9e7',
            borderRadius: 4,

            '& > :last-child': {
              borderBottom: 'none',
            },
          }}
        >
          {children}
        </List>
        {isSuccess && (
          <CommonStack spacing={2} mt={3}>
            <Pagination
              count={maxPage}
              page={page}
              onChange={handleChangePage}
            />
          </CommonStack>
        )}
      </Grid>
    </>
  );
};
