import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { IconButton, Tooltip } from '@mui/material';

import { AVATAR_DESCRIPTIONS } from 'constants/queryKeys';

import { DeleteConfirmDialog } from '../../../../components/Dialog/DeleteConfirmDialog';

import { deleteAvatarDescription } from 'api-legacy/avatarDescription';

export const DeleteTooltip = ({ descriptionNum }) => {
  const queryClient = useQueryClient();
  const { candidateId } = useParams();
  const mutation = useMutation({
    mutationFn: async () =>
      await deleteAvatarDescription({
        avatarId: candidateId,
        descriptionNum,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [AVATAR_DESCRIPTIONS, candidateId],
      });
      handleClose();
    },
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    mutation.mutate();
  };

  return (
    <>
      <Tooltip title="삭제">
        <IconButton onClick={handleOpen}>
          <DeleteOutlineRoundedIcon />
        </IconButton>
      </Tooltip>{' '}
      {open && (
        <DeleteConfirmDialog
          open={open}
          pending={mutation.isPending}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};
