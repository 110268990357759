import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Logout } from './Logout';

const NameTag = styled('div')(() => ({
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#27c3e2',
  color: '#fff',
  borderRadius: 10,
  padding: '4px 12px',
  fontWeight: 600,
}));

export const NavMenu = ({ name, children }) => (
  <Box display="flex">
    <NameTag>{name}</NameTag>
    {children}
    <Logout />
  </Box>
);
