import { makeStyles } from '@mui/styles';

import ChatIcon from 'assets/images/IconChatMax.svg';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: '0 auto',
    minHeight: '100vh',
    backgroundColor: '#F0F2F4',
    '@media all and (max-width: 640px)': {},
    '& *': {
      fontFamily: 'Pretendard!important',
    },
  },
  container: {},
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 120,
    marginBottom: 20,
    '@media all and (max-width: 480px)': {
      height: 'auto',
      marginTop: 10,
    },
  },
  logoStyle: {
    '& svg': {
      width: 120,
      '@media all and (max-width: 480px)': {
        width: 80,
      },
    },
  },
  inputStyle: {
    '&.MuiFormControl-root': {
      //width: 'calc(100% - 120px - 20px)', // 100% - 로고사이즈 - gutter
      width: 520,
      margin: '0 16px',
      '@media all and (max-width: 768px)': {
        width: '100%',
      },
    },
    '& .MuiInputBase-root': {
      fontSize: '1.25rem',
      fontWeight: 600,
      backgroundColor: '#fff',
      border: '1px solid #CCD1D6',
      boxShadow: '0px 2px 8px #58828b2b',
      borderRadius: 10,
      paddingRight: 0,
      '& input:-internal-autofill-selected': {
        backgroundColor: '#fff!important',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover  .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
      {
        border: '0',
      },
    '@media all and (max-width: 640px)': {
      width: '100%',
      marginRight: 16,
      '& .MuiInputBase-root': {
        fontSize: '1.125rem',
      },
    },
    '@media all and (max-width: 480px)': {
      '&.MuiFormControl-root': {
        width: 'calc(100% - 80px - 20px)', // 100% - 로고사이즈 - gutter
      },
      '& input': {
        padding: '6px 8px',
        fontSize: '1rem',
      },
      '& .MuiButtonBase-root': {
        padding: '4px 8px',
      },
    },
  },
  card: {
    marginBottom: 30,
    cursor: 'pointer',
    '&.MuiPaper-root': {
      display: 'flex',
      borderRadius: 12,
      padding: 30,
    },
    '& .MuiTypography-h5': {
      fontSize: '1.875rem',
      fontWeight: 600,
      marginTop: 10,
    },
    '& .MuiTypography-body2': {
      marginTop: 10,
      fontSize: '1.625rem',
      color: '#161616',
    },
    '@media all and (max-width: 640px)': {
      margin: '0 auto 20px',
      '& .MuiTypography-h5': {
        fontSize: '1.5rem',
      },
    },
    boxShadow: 'none!important',
    border: '1px solid #e6e8ec',
    '&:hover': {
      border: '1px solid #93b5f1',
      boxShadow: '0 1px 7px #d9e0e4',
    },
  },
  cardImg: {
    '& .MuiAvatar-root': {
      width: 80,
      height: 80,
      backgroundColor: '#f2f7ff',
      '& > svg': {
        width: 48,
        height: 48,
      },
    },
    width: '100%',
    maxWidth: '80px',
    aspectRatio: '3/4',
    '& img': {
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '3/4',
    },
  },
  cardContents: {
    width: '100%',
    padding: '0 20px',
    fontSize: '1.25rem',
    color: '#161616',
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${ChatIcon})`,
    backgroundSize: '42px 42px',
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      '& .MuiTypography-h6, & .MuiTypography-root': {
        fontSize: '1.125rem',
      },
    },
    '@media all and (max-width: 480px)': {
      padding: '0 10px',
      backgroundSize: '32px 32px',
      backgroundPosition: 'right -2px',
    },
  },
  simpleCardContents: {
    width: '100%',
    padding: '0 20px',
    fontSize: '1.25rem',
    color: '#161616',
    '@media all and (max-width: 640px)': {
      fontSize: '1.125rem',
      '& .MuiTypography-h6, & .MuiTypography-root': {
        fontSize: '1.125rem',
      },
    },
    '@media all and (max-width: 480px)': {
      padding: '0 10px',
      backgroundSize: '32px 32px',
      backgroundPosition: 'right -2px',
    },
  },
  cardTitle: {
    borderBottom: '1px dashed #D3D4D4',
    '& > .MuiTypography-h6': {
      fontWeight: 700,
      fontSize: '1.375rem',
      paddingBottom: 3,
      borderBottom: '3px solid #93b5f1',
      color: '#333',
    },
    '@media all and (max-width: 456px)': {
      '& > .MuiTypography-h6': {
        fontSize: '1.125rem',
      },
    },
  },
  infoStyle: {
    '& > div': {
      marginTop: 10,
      fontWeight: 600,
      color: '#0F0F10',
      '& span': {
        width: '5.4rem',
        display: 'inline-flex',
        color: '#6F6F70',
        fontWeight: 400,
      },
      '@media all and (max-width: 456px)': {
        fontSize: '1rem',
        '& span': {
          width: 'auto',
          display: 'flex',
          marginBottom: 4,
          fontSize: '0.875rem',
        },
      },
    },
  },
  recordingBtnStyle: {
    border: '0 none',
    boxShadow: '1px 1px 15px #27C3E28a',
  },
  recording: {
    width: 8,
    height: 40,
    borderRadius: 4,
    display: 'inline-block',
    position: 'relative',
    color: '#27C3E2',
    background: '#27C3E2',
    animation: `$animloader 0.3s 0.3s linear infinite alternate`,
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      width: 8,
      height: 40,
      borderRadius: 4,
      background: '#27C3E2',
      top: '50%',
      left: 20,
      transform: 'translateY(-50%)',
      animation: `$animloader 0.3s  0.45s  linear infinite alternate`,
    },
    '&:before': {
      left: -20,
      animationDelay: '0s',
    },
    '@media all and (max-width: 640px)': {
      width: 6,
      height: 36,
      '&::after, &::before': {
        width: 6,
        left: 12,
        height: 36,
      },
      '&:before': {
        left: -12,
      },
    },
    '@media all and (max-width: 480px)': {
      width: 5,
      height: 26,
      '&::after, &::before': {
        width: 5,
        left: 10,
        height: 26,
      },
      '&:before': {
        left: -10,
      },
    },
  },
  '@keyframes animloader': {
    '0%': { height: 40 },
    '100%': { height: 5 },
  },
});
