import { useContext } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommonInputBase } from 'components/styledMUI/CommonInputBase';
import { SearchIconWrapper } from 'components/styledMUI/SearchIconWrapper';
import { PinnedQuestionContext } from './PinnedQuestionContext';
import { CacheList } from './CacheList';

const Search = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
  },
  border: '1px solid #e8edf7',
}));

const StyledInputBase = styled(CommonInputBase)(() => ({
  '&.MuiInputBase-root': {
    padding: '4px 0',
    width: '100%',
  },
}));

export const AvatarCacheBox = () => {
  const { search, handleChangeSearch, debouncedSearch } = useContext(
    PinnedQuestionContext,
  );

  return (
    <Box>
      <Search>
        <SearchIconWrapper>
          <SearchRoundedIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="질문을 검색해 주세요"
          value={search}
          onChange={handleChangeSearch}
        />
      </Search>
      <CacheList search={debouncedSearch} />
    </Box>
  );
};
