import { queryOptions } from '@tanstack/react-query';
import {
  fetchCategory,
  fetchCategoryList,
  fetchSubCategory,
} from '../../api/category/fetchCategory';

export const categoryQueries = {
  all: () => ['category'],
  categories: (candidateId, mode, page) => [
    ...categoryQueries.all(),
    candidateId,
    mode,
    page,
  ],
  categoryList: (candidateId) =>
    queryOptions({
      queryKey: categoryQueries.all(),
      queryFn: () => fetchCategoryList(candidateId),
    }),
  category: (candidateId, mode, page) =>
    queryOptions({
      queryKey: categoryQueries.categories(candidateId, mode, page),
      queryFn: () => fetchCategory(candidateId, page),
      meta: { errorMessage: '데이터를 불러오지 못했습니다.' },
    }),
  subcategory: (candidateId, categoryId, mode, page) =>
    queryOptions({
      queryKey: categoryQueries.categories(candidateId, categoryId, mode, page),
      queryFn: () => fetchSubCategory(candidateId, categoryId, page),
      meta: { errorMessage: '데이터를 불러오지 못했습니다.' },
    }),
};
