import { queryOptions } from '@tanstack/react-query';

import { fetchManagers } from 'api/managers/fetchManagers';

export const managersQueries = {
  all: () => ['managers'],
  lists: (managerType, id) => [...managersQueries.all(), managerType, id],
  list: (managerType, id, pageSize = 5, search = '', page = 1) =>
    queryOptions({
      queryKey: [
        ...managersQueries.lists(managerType, id),
        pageSize,
        search,
        page,
      ],
      queryFn: () =>
        fetchManagers({
          managerType,
          id,
          pageSize,
          search,
          page,
        }),
    }),
};
