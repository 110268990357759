import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Grid1RowBox } from 'pages/user/features/question/components/styled';
import { categoryQueries } from '../../../../../../react-query/factory/categoryQueries';
import { useState } from 'react';
import Category from '../items/Category';

export const CategoryGrid = ({ setBottomMenuOpen }) => {
  const { avatarId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleQuestion = (e, category) => {
    e.stopPropagation();
    if (!category.parentId) {
      if (selectedCategory?.categoryId === category.categoryId) {
        setSelectedCategory(null);
      } else {
        setSelectedCategory(category);
      }
    } else {
      if (selectedCategory?.categoryId === category.parentId) {
        setSelectedCategory(null);
      } else {
        setSelectedCategory(category.parentId);
      }
    }
  };

  const avatarCategories = useQuery(categoryQueries.categoryList(avatarId));

  if (
    avatarCategories.isLoading ||
    avatarCategories.isError ||
    !avatarCategories.data.length
  )
    return <></>;

  return (
    <Grid1RowBox>
      {avatarCategories?.data?.map((category) => (
        <Category
          setBottomMenuOpen={setBottomMenuOpen}
          key={category?.categoryId}
          category={category}
          selectedCategory={selectedCategory}
          toggleQuestion={toggleQuestion}
        />
      ))}
    </Grid1RowBox>
  );
};
