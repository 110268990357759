import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, TextField } from '@mui/material';

import Recording from './Recording';

import { ReactComponent as SendIcon } from 'assets/images/IconSendFlat.svg';
import { Logo } from 'components/Logo';
import { SendIconBox } from 'components/styledMUI/SendIconBox';
import { useStyles } from 'components/styles/MainStyle';
import { Login } from '../../../../ui/layout/Login';

export default function Main() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [text, setText] = useState('');

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleSTT = (text) => {
    setText(text);
  };

  const handleSearch = () => {
    if (text.trim().length > 0) {
      navigate(`/candidateList/${text.trim()}`);
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.loginBox}>
          <Login />
        </Box>
        <Box className={classes.logoStyle}>
          <Logo width={'306px'} height={'126px'} />
        </Box>
        <Box className={classes.searchWrapper}>
          <Box className={classes.textFieldStyle}>
            <TextField
              value={text}
              onChange={handleChangeText}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              placeholder="캐릭터를 입력해주세요."
              variant="outlined"
              className={classes.inputStyle}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <SendIconBox onClick={handleSearch}>
                    <SendIcon />
                  </SendIconBox>
                ),
              }}
              disableripple="true"
            />
            <Box className={classes.recordWrapper}>
              <Recording handleSTT={handleSTT} />
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
