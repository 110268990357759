import { styled } from '@mui/material/styles';

const ImageWrapper = styled('div')(() => ({
  width: 360,
  height: 240,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 24,
  backgroundColor: '#fafafa',
  border: '1px dashed #ddd',
  borderRadius: 10,
  boxSizing: 'border-box',
  '& img, & svg': {
    width: 'max-content',
    height: 'max-content',
    maxWidth: 340,
    maxHeight: 210,
  },
}));

export const LogoBox = ({ src, alt }) => {
  return <ImageWrapper>{src && <img src={src} alt={alt} />}</ImageWrapper>;
};
