import { useState } from 'react';

import { Box, FormControl, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BoxComponent } from 'ui/dialog-items/BoxComponent';
import { CancelButton } from 'ui/dialog-items/CancelButton';
import { DialogHeader } from 'ui/dialog-items/DialogHeader';
import { DialogInput } from 'ui/dialog-items/DialogInput';
import { DialogWrapper } from 'ui/dialog-items/DialogWrapper';
import { SubmitButton } from 'ui/dialog-items/SubmitButton';

import { useCreateManagerMutation } from 'react-query/mutations/managers/useCreateManagerMutation';

import { regExType, validation } from 'lib/utils/validation.util';

const LabelText = styled(Typography)(() => ({
  fontSize: '0.938rem',
  marginBottom: 4,
}));

export const AddManagerDialog = ({ managerType, open, handleClose }) => {
  const [message, setMessage] = useState('');

  const [managerData, setManagerData] = useState({
    type: managerType,
    name: '',
    loginId: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManagerData({
      ...managerData,
      [name]: value,
    });
  };

  const validateSubmit = () => {
    if (!validation(regExType.NameRegEx, managerData.name))
      return {
        enable: false,
        reason: '이름에 포함될 수 없는 문자가 있습니다.',
      };
    if (!validation(regExType.IdRegEx, managerData.loginId))
      return {
        enable: false,
        reason: '아이디는 완전한 한글이나 영어 또는 숫자여야 합니다.',
      };
    if (!validation(regExType.PasswordRegEx, managerData.password))
      return {
        enable: false,
        reason:
          '알파벳, 숫자, 특수 문자를 포함한 8자 이상의 비밀번호를 입력해주세요.',
      };

    return { enable: true };
  };

  const mutation = useCreateManagerMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = validateSubmit();
    if (validation.enable) {
      mutation.mutate(managerData, { onSuccess: handleClose });
    } else {
      setMessage(validation.reason);
    }
  };

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogHeader title={'계정 추가'} handleClose={handleClose} />
      <form onSubmit={handleSubmit}>
        <BoxComponent>
          <FormControl>
            <LabelText>이름</LabelText>
            <DialogInput
              readOnly={mutation.isPending}
              id="name"
              name="name"
              value={managerData.name}
              onChange={handleChange}
              placeholder="이름을 입력해주세요"
              autoComplete="off"
              type="text"
            />
          </FormControl>
          <FormControl>
            <LabelText>아이디</LabelText>
            <DialogInput
              // error={validateCheck(regExType.idRegEx, managerData.loginId)}
              readOnly={mutation.isPending}
              id="loginId"
              name="loginId"
              value={managerData.loginId}
              onChange={handleChange}
              placeholder="아이디를 입력해주세요"
              autoComplete="off"
              type="text"
            />
          </FormControl>
          <FormControl>
            <LabelText>비밀번호</LabelText>
            <DialogInput
              readOnly={mutation.isPending}
              name="password"
              value={managerData.password}
              onChange={handleChange}
              placeholder="비밀번호를 입력해주세요"
              type="password"
              autoComplete="off"
            />
          </FormControl>
          <Box
            sx={{
              '& span': {
                color: '#CA4242',
                width: '100%',
              },
            }}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <span>{message}</span>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <CancelButton disabled={mutation.isPending} onClick={handleClose}>
                취소
              </CancelButton>
              <SubmitButton
                type="submit"
                disabled={!validateSubmit() || mutation.isPending}
              >
                추가
              </SubmitButton>
            </Box>
          </Box>
        </BoxComponent>
      </form>
    </DialogWrapper>
  );
};
