import { tokenInstance } from 'lib/axios/instance';

const endpoint = '/api/v1/avatars/questions';

export const getMissingQuestions = async (page = 1, candidateId, handled) => {
  const { data } = await tokenInstance.get(`${endpoint}/missing`, {
    params: { page, id: candidateId, handled },
  });
  return data;
};

export const handleMissingQuestion = async (handledQuestion) => {
  await tokenInstance.post(`${endpoint}/missing`, handledQuestion);
};

export const createAvatarPinnedQuestionCache = async (handledQuestion) => {
  await tokenInstance.put(`${endpoint}/pinned`, handledQuestion);
};

export const updateAvatarPinnedQuestionCache = async (handledQuestion) => {
  await tokenInstance.post(`${endpoint}/pinned`, handledQuestion);
};
