import CloseIcon from '@mui/icons-material/CloseRounded';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppBarStyled = styled(AppBar)(() => ({
  color: '#333',
  boxShadow: 'none',
}));

export const DialogHeader = ({ title, handleClose }) => (
  <AppBarStyled sx={{ position: 'relative' }}>
    <Toolbar>
      <Typography sx={{ flex: 1 }} variant="h6" component="div">
        {title}
      </Typography>
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  </AppBarStyled>
);
