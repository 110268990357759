import { API } from 'constants/api-endpoints';

import { tokenInstance } from 'lib/axios/instance';

export const updateHitCount = async (avatarId, cacheId) => {
  await tokenInstance.post(
    `${API.AVATARS}/${avatarId}/caches/${cacheId}/hit-count`,
  );
  return avatarId;
};
