import { Link, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AVATAR_CONFIG_DETAILS } from 'constants/queryKeys';

import { ImageBox } from './components/ImageBox';

import { getAvatarConfigDetails } from 'api-legacy/avatars/getAvatarDescription';
import { ContentBox } from 'components/styledMUI/ContentBox';
import { ExposeSwitch } from 'components/styledMUI/ExposeSwitch';

const PageTitle = styled('h3')(() => ({
  margin: '1rem 0 2.5rem',
  color: '#0F0F10',
  fontWeight: 600,
  fontSize: '1.5rem',
}));
const SubTitle = styled('h4')(() => ({
  color: '#0F0F10',
  fontWeight: 700,
  fontSize: '1.063rem',
  marginRight: 20,
  width: 210,
  // borderBottom:'1px dashed #ddd',
  // paddingBottom:12,
}));

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40,
  '& p': {
    fontSize: '1.125rem',
  },
}));

const EditButton = styled(Link)(() => ({
  display: 'block',
  width: 140,
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#d32f2f',
  marginLeft: 'auto',
  padding: '12px 24px',
  fontSize: '1.125rem',
  fontWeight: 600,
  textDecoration: 'none',
  borderRadius: 10,
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
}));

export default function Preferences() {
  const { candidateId } = useParams();

  const { data: avatarConfigDetails, isLoading } = useQuery({
    queryKey: [AVATAR_CONFIG_DETAILS, candidateId],
    queryFn: async () => {
      const { data } = await getAvatarConfigDetails(candidateId);
      return data;
    },
  });

  if (isLoading) return <></>;
  return (
    <ContentBox component="main" maxWidth="xl">
      <PageTitle>환경설정</PageTitle>
      <Box>
        <BoxStyled>
          <SubTitle>사진</SubTitle>
          <ImageBox src={avatarConfigDetails.posterImage?.downloadUrl} />
        </BoxStyled>
        <BoxStyled>
          <SubTitle>타이틀</SubTitle>
          <p>{avatarConfigDetails.title}</p>
        </BoxStyled>
        <BoxStyled>
          <SubTitle>외부노출 허용</SubTitle>
          <ExposeSwitch checked={avatarConfigDetails.public} disabled />
        </BoxStyled>
        <BoxStyled>
          <SubTitle>특정 IP만 허용</SubTitle>
          <ExposeSwitch
            checked={avatarConfigDetails.accessPolicy === 'WhiteList'}
            disabled
          />
        </BoxStyled>
        {avatarConfigDetails.accessPolicy === 'WhiteList' && (
          <BoxStyled
            sx={{
              alignItems: 'start',
            }}
          >
            <SubTitle>허용된 IP 리스트</SubTitle>
            <Box>
              <ol>
                {avatarConfigDetails.accessAddresses.map((address) => (
                  <li key={address.addressNum}>
                    <p>{address.address}</p>
                  </li>
                ))}
              </ol>
            </Box>
          </BoxStyled>
        )}
      </Box>
      <Box width="100%">
        <EditButton to={`/candidate/preferences/edit/${candidateId}`}>
          수정
        </EditButton>
      </Box>
    </ContentBox>
  );
}
