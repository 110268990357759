import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ImgBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fafafa',
  '& img, & svg': {
    width: 'max-content',
    height: 'max-content',
    maxWidth: 340,
    maxHeight: 210,
  },
}));

const EmptyImg = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px dashed #ddd',
  padding: '32px 24px 24px',
  '& svg': {
    opacity: 0.5,
  },
  '& span': {
    marginTop: 12,
    color: '#969696',
  },
}));

export const ImageBox = ({ src }) => {
  if (!src)
    return (
      <EmptyImg>
        <AddPhotoAlternateRoundedIcon />
        <span>이미지가 없습니다.</span>
      </EmptyImg>
    );
  return (
    <ImgBox>
      <img src={src} alt="사진" />
    </ImgBox>
  );
};
