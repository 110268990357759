export const API = {
  AUTH: '/api/v1/authentications',
  USERS: '/api/v1/users',
  CHARACTERS: '/api/v1/characters',
  AVATARS: '/api/v1/avatars',
  AVATAR_FILES: '/api/v1/avatar/files',
  MANAGERS: '/api/v1/managers',
  FILES: '/api/v1/files',
  TEAM: '/api/v1/team',
  TEAM_FILES: '/api/v1/team/files',
  TEAM_MANAGERS: '/api/v1/team-managers',
};
