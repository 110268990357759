import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale('ko');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(isTomorrow);

class DateUtil {
  constructor() {
    this.timeZone = 'Asia/Seoul';
    this.language = 'ko';
    this.FORMAT = {
      DEFAULT_FORMAT: 'YYYY-MM-DD HH:mm',
      FULL_FORMAT: 'YYYY-MM-DD HH:mm:ss',
      DATE_FORMAT: 'YYYY-MM-DD',
      DAY_FORMAT: 'YYYY-MM-DD-(ddd)',
      AMPM_FORMAT: 'YYYY-MM-DD hh:mm A',
      SIMPLE_TIME_FORMAT: 'hh:mm',
      SIMPLE_DATE_AND_TIME_AMPM_FORMAT: 'MM-DD hh:mm A',
      SIMPLE_TIME_AMPM_FORMAT: 'A hh:mm',
      NORMAL_TIME_FORMAT: 'HH:mm:ss',
      DOT_DATE_FORMAT: 'YYYY.MM.DD.',
      SINGLE_DOT_DATE_FORMAT: 'YYYY.M.D.',
      DOT_FORMAT: 'YYYY.M.D. (ddd)',
      SINGLE_DIGIT_DOT_DATE_FORMAT: 'YYYY.M.D.',
      FULL_DATE_DOT_FORMAT: 'YYYY.M.D. HH:mm',
      FULL_DATE_AMPM_DOT_FORMAT: 'YYYY.M.D. A hh:mm',
      FULL_DATE_AMPM_DOT_FORMAT_SPACED: `YYYY.M.D. ${'\u00a0'} A ${'\u00a0'} hh:mm `,
      SIMPLE_DATE_AMPM_DOT_FORMAT: 'MM.DD. hh:mm A',
      SIMPLE_AMPM: 'A',
      DAY: 'DD',
      MONTH: 'MM',
      YEAR: 'YYYY',
      SIMPLE_DAY: 'ddd',
      SINGLE_DIGIT_DOT_DATE_FORMAT_KOREAN: 'YYYY년 M월 D일',
      FULL_FORMAT_KOREAN: 'YYYY. M. D. HH:mm:ss',
      SIMPLE_DATE_FORMAT: 'YYMMDD',
    };
  }

  setTimeZone(zone) {
    this.timeZone = zone;
  }

  setLanguage(languageCode) {
    this.language = languageCode;
  }

  getTimeZone() {
    return this.timeZone;
  }

  getLanguage() {
    return this.language;
  }

  getNow() {
    return this.getTimeZoneDate(dayjs(new Date()), this.FORMAT.DEFAULT_FORMAT);
  }

  getNowYear() {
    return this.getTimeZoneDate(dayjs(new Date()), this.FORMAT.YEAR);
  }

  getNowDate() {
    return this.getTimeZoneDate(dayjs(new Date()), this.FORMAT.DATE_FORMAT);
  }

  getTomorrowDate() {
    return this.getTimeZoneDate(
      dayjs(new Date()).add(1, 'day'),
      this.FORMAT.DATE_FORMAT,
    );
  }

  getConvertedNowDate(format) {
    return this.getTimeZoneDate(dayjs(new Date()), format);
  }

  //todo ko timeZone
  getTimeZoneDate(date, format) {
    return dayjs(date).tz(this.timeZone).locale(this.language).format(format);
  }

  getTZConvertingDatetime(datetime, format) {
    const result = dayjs(datetime).tz(this.timeZone).locale(this.language);
    if (format) {
      return result.format(format);
    }

    return result;
  }

  getUTCDate(date) {
    return dayjs(date).tz('UTC');
  }

  getTimeZoneLanguage(date) {
    return dayjs(date).locale(this.language);
  }

  convertTimeZoneToUTC(date) {
    return dayjs(date).tz('UTC');
  }

  convertUTCToTimeZone(date, format) {
    return this.getTimeZoneDate(dayjs(date), format);
  }

  // ex-  UTC 에서 9 시간을 더한 한국시간을 표시
  converterUTCToTimeZone(date, format) {
    return this.getTZConvertingDatetime(
      dayjs(date).tz('UTC', true),
      format ? format : null,
    );
  }

  getWeek(date) {
    const selectedDay = date
      ? dayjs(date).format('YYYY-MM-DD')
      : dayjs().tz(this.timeZone).format('YYYY-MM-DD');
    return {
      selectedDay: selectedDay,
      weekOfStartDay: selectedDay,
      weekOfEndDay: selectedDay,
    };
  }

  getLocalTimeZone() {
    return dayjs.tz.guess();
  }

  getStartOfDateTimeByUnit(dateTime, unitName) {
    return dayjs(dateTime)
      .tz(this.timeZone)
      .locale(this.language)
      .startOf(unitName);
  }

  getEndOfDateTimeByUnit(unitName) {
    return dayjs().endOf(unitName);
  }

  getTimeZoneDateNoFormat(date) {
    return dayjs(date).tz(this.timeZone).locale(this.language);
  }

  getStartAndEndDateTimeOfMonth(dateTime) {
    const startDateTimeOfMonth =
      this.getTimeZoneDateNoFormat(dateTime).startOf('month');
    const endDateTimeOfMonth =
      this.getTimeZoneDateNoFormat(dateTime).endOf('month');

    return {
      startDateTime: startDateTimeOfMonth,
      endDateTime: endDateTimeOfMonth,
    };
  }

  getTodayByCalendar() {
    const today = dayjs().tz(this.timeZone).locale(this.language).toDate();
    return {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
  }

  getInputValueFormatToday() {
    const today = dayjs()
      .tz(this.timeZone)
      .locale(this.language)
      .format('YYYY-MM');
    return today;
  }

  getNoticeTimeDifferentMessage(createdDatetime) {
    let timeDifferentMsg = '';
    const convertedStartDatetime = this.convertUTCToTimeZone(
      createdDatetime,
      this.FORMAT.FULL_FORMAT,
    );

    const now = dayjs();
    const timeDiff = now.diff(convertedStartDatetime, 'minute');
    if (timeDiff < 1) {
      timeDifferentMsg = '방금전';
    } else if (timeDiff < 60) {
      timeDifferentMsg = `${timeDiff}분전`;
    } else if (timeDiff < 1440) {
      timeDifferentMsg = `${Math.floor(timeDiff / 60)}시간전`;
    } else {
      timeDifferentMsg = `${Math.floor(timeDiff / 1440)}일전`;
    }

    return timeDifferentMsg;
  }

  getRoomTimeDifferenceMessage(timeDifferenceMinute) {
    let msg = '';

    if (timeDifferenceMinute < 60) {
      msg = `00 : ${timeDifferenceMinute}`;
    } else {
      const hour = Math.floor(timeDifferenceMinute / 60);
      const minute = timeDifferenceMinute - hour * 60;

      msg = `${hour.toString().padStart(2, '0')} : ${minute
        .toString()
        .padStart(2, '0')}`;
    }

    return msg;
  }
}

export const DATE_UTIL = new DateUtil();
